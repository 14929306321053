import { SortOption } from ".";
import { CashPointsRatio, FlightItem, FlightSortType } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { SortService } from "booking_app/services/sort.service";
import { WhitelabelTranslateService } from "booking_app/services/whitelabel-translate.service";

import { DisplayUtils } from "booking_app/utils";

export class FlightsSearchResultsSorterService {
  static $inject = [
    "$translate",
    "WhitelabelTranslateService",
    "SortService",
    "AppSettings",
    "PointsCashShareService",
    "DisplayUtils",
  ];

  sortOptions: SortOption[] = [];
  permittedSortOptions: SortOption[] = [];
  SelectedSortOption: SortOption;

  constructor(
    private $translate: any,
    private whitelabelTranslateService: WhitelabelTranslateService,
    private sortService: SortService,
    private appSettings: AppSettings,
    private pointsCashShareService: PointsCashShareService,
    private displayUtils: DisplayUtils,
  ) {
    this.initializeSortOptions();
    this.initializePermittedSortOptions();
    this.selectedSortOption = this.permittedSortOptions[0];
  }

  get selectedSortOption(): SortOption {
    return this.SelectedSortOption;
  }

  set selectedSortOption(selectedSortOption: SortOption) {
    this.SelectedSortOption = selectedSortOption;
  }

  initializeSortOptions() {
    this.sortOptions = [{
      type: FlightSortType.PRICE_LOHI,
      text: this.$translate.instant("Price (Low to High)"),
      sortFunction: (flightItems: FlightItem[]) => this.sortService.int(flightItems, "maxPriceInCash", false),
    }, {
      type: FlightSortType.PRICE_HILO,
      text: this.$translate.instant("Price (High to Low)"),
      sortFunction: (flightItems: FlightItem[]) => this.sortService.int(flightItems, "maxPriceInCash", true),
    }, {
      type: FlightSortType.STOPS_LOHI,
      text: this.$translate.instant("Stops (Low to High)"),
      sortFunction: (flightItems: FlightItem[]) => {
        return this.sortService.func(
          flightItems,
          (item: FlightItem) => item.segments.length,
          "asc",
        );
      },
    }, {
      type: FlightSortType.OUTBOUND_LOHI,
      text: this.$translate.instant("Outbound Departure (Low to High)"),
      sortFunction: (flightItems: FlightItem[]) => this.sortService.int(flightItems, "departureTimeInMinutes", false),
    }, {
      type: FlightSortType.OUTBOUND_HILO,
      text: this.$translate.instant("Outbound Departure (High to Low)"),
      sortFunction: (flightItems: FlightItem[]) => this.sortService.int(flightItems, "departureTimeInMinutes", true),
    }, {
      type: FlightSortType.DURATION_LOHI,
      text: this.$translate.instant("Flight Duration (Shortest to Longest)"),
      sortFunction: (flightItems: FlightItem[]) => this.sortService.int(flightItems, "duration", false),
    }, {
      type: FlightSortType.DURATION_HILO,
      text: this.$translate.instant("Flight Duration (Longest to Shortest)"),
      sortFunction: (flightItems: FlightItem[]) => this.sortService.int(flightItems, "duration", true),
    }, {
      type: FlightSortType.MILES_LOHI,
      text: this.whitelabelTranslateService.translate("price_low_to_high",
        {
          partnersShortCurrency: this.displayUtils.partnerShortCurrency(),
        },
      ),
      sortFunction: (flightItems: FlightItem[]) => this.sortService.int(flightItems, "maxPriceInPoints", false),
    }, {
      type: FlightSortType.MILES_HILO,
      text: this.whitelabelTranslateService.translate("price_high_to_low",
        {
          partnersShortCurrency: this.displayUtils.partnerShortCurrency(),
        },
      ),
      sortFunction: (flightItems: FlightItem[]) => this.sortService.int(flightItems, "maxPriceInPoints", true),
    }];
  }

  initializePermittedSortOptions(): void {
    const enabledArr: SortOption[] =
      this.sortOptions.filter(e => this.appSettings.flightsEnabledSortingOptions.includes(e.type));
    this.permittedSortOptions.splice(0, this.permittedSortOptions.length, ...enabledArr);
  }

  updateSortingOptionsForPayWithPoints(): void {
    if (!this.appSettings.sortSettings.canUseFullCashOnRedemption) {
      return;
    }

    const fullCashSortArr: FlightSortType[] = [FlightSortType.PRICE_LOHI, FlightSortType.PRICE_HILO];
    const fullPointsSortArr: FlightSortType[] = [FlightSortType.MILES_LOHI, FlightSortType.MILES_HILO];

    if (this.pointsCashShareService.pointsCashRatio() === CashPointsRatio.FULLCASH) {
      const subsetWithoutPoints: SortOption[] = this.getUpdatedPermittedSortArr(fullPointsSortArr);

      this.selectedSortOption = this.isCurrentCashOrPoints(fullPointsSortArr) ?
        this.getMatchingSortArr([FlightSortType.PRICE_LOHI])[0] : this.selectedSortOption;

      if (subsetWithoutPoints.length > 0) {
        this.permittedSortOptions.splice(0, this.permittedSortOptions.length, ...subsetWithoutPoints);
      }

      if (!this.isSortingOptionsExist(fullCashSortArr)) {
        this.permittedSortOptions.push(...this.getMatchingSortArr(fullCashSortArr));
      }
    }

    if (this.pointsCashShareService.pointsCashRatio() === CashPointsRatio.FULLPOINTS) {
      const subsetWithoutCash: SortOption[] = this.getUpdatedPermittedSortArr(fullCashSortArr);

      this.selectedSortOption = this.isCurrentCashOrPoints(fullCashSortArr) ?
        this.getMatchingSortArr([FlightSortType.MILES_LOHI])[0] : this.selectedSortOption;

      if (subsetWithoutCash.length > 0) {
        this.permittedSortOptions.splice(0, this.permittedSortOptions.length, ...subsetWithoutCash);
      }

      if (!this.isSortingOptionsExist(fullPointsSortArr)) {
        this.permittedSortOptions.push(...this.getMatchingSortArr(fullPointsSortArr));
      }
    }

    if (this.pointsCashShareService.pointsCashRatio() === CashPointsRatio.CASHANDPOINTS) {

      if (!this.isSortingOptionsExist(fullPointsSortArr)) {
        this.permittedSortOptions.push(...this.getMatchingSortArr(fullPointsSortArr));
      }

      if (!this.isSortingOptionsExist(fullCashSortArr)) {
        this.permittedSortOptions.push(...this.getMatchingSortArr(fullCashSortArr));
      }
    }

  }

  private getUpdatedPermittedSortArr(sortLabelArrToBeRemoved: FlightSortType[]): SortOption[] {
    return this.sortOptions.filter(sortOption => !sortLabelArrToBeRemoved.includes(sortOption.type));
  }

  private getMatchingSortArr(sortLabelArrToBeMatched: FlightSortType[]): SortOption[] {
    return this.sortOptions.filter(sortOption => sortLabelArrToBeMatched.includes(sortOption.type));
  }

  private isSortingOptionsExist(sortLabelArrToBeChecked: FlightSortType[]): boolean {
    return this.permittedSortOptions.filter((sortOption) =>
      sortLabelArrToBeChecked.includes(sortOption.type)).length > 0;
  }

  private isCurrentCashOrPoints(fullPointsOrCashArr: FlightSortType[]): boolean {
    const currentElement: FlightSortType = fullPointsOrCashArr.find(e => e === this.selectedSortOption.type);
    return currentElement && currentElement.length > 0;
  }

}

angular.module("BookingApp").service("FlightsSearchResultsSorterService", FlightsSearchResultsSorterService);
