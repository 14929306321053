import { AngularUtils } from "booking_app/utils";
import { SecondaryPassengerFormData } from "./secondary-passenger-form-data";
import { CommonPassengerDetailFormData } from "booking_app/components/flights/checkout/common-passenger-detail-form";

export class FlightsCheckoutSecondaryPassengerDetailFormController {
  static $inject = ["$translate"];

  // injectors
  private $translate: any;

  // bindings
  parentForm: any;
  formName: string;
  data: SecondaryPassengerFormData;
  onUpdate: (obj: { data: SecondaryPassengerFormData }) => void;
  submitted: boolean;
  type: string;
  index: number;
  passportRequired: boolean;
  travelSource: string;

  internalData: SecondaryPassengerFormData; // copy of data from bindings

  constructor($translate) {
    this.$translate = $translate;
  }

  $onChanges(changesObj) {
    if (AngularUtils.hasBindingChanged(changesObj.data)) {
      this.internalData = angular.copy(this.data);
    }
  }

  title() {
    // Move this to a proper translation key
    return this.$translate.instant(`flights.checkout.${this.data.type}_caption`, {
      index: this.index + 1,
    });
  }

  onUpdateCommonData(data: CommonPassengerDetailFormData) {
    this.internalData.commonData = data;
    this.onUpdateData();
  }

  onUpdateData() {
    this.onUpdate({ data: this.internalData });
  }

  onResetData(data: SecondaryPassengerFormData): void {
    this.internalData = { ...data, type: this.data.type };
    this.onUpdateData();
  }
}
