import { FlightItem } from "booking_app/types";
import { FlightsItinerary, flightsSegmentId } from "booking_app/models";

export class FlightItemBuilder {

  private originSegmentsId(itinerary: FlightsItinerary): string {
    const segments = itinerary.segments.filter(segment => segment.segment_type === 'origin');
    return segments.reduce((identifier, segment) => {
      return `${identifier}${flightsSegmentId(segment)}`
    }, '');
  }

  public buildOriginFlightItems(itineraries: FlightsItinerary[]): FlightItem[] {
    // remove itineraries that have duplicate first flights, display only the minimum price
    const itineraryHash: { [segmentsId: string]: FlightsItinerary } = {};
    itineraries.forEach((itinerary) => {
      const segmentsId = this.originSegmentsId(itinerary);
      if (!itineraryHash[segmentsId] ||
        (itineraryHash[segmentsId] && itineraryHash[segmentsId].max_points_payment > itinerary.max_points_payment)
      ) {
        itineraryHash[segmentsId] = angular.copy(itinerary);
      }
    })

    // create flight items that only contains origin segments
    Object.keys(itineraryHash).forEach((key) => {
      itineraryHash[key].segments = itineraryHash[key].segments.filter(
        segment => segment.segment_type === 'origin'
      );
    });

    return Object.values(itineraryHash).map(itinerary => FlightItem.buildFromItinerary(itinerary));
  }

  public buildReturnFlightItems(itineraries: FlightsItinerary[], selectedOriginFlightItem: FlightItem): FlightItem[] {
    // use data from selectedOriginFlightItem to filter only itineraries that have same origin flight
    const _itineraries = itineraries.filter(((itinerary) => {
      return this.originSegmentsId(itinerary) === selectedOriginFlightItem.id;
    }));

    // create flight items that only contains return segments
    return _itineraries.map((itinerary) => FlightItem.buildFromItinerary(itinerary, "return"));
  }
}

angular.module("BookingApp").service("FlightItemBuilder", FlightItemBuilder);
