import { PriceChangedModalController } from "./price-changed-modal.controller";
import { Bindings } from "booking_app/types";

export class PriceChangedModalComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      itinerary: Bindings.ONE_WAY,
      searchResult: Bindings.ONE_WAY,
      priceChangeAccepted: Bindings.EXPRESSION
    };

    this.controller = PriceChangedModalController;
    this.templateUrl = "/html/flights/price_changed_modal";
  }
}

angular.module("BookingApp").component("priceChangedModalComponent", new PriceChangedModalComponent());
