import { FlightCabin, FlightItem, FlightSearchParams, FlightType } from "booking_app/types";
import { FlightsItinerary } from "booking_app/models";
import { FlightItemBuilder, FlightsSinglePriceSearchService } from "booking_app/services/flights";
import { NumberUtils, TimeUtils } from "booking_app/utils";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { CouponService } from "booking_app/services/coupon.service";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { PointsCashPaymentHandlingService } from "booking_app/services/points-cash-payment-handling.service";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

type FlightsSegmentType = "origin" | "return";

export class FlightsSummaryPanelController {
  static $inject = [
    "$scope",
    "$rootScope",
    "$translate",
    "$filter",
    "CouponService",
    "FlightItemBuilder",
    "GlobalStateService",
    "PayWithPointsCashService",
    "FlightsSinglePriceSearchService",
    "PointsCashShareService",
    "AppConfig",
    "AppSettings",
    "PointsCashPaymentHandlingService",
  ];

  // bindings
  itinerary: FlightsItinerary;
  flightSearchParams: FlightSearchParams;

  cancellationFeeText: string;
  flightItems: FlightItem[];
  redirectToCheckoutPage: () => void;
  private couponCodeDetails: any;

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $translate: any,
    private $filter: any,
    private couponService: CouponService,
    private flightItemBuilder: FlightItemBuilder,
    private globalStateService: GlobalStateService,
    private payWithPointsCashService: PayWithPointsCashService,
    private singlePriceService: FlightsSinglePriceSearchService,
    private pointsCashShareService: PointsCashShareService,
    private appConfig: any,
    private appSettings: AppSettings,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) {}

  $onInit() {
    this.$scope.globalState = this.$rootScope.globalState;
    this.$scope.globalStateService = this.globalStateService;
    this.$scope.pointsCashShareService = this.pointsCashShareService;
    this.$scope.sliderLeftLabel = this.appSettings.pointsCashSliderSettings.sliderLeftLabel;
    this.$scope.sliderRightLabel = this.appSettings.pointsCashSliderSettings.sliderRightLabel;
    this.$scope.sliderLabel = this.appSettings.pointsCashSliderSettings.sliderLabel;
    this.$scope.alwaysShowPointsSlider = this.appSettings.pointsCashSliderSettings.alwaysShowPointsSlider;
    this.cancellationFeeText = this.appSettings.cancellationFeeText.checkout;
    this.buildFlightItems();
    this.couponCodeDetails = {
      code: "",
      response: {
        valid: false,
        errorMsg: null,
      },
    };
  }

  flightType(): string {
    const flightType = this.flightSearchParams.flightType;
    return Object.keys(FlightType).find(key => FlightType[key] === flightType);
  }

  flightTypeText(): string {
    const flightType = this.flightSearchParams.flightType;
    let flightTypeText = "Return";
    if (flightType === FlightType.ONE_WAY) {
      flightTypeText = "flights.type.one_way";
    }

    return flightTypeText;
  }

  flightLabelText(): string {
    if (this.flightType() === "ONE_WAY") {
      return "flights.label.oneway_flight";
    } else {
      return `flights.label.return_flight`;
    }
  }

  adultCount(): number {
    return this.flightSearchParams.adultCount;
  }

  childCount(): number {
    return this.flightSearchParams.childCount;
  }

  infantCount(): number {
    return this.flightSearchParams.infantCount;
  }

  adultCaption(): string {
    if (this.adultCount() === 1) {
      return this.$translate.instant("count.adult_one");
    } else if (this.adultCount() > 1) {
      return this.$translate.instant("count.adult_other", { count: this.adultCount() });
    }
  }

  childCaption(): string {
    if (this.childCount() === 1) {
      return this.$translate.instant("count.children_one");
    } else if (this.childCount() > 1) {
      return this.$translate.instant("count.children_other", { count: this.childCount() });
    }
  }

  infantCaption(): string {
    if (this.infantCount() === 1) {
      return this.$translate.instant("count.infant_one");
    } else if (this.infantCount() > 1) {
      return this.$translate.instant("count.infant_other", { count: this.infantCount() });
    }
  }

  originDepartureDate(dateFormat: string): string {
    const segments = this.originFlightItem().segments;
    const departureTime = segments[0].departure_time;
    return TimeUtils.formatDateAndTime(departureTime, dateFormat);
  }

  returnDepartureDate(dateFormat: string): string {
    const segments = this.returnFlightItem().segments;
    const departureTime = segments[0].departure_time;
    return TimeUtils.formatDateAndTime(departureTime, dateFormat);
  }

  departureText(): string {
    return `
      ${ this.flightSearchParams.fromAirportName} (${this.flightSearchParams.fromAirport})
      ${ this.$translate.instant("to")}
      ${ this.flightSearchParams.toAirportName} (${this.flightSearchParams.toAirport})
    `;
  }

  returnText(): string {
    return `
      ${ this.flightSearchParams.toAirportName} (${this.flightSearchParams.toAirport})
      ${ this.$translate.instant("to")}
      ${ this.flightSearchParams.fromAirportName} (${this.flightSearchParams.fromAirport})
    `;
  }

  isReturnFlight(): boolean {
    return this.flightSearchParams.flightType === FlightType.RETURN;
  }

  formattedPointsPayment(): any {
    return this.formatNumber(this.totalPointsPayment(), 0);
  }

  totalPointsPayment(): number {
    let pointsDiscountTier: number[] = [...this.itinerary.points_non_fixed_discounts_by_tier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      pointsDiscountTier = pointsDiscountTier.reverse();
    }
    const nonFixedDiscount = pointsDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.pointsToPay(
      this.itinerary.base_points_payment,
      nonFixedDiscount,
      this.itinerary.points_fixed_discount,
    );
  }

  totalCashPayment(): number {
    let cashDiscountTier: number[] = [...this.itinerary.cash_non_fixed_discounts_by_tier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      cashDiscountTier = cashDiscountTier.reverse();
    }
    const nonFixedDiscount = cashDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.cashToPay(
      this.itinerary.base_cash_payment,
      nonFixedDiscount,
      this.itinerary.cash_fixed_discount,
    );
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.totalCashPayment(),
      this.totalPointsPayment(),
    );
  }

  cabinText(): string {
    return this.$translate.instant(FlightCabin.toStringTranslation(this.originFlightItem().segments[0].cabin));
  }

  originArrivalDate(): string {
    return this.buildArrivalDate("origin");
  }

  departureArrivalDate(): string {
    return this.buildArrivalDate("return");
  }

  displayArrivalDate(flightItem: FlightItem): boolean {
    if (flightItem) {
      const segments = flightItem.segments;
      const arrival = segments[segments.length - 1].arrival_time;
      const departure = segments[0].departure_time;
      return this.getOffsetDay(arrival, departure) > 0;
    }
  }

  originFlightItem(): FlightItem {
    return this.flightItemBuilder.buildOriginFlightItems([this.itinerary])[0];
  }

  returnFlightItem(): FlightItem {
    if (this.isReturnFlight()) {
      return this.flightItemBuilder.buildReturnFlightItems([this.itinerary], this.originFlightItem())[0];
    }
  }

  cancellationFee(): number {
    return this.appConfig.cancellation_fee_percentage;
  }

  applyCouponCode(code: string): void {
    this.couponService.validateCouponCode(code).then(() => {
      this.couponService.validatingPriceChanged = true;
      return this.singlePriceService.runSingleSearch(false, code);
    }).finally(() => {
      if (!this.itinerary.has_discount_for_coupon_code && !this.couponService.hasErrorMessage()) {
        this.couponService.couponCodeDetails.response = {
          valid: false,
          errorMsg: "Conditions not met",
        };
      }
      this.couponService.validatingPriceChanged = false;
    });
  }

  resetCouponCode(): void {
    this.couponService.resetCouponCode();
    this.singlePriceService.runSingleSearch(false);
  }

  originFareType(): string {
    const segments = this.originFlightItem().segments;
    return segments[0].fare_brand_name.toLowerCase();
  }

  returnFareType(): string {
    const segments = this.returnFlightItem().segments;
    return segments[0].fare_brand_name.toLowerCase();
  }

  isRevalidatingItinerary(): boolean {
    return this.singlePriceService.isRevalidatingItinerary;
  }

  private getOffsetDay(arrival: string, departure: string): number {
    return TimeUtils.getArrivalDayOffset(arrival, departure);
  }

  private formatNumber(value: number, decimalPlace: number): string {
    return this.$filter("numberFmt")(value, this.$rootScope.selectedLocale, decimalPlace);
  }

  private formatCurrencyValue(value): number {
    return NumberUtils.formatCurrencyValue(
      value,
      this.$rootScope.selectedCurrency,
    );
  }

  private buildFlightItems(): void {
    if (this.flightSearchParams.flightType === FlightType.RETURN) {
      this.flightItems = [this.originFlightItem(), this.returnFlightItem()];
    } else if (this.flightSearchParams.flightType === FlightType.ONE_WAY) {
      this.flightItems = [this.originFlightItem()];
    }
  }

  private buildArrivalDate(arrivalType?: FlightsSegmentType): string {
    let flight = null;
    if (arrivalType === "return") {
      flight = this.returnFlightItem();
    } else if (arrivalType === "origin") {
      flight = this.originFlightItem();
    }
    const segments = flight.segments;
    const arrivalTime = moment(segments[segments.length - 1].arrival_time);
    return moment(arrivalTime).format("D MMM");
  }

}
