import { StripeIntentPaymentFormFlightsController } from "./stripe-intent-payment-form-flights.controller";
import { Bindings } from "booking_app/types";

export class StripeIntentPaymentFormFlightsComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      parentForm: Bindings.ONE_WAY,
      internalData: Bindings.ONE_WAY,
      submitted: Bindings.ONE_WAY,
      onUpdateData: Bindings.EXPRESSION,
    };
    this.controller = StripeIntentPaymentFormFlightsController;
    this.templateUrl = "/html/flights/stripe_intent_payment_form";
  }
}

angular.module("BookingApp").component("stripeIntentPaymentFormFlights", new StripeIntentPaymentFormFlightsComponent());
