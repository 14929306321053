declare var Rollbar: any;

import { ApiDataService } from "booking_app/services/api-data.service";
import {
  FlightsBookingsParams,
  FlightsBookingsPassengersParams,
} from "./bookings-params";
import { FlightsCheckoutData } from "booking_app/components/flights/checkout/checkout-data";
import { DateFormObject } from "booking_app/components/flights/checkout/date-form-object";
import {
  CommonPassengerDetailFormData,
  PrimaryPassengerFormData,
  SecondaryPassengerFormData,
} from "booking_app/components/flights/checkout";
import { CommonBookingParams } from "booking_app/types/common-booking-params";
import { FlightsItinerary } from "booking_app/models";
import { BookingPaymentParams, FlightSearchParams, PaymentChannel, PaymentMethod } from "booking_app/types";
import { ProductType } from "booking_app/types/product-type";
import { PaymentMethodService } from "booking_app/services/payment-method.service";
import { CouponService } from "booking_app/services/coupon.service";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { AdyenFormService } from "booking_app/services/adyen-form.service";
import { CheckoutFormService } from "booking_app/services/checkout/checkout-form.service";
import { AppSettings } from "booking_app/values/app-settings";
import { StripePaymentIntentService } from "booking_app/services/stripe-payment-intent.service";
import { CurrenciesService } from "booking_app/services/currencies.service";

export class FlightsBookingsService {
  static $inject = [
    "$q",
    "$rootScope",
    "ApiDataService",
    "AppUser",
    "AppConfig",
    "PayWithPointsCashService",
    "StripePaymentService",
    "StripePaymentIntentService",
    "PaymentMethodService",
    "CouponService",
    "PointsCashShareService",
    "AdyenFormService",
    "CheckoutFormService",
    "AppSettings",
    "CurrenciesService",
  ];

  isLoading: boolean;
  errorCode: string;
  private data: FlightsCheckoutData;
  private params: FlightSearchParams;
  private itinerary: FlightsItinerary;

  constructor(
    private $q: any,
    private $rootScope: any,
    private apiDataService: ApiDataService,
    private appUser: any,
    private appConfig: any,
    private payWithPointsCashService: PayWithPointsCashService,
    private stripePaymentService: any,
    private stripePaymentIntentService: StripePaymentIntentService,
    private paymentMethodService: PaymentMethodService,
    private couponService: CouponService,
    private pointsCashShareService: PointsCashShareService,
    private adyenFormService: AdyenFormService,
    private checkoutFormService: CheckoutFormService,
    private appSettings: AppSettings,
    private currenciesService: CurrenciesService,
  ) {
    this.resetState();
  }

  resetState(): void {
    this.isLoading = false;
    this.errorCode = null;
  }

  createBooking(
    data: FlightsCheckoutData,
    params: FlightSearchParams,
    itinerary: FlightsItinerary,
  ): Promise<any> {
    this.data = data;
    this.params = params;
    this.itinerary = itinerary;
    this.isLoading = true;

    return this.buildBookingParams()
      .then((bookingParams) => {
        return this.apiDataService.post("bookings", JSON.stringify(bookingParams));
      })
      .then((res) => {
        this.isLoading = false;
        return res;
      })
      .catch((error) => {
        this.createBookingError(`Create Booking: Unable to proceed. Error: ${error}`);
        throw error;
      });
  }

  buildPassengersRevalidateData(checkoutData: FlightsCheckoutData): FlightsBookingsPassengersParams[] {
    const { primaryPassengerData, secondaryPassengerData } = checkoutData;
    return [this.firstPassengerData(primaryPassengerData), ...this.secondaryPassengersData(secondaryPassengerData)];
  }

  showLoading(): void {
    this.isLoading = true;
  }

  hideLoading(): void {
    this.isLoading = false;
  }

  setRevalidateError(error: string): void {
    this.errorCode = error;
  }

  private getDateText(date: DateFormObject): string {
    const day = date.day && date.day.text;
    const month = date.month && date.month.text;
    const year = date.year && date.year.text;

    const dateText = [year, month, day].filter(item => item).join("-");

    return dateText === "" ? null : dateText;
  }

  private commonPassengerData(
    passengerData: CommonPassengerDetailFormData,
    type: string,
  ): FlightsBookingsPassengersParams {
    const {
      dateOfBirth,
      firstName,
      lastName,
      passportNationality,
      passportExpiryDate,
      passportNumber,
      title,
    } = passengerData;

    return {
      date_of_birth: this.getDateText(dateOfBirth),
      first_name: firstName,
      last_name: lastName,
      passport_country: passportNationality.code,
      passport_expiry_date: this.getDateText(passportExpiryDate),
      passport_number: passportNumber,
      salutation: title,
      type,
    };
  }

  private buildAdyenParams() {
    let adyen_payment_type = "default";
    let adyen_card_token = "";
    if (this.paymentMethodService.isUsingSavedAdyenCard()) {
      adyen_payment_type = "adyen-recurring";
      adyen_card_token = this.paymentMethodService.selectedCreditCard.token;
    }
    return {adyen_payment_type, adyen_card_token};
  }

  private buildCommonBookingParams(paymentChannel: PaymentChannel, paymentNonce?: string): CommonBookingParams {
    const params = {
      new_card_save: this.newCreditCardSave(),
      ...this.buildAdyenParams(),
      ...this.buildNonPaymentParams(paymentChannel),
    };

    if (this.requiresTokenization()) {
      params.payment = this.buildPaymentParams(paymentChannel, paymentNonce);
    }

    return params;
  }

  private buildNonPaymentParams(paymentChannel: PaymentChannel): CommonBookingParams {
    const { primaryPassengerData } = this.data;
    const { countryCode, number } = primaryPassengerData.phoneDetails;
    const phone = `${countryCode.id} ${number}`;
    const couponCode: string = this.couponService.couponCodeDetails.code;
    const nonPaymentParams: any = {
      booking_key: this.itinerary.booking_key,
      currency: this.$rootScope.selectedCurrency.code,
      debug_info: {
        points_payment: this.isRedeem() ? Math.max(this.pointsToPay(), 0) : 0,
        cash_payment: Math.max(this.cashToPay(), 0),
        points_earned: !this.isRedeem() ? this.itinerary.points : 0,
        bonus_programs: this.itinerary.bonus_programs,
        bonus_tiers: this.itinerary.bonus_tiers,
        exchange_rate: this.$rootScope.convert_rate,
      },
      lp: this.$rootScope.landingPage.url,
      membership: {
        first_name: null,
        last_name: null,
        number: null,
      },
      payment_channel: paymentChannel,
      partner_id: this.$rootScope.pointsPartner.id,
      pay_with_points_tier: this.isRedeem() ? this.pointsCashShareService.pointsCashShare.value : undefined,
      voucher_ids: [],
      travel_type: "flights",
      user: {
        first_name: this.appUser?.first_name,
        send_marketing: false,
        phone,
        email: primaryPassengerData.email,
      },
      remember_me: primaryPassengerData.commonData.rememberMe,
      coupon_code: this.couponService.isValid() ? couponCode : "",
      points_account_id: this.$rootScope.pointsAccountId,
    };

    if (this.$rootScope.useProductType()) {
      nonPaymentParams.product_type = this.$rootScope.productTypeAdapter(this.$rootScope.globalState.productType);
    }

    return nonPaymentParams;
  }

  private buildPaymentParams(paymentChannel: PaymentChannel, paymentNonce?: string): BookingPaymentParams {
    const { houseNumber, address, city, country, state, postalCode } = this.data.paymentData.addressData;
    const { cardNumber, cardholderName, securityCode, expiryDate } = this.data.paymentData.creditCardData;
    const { first_name, last_name } = this.getNameFromCardholderName(cardholderName);
    const { payment_method, customer } = this.getSavedCardData();
    const new_card_save = this.newCreditCardSave();

    return {
      house_number: houseNumber,
      address,
      city,
      country: country && country.code,
      first_name,
      last_name,
      nonce: paymentNonce,
      postal_code: postalCode,
      state: state && state.code,
      new_card_save,
      ...this.paymentMethodService.filterPaymentData({
        card_number: cardNumber && cardNumber.toString(),
        cvv: securityCode && securityCode.toString(),
        expiration_month: expiryDate && expiryDate.month && expiryDate.month.text,
        expiration_year: expiryDate && expiryDate.year && expiryDate.year.text,
      }),
      ...this.adyenFormService.adyenEncryptedData(this.data.paymentData.creditCardData),
      browser_info: this.data.paymentData.browserInfo,
      payment_method,
      customer,
    };
  }

  private isRedeem() {
    return this.$rootScope.landingPage.hasProductType(ProductType.REDEEM);
  }

  private getNameFromCardholderName(name: string): { first_name: string, last_name: string } {
    if (name === "" || name === null) {
      return {
        first_name: "",
        last_name: "",
      };
    }
    const names = name.split(" ");
    return {
      first_name: names[0],
      last_name: names[names.length - 1],
    };
  }

  private getSavedCardData(): { payment_method: string, customer: string } {
    if (this.stripePaymentIntentService.isUsingSavedCard()) {
      return {
        payment_method: this.paymentMethodService.selectedCreditCard.token,
        customer: this.paymentMethodService.selectedCreditCard.customer_id,
      };
    }

    return {
      payment_method: null,
      customer: null,
    };
  }

  private buildPassengersData(): FlightsBookingsPassengersParams[] {
    const { primaryPassengerData, secondaryPassengerData } = this.data;
    return [this.firstPassengerData(primaryPassengerData), ...this.secondaryPassengersData(secondaryPassengerData)];
  }

  private buildFlightBookingParams(paymentChannel: PaymentChannel, paymentNonce?: string): FlightsBookingsParams {
    return {
      passengers: this.buildPassengersData(),
      ...this.buildCommonBookingParams(paymentChannel, paymentNonce),
    };
  }

  private firstPassengerData(data: PrimaryPassengerFormData): FlightsBookingsPassengersParams {
    return this.commonPassengerData(data.commonData, "adult");
  }

  private secondaryPassengersData(data: SecondaryPassengerFormData[]): FlightsBookingsPassengersParams[] {
    return data.map(d => this.commonPassengerData(d.commonData, d.type));
  }

  private processTokenizationError(message: string): void {
    this.isLoading = false;
    this.errorCode = "Payment Error";
    Rollbar.info(message);
  }

  private createBookingError(message: string): void {
    this.isLoading = false;
    this.errorCode = "Payment Error";
    Rollbar.critical(message);
  }

  private getStripeToken(): Promise<string> {
    const { cardNumber, securityCode, expiryDate, cardholderName } = this.data.paymentData.creditCardData;
    const { address, city, state, postalCode, country } = this.data.paymentData.addressData;
    const { first_name } = this.getNameFromCardholderName(cardholderName);

    return this.stripePaymentService.getToken(
      cardNumber,
      securityCode,
      expiryDate.month.text,
      expiryDate.year.text,
      first_name,
      address,
      city,
      state && state.code,
      postalCode,
      country.code,
    )
    .then(token => token)
    .catch((error) => {
      this.processTokenizationError(`Stripe: Unable to tokenize payment details. Error: ${error}`);
      throw error;
    });
  }

  private createStripeSource(token: string): Promise<string> {
    const usage = this.newCreditCardSave() ? "reusable" : "single_use";
    return this.stripePaymentService.createSource(token, usage)
      .then((source) => {
        return source;
      })
      .catch((error) => {
        this.processTokenizationError(`Stripe: Unable to create payment source. Error: ${error}`);
        throw error;
      });
  }

  private newCreditCardSave(): boolean {
    return this.paymentMethodService.allowSaveCreditCard() &&
      this.paymentMethodService.checkedSaveCreditCard &&
      !this.hasSelectedSavedCreditCard();
  }

  private requiresTokenization(): boolean {
    return (!this.isRedeem() ||
      (
        this.itinerary &&
        this.cashToPay() > 0
      )) &&
      !this.isPayAnyone();
  }

  private cashToPay(): number {
    let cashDiscountTier: number[] = [...this.itinerary.cash_non_fixed_discounts_by_tier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      cashDiscountTier = cashDiscountTier.reverse();
    }
    const nonFixedDiscount = cashDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.cashToPay(
      this.itinerary.base_cash_payment,
      nonFixedDiscount,
      this.itinerary.cash_fixed_discount,
    );
  }

  private pointsToPay(): number {
    let pointsDiscountTier: number[] = [...this.itinerary.cash_non_fixed_discounts_by_tier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      pointsDiscountTier = pointsDiscountTier.reverse();
    }
    const nonFixedDiscount = pointsDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.pointsToPay(
      this.itinerary.base_points_payment,
      nonFixedDiscount,
      this.itinerary.points_fixed_discount,
    );
  }

  private hasSelectedSavedCreditCard(): boolean {
    return this.paymentMethodService.activePaymentTab === PaymentMethod.SAVED_CARDS &&
           this.paymentMethodService.selectedCreditCard &&
           this.paymentMethodService.selectedCreditCard.token !== "";
  }

  private buildBookingParams(): Promise<FlightsBookingsParams> {
    if (this.isPayAnyone()) {
      this.data.paymentData.creditCardData.paymentChannel = PaymentChannel.PAY_ANYONE;
    }

    if (this.usePaymentChannel() && this.isNotAdyenChannel() && !this.paymentMethodService.isUsingSavedAdyenCard()) {
      switch (this.$rootScope.selectedCurrency.preferredGateway) {
        case "stripe":
          return this.buildStripeBookingParams();
      }
    }

    if (this.paymentMethodService.isUsingSavedAdyenCard()) {
        const adyenPaymentChannel = this.paymentMethodService.getPaymentChannelFromAdyenSavedCard();
        switch (adyenPaymentChannel) {
          case "adyen":
            this.data.paymentData.creditCardData.paymentChannel = PaymentChannel.ADYEN;
            break;
          case "adyen_alipay":
            this.data.paymentData.creditCardData.paymentChannel = PaymentChannel.ADYEN_ALIPAY;
            break;
          case "adyen_diners":
            this.data.paymentData.creditCardData.paymentChannel = PaymentChannel.ADYEN_DINERS;
            break;
          case "adyen_jcb":
            this.data.paymentData.creditCardData.paymentChannel = PaymentChannel.ADYEN_JCB;
            break;
          case "adyen_unionpay":
            this.data.paymentData.creditCardData.paymentChannel = PaymentChannel.ADYEN_UNIONPAY;
            break;
        }
        const params = this.buildFlightBookingParams(this.data.paymentData.creditCardData.paymentChannel);
        return this.$q(res => res(params));
    } else {
      const params = this.buildFlightBookingParams(this.data.paymentData.creditCardData.paymentChannel);
      return this.$q(res => res(params));
    }
  }

  private buildStripeBookingParams(): Promise<FlightsBookingsParams> {
    // Use saved credit card old flow
    if (!this.appSettings.stripePaymentIntentsEnabled && this.hasSelectedSavedCreditCard()) {
      const paymentNonce = this.paymentMethodService.selectedCreditCard.token;
      const params = this.buildFlightBookingParams(PaymentChannel.STRIPE, paymentNonce);
      return this.$q(res => res(params));
    }

    if (this.requiresTokenization()) {
      if (this.appSettings.stripePaymentIntentsEnabled) {
        this.data.paymentData.creditCardData.paymentChannel = PaymentChannel.STRIPE_PAYMENT_INTENTS;
        return this.$q(res => res(this.buildFlightBookingParams(PaymentChannel.STRIPE_PAYMENT_INTENTS)));
      } else {
        // OLD STRIPE PAYMENT FLOW
        return this.getStripeToken()
          .then((token) => {
            return this.createStripeSource(token);
          })
          .then((source) => {
            return this.buildFlightBookingParams(PaymentChannel.STRIPE, source);
          })
          .catch((error) => {
            this.createBookingError(`Create Booking: Unable to proceed. Error: ${error}`);
            throw error;
          });
      }
    } else {
      const params = this.buildFlightBookingParams(PaymentChannel.STRIPE);
      return this.$q(res => res(params));
    }
  }

  private isPayAnyone(): boolean {
    return this.paymentMethodService.activePaymentTab === PaymentMethod.PAY_ANYONE;
  }

  private usePaymentChannel(): boolean {
    return !this.checkoutFormService.isExternalPaymentProvider(this.data.paymentData.creditCardData.paymentChannel);
  }

  private isNotAdyenChannel(): boolean {
    return !this.data.paymentData.creditCardData.paymentChannel.includes("adyen");
  }
}

angular.module("BookingApp").service("FlightsBookingsService", FlightsBookingsService);
