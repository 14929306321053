import { Bindings } from "booking_app/types";
import { FlightLocationSearchController } from "./flight-location-search.controller";

export class FlightLocationSearchComponent {
  bindings: any = {
    label: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    datasetName: Bindings.ONE_WAY, // Unique identifier for dataset, required
    value: Bindings.ONE_WAY,
    errorMessage: Bindings.ONE_WAY,
  };
  controller: any = FlightLocationSearchController;
  template: string = `
    <ui-select-destination-search
      close-on-blur
      ng-model="$ctrl.internalValue"
      theme="bootstrap"
      ng-class="{'invalid': $ctrl.showError()}"
      on-select="$ctrl.onSelectCallback($item, $model)">
      <ui-select-match
        aria-hide-on-empty
        placeholder="{{ $ctrl.label | translate }}"
        aria-label="{{$ctrl.internalValue}}"
        role="text">{{$ctrl.internalValue}}</ui-select-match>
       <ui-select-choices repeat="airport in $ctrl.airports track by $index"
            refresh="$ctrl.fetchAirports($select.search)"
            refresh-delay="1">
        <div ng-bind-html="$ctrl.searchResultQueryFormat(airport) | highlight: $select.search"></div>
        <small>
          {{airport.country}}
        </small>
      </ui-select-choices>
      <ul class="ui-select-no-choice dropdown-menu" ng-show="$ctrl.noAirportsFound($select.search)"
        aria-hidden="{{ $select.open ? 'false' : 'true' }}">
        <li>
          {{ $ctrl.searchStatusMsg }}
        </li>
      </ul>
    </ui-select-destination-search>
    <div class="dirtyMsg tooltips"
         ng-class="$ctrl.datasetName"
         ng-show="$ctrl.errorMessage !== ''"
         aria-live="assertive">
      <span>{{ $ctrl.errorMessage }}</span>
    </div>
  `;
}

angular.module("BookingApp").component("flightLocationSearch", new FlightLocationSearchComponent());
