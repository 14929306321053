import { FlightsSearchResultsController } from ".";

export class FlightsSearchResultsComponent {
  templateUrl: string;
  controller: any;

  constructor() {
    this.templateUrl = '/html/flights/search_results';
    this.controller = FlightsSearchResultsController;
  }
}

angular.module("BookingApp").component("flightsSearchResultsComponent", new FlightsSearchResultsComponent());
