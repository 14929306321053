import { convertJSONToQueryString } from 'booking_app/services/route-params-utils';
import { FlightsSearchFormState } from 'booking_app/components/flights/search-form';

declare var moment: any;

export class FlightsTilesController {
  static $inject = [
    "$rootScope",
    "$location",
    "$window",
    "FlightsSearchFormState"
  ];

  // injectors
  private $rootScope: any;
  private $location: any;
  private $window: any;
  private formState: FlightsSearchFormState;

  // bindings
  name: string;
  image: string;
  origin: string;
  destination: string;
  id: string;

  constructor(
    $rootScope: any,
    $location: any,
    $window: any,
    flightsSearchFormState: FlightsSearchFormState,
  ) {
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$window = $window;
    this.formState = flightsSearchFormState;
  }

  runCustomSearch(): void {
    let url = this.queryParams(this.origin, this.destination);
    if (this.$rootScope.landingPage.quickSearchInNewTab) {
      this.$window.open(url, '_blank');
    } else {
      this.$window.scrollTo(0, 0);
      this.$location.url(url);
    }
    return
  }

  queryParams(origin, destination): string {
    let baseDate = moment();
    let departureDaysLater = 14;
    let departureDate = baseDate.add(departureDaysLater, 'd').format('YYYY-MM-DD');
    let returnDaysLater = 7;
    let returnDate = baseDate.add(returnDaysLater, 'd').format('YYYY-MM-DD');
    let urlParams = {
      fromAirport: origin,
      toAirport: destination,
      departureDate: departureDate,
      returnDate: returnDate,
      cabin: 'Y',
      passengers: '1:0:0',
      flightType: 'rt',
      currency: this.$rootScope.selectedCurrency.code,
      landingPage: this.$rootScope.landingPage.url,
      partnerId: this.$rootScope.pointsPartner.id
    }
    return `/flights/results?${convertJSONToQueryString(urlParams)}`
  }
}
