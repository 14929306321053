import { FlightItem, FlightType } from "booking_app/types";
import { FlightsSegment } from "booking_app/models";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

export class FlightsSummaryHeaderController {
  static $inject = [
    "$translate",
    "AppSettings",
  ];

  flightItem: FlightItem;

  constructor(
    private $translate: any,
    private appSettings: AppSettings,
  ) {
  }

  firstSegment(): any {
    return this.flightItem.segments[0];
  }

  lastSegment(): any {
    return this.flightItem.segments[Object.keys(this.flightItem.segments).length - 1];
  }

  departureTime(): string {
    return moment(this.firstSegment().departure_time).format("D MMMM YYYY");
  }

  departureTimeWithDay(): string {
    return moment(this.firstSegment().departure_time).format("ddd, D MMM YYYY");
  }

  flightText(): string {
    if (this.appSettings.hasCustomflightSummaryHeaderText) {
      return this.headerText();
    } else {
      return this.sharedText();
    }
  }

  segmentType(): string {
    if (this.firstSegment().segment_type === "origin") {
      return "depart";
    } else {
      return "return";
    }
  }

  isDepartTrip(): boolean {
    return this.firstSegment().segment_type === "origin";
  }

  private headerText(): string {
    const tripDate = { date: this.departureTime() };
    if (this.firstSegment().segment_type === "origin") {
      return this.$translate.instant(`flights.label.departing_flight_on`, tripDate);
    } else if (this.firstSegment().segment_type === "return") {
      return this.$translate.instant(`flights.label.returning_flight_on`, tripDate);
    }
  }

  private sharedText(): string {
    const tripValues = {
      departure: `${this.firstSegment().from_airport_name} (${this.firstSegment().from_airport})`,
      arrival: `${this.lastSegment().to_airport_name} (${this.lastSegment().to_airport})`,
      date: this.departureTime(),
    };

    if (this.firstSegment().segment_type === "origin") {
      return this.$translate.instant("flights.label.departing_flight_from_to_on", tripValues);
    } else if (this.firstSegment().segment_type === "return") {
      return this.$translate.instant("flights.label.returning_flight_from_to_on", tripValues);
    }
  }
}
