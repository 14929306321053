import { FlightsCheckoutController } from "./checkout.controller";

export class FlightsCheckoutComponent {
  controller: any;
  templateUrl: string;

  constructor() {
    this.controller = FlightsCheckoutController;
    this.templateUrl = '/html/flights/checkout';
  }
}

angular.module("BookingApp").component("flightsCheckoutComponent", new FlightsCheckoutComponent());
