import { FlightItem } from "booking_app/types";
import { FlightItemFilter } from "../flight-item-filter";
import { StopoverFilterUnit } from "./stopover-filter-unit";
import { WhitelabelTranslateService } from "booking_app/services/whitelabel-translate.service";

export class StopoverFilter extends FlightItemFilter {
  allFilterUnits: StopoverFilterUnit[];
  activeFilterUnits: StopoverFilterUnit[];

  constructor(
    private whitelabelTranslateService: WhitelabelTranslateService,
  ) {
    super();
    this.allFilterUnits = this.buildFilterUnits();
    this.reset();
  }

  performSingle(filter: StopoverFilterUnit, flightItem: FlightItem): boolean {
    return flightItem.segments.length >= filter.minSegmentCount &&
      flightItem.segments.length <= filter.maxSegmentCount;
  }

  perform(flightItem: FlightItem): boolean {
    if (this.activeFilterUnits.length === 0) {
      return true;
    }

    return this.activeFilterUnits.some(filterUnit => this.performSingle(filterUnit, flightItem));
  }

  reset(): void {
    this.activeFilterUnits = [];
  }

  toggleFilter(filterUnit: StopoverFilterUnit) {
    const filterUnitIndex: number = this.activeFilterUnits.findIndex(unit => unit.id === filterUnit.id);
    const filterIsActive: boolean = filterUnitIndex > -1;
    if (filterIsActive) {
      this.activeFilterUnits.splice(filterUnitIndex, 1);
    } else {
      this.activeFilterUnits.push(filterUnit);
    }
  }

  findFilter(filterUnit: StopoverFilterUnit): StopoverFilterUnit {
    return this.activeFilterUnits.find(unit => unit.id === filterUnit.id);
  }

  private buildFilterUnits(): StopoverFilterUnit[] {
    return [{
      minSegmentCount: 1,
      maxSegmentCount: 1,
      label: "Direct",
      id: "stopover-1-1",
    }, {
      minSegmentCount: 2,
      maxSegmentCount: 2,
      label: this.whitelabelTranslateService.translate("one_stop"),
      id: "stopover-2-2",
    }, {
      minSegmentCount: 3,
      maxSegmentCount: 100,
      label: this.whitelabelTranslateService.translate("two_stop"),
      id: "stopover-3-100",
    }];
  }
}
