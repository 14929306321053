import { FlightsItinerary, FlightsSinglePriceSearchResult } from "booking_app/models";
import { FlightUrlGenerator } from "booking_app/services/flights/url-generator";
import { NumberUtils } from "booking_app/utils";
import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { AppSettings } from "booking_app/values/app-settings";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { PointsCashPaymentHandlingService } from "booking_app/services/points-cash-payment-handling.service";

export class PriceChangedModalController {
  static $inject = [
    "$rootScope",
    "$location",
    "$filter",
    "FlightUrlGenerator",
    "PayWithPointsCashService",
    "AppSettings",
    "PointsCashShareService",
    "PointsCashPaymentHandlingService",
  ];

  // bindings
  itinerary: FlightsItinerary;
  searchResult: FlightsSinglePriceSearchResult;
  priceChangeAccepted: () => void;

  constructor(
    private $rootScope: any,
    private $location: any,
    private $filter: any,
    private flightUrlGenerator: FlightUrlGenerator,
    private payWithPointsCashService: PayWithPointsCashService,
    private appSettings: AppSettings,
    private pointsCashShareService: PointsCashShareService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) {}

  isSearchParamsAvailable(): boolean {
    return !!this.searchResult.search && Object.keys(this.searchResult.search).length > 0;
  }

  redirectToMainPage(): void {
    this.$location.url("/");
  }

  searchAgain(): void {
    const url = this.flightUrlGenerator.generateSearchResultsUrl(this.searchResult);
    this.$location.url(url);
  }

  priceAccepted(): void {
    this.priceChangeAccepted();
  }

  formattedPointsPayment(): any {
    return this.formatNumber(this.totalPointsPayment(), 0);
  }

  formattedCashPayment(): any {
    return this.formatNumber(
      this.formatCurrencyValue(this.totalCashPayment()),
      this.$rootScope.selectedCurrency.decimalPlace,
    );
  }

  formattedEarnCashPayment(): any {
    return this.formatNumber(
      this.formatCurrencyValue(this.itinerary.max_cash_payment * this.$rootScope.selectedCurrency.rate),
      this.$rootScope.selectedCurrency.decimalPlace,
    );
  }

  totalPointsPayment(): number {
    let pointsDiscountTier: number[] = [...this.itinerary.cash_non_fixed_discounts_by_tier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      pointsDiscountTier = pointsDiscountTier.reverse();
    }
    const nonFixedDiscount = pointsDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.pointsToPay(
      this.itinerary.base_points_payment,
      nonFixedDiscount,
      this.itinerary.points_fixed_discount,
    );
  }

  totalCashPayment(): number {
    let cashDiscountTier: number[] = [...this.itinerary.cash_non_fixed_discounts_by_tier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      cashDiscountTier = cashDiscountTier.reverse();
    }
    const nonFixedDiscount = cashDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.cashToPay(
      this.itinerary.base_cash_payment,
      nonFixedDiscount,
      this.itinerary.cash_fixed_discount,
    );
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.totalCashPayment(),
      this.totalPointsPayment(),
    );
  }

  private formatNumber(value: number, decimalPlace: number): string {
    return this.$filter("numberFmt")(value, this.$rootScope.selectedLocale, decimalPlace);
  }

  private formatCurrencyValue(value): number {
    return NumberUtils.formatCurrencyValue(
      value,
      this.$rootScope.selectedCurrency
    );
  }

}
