import { FlightsAirportSearchService } from "booking_app/services/flights";
import { FlightAirportSearchResult } from "booking_app/models";
import { AngularUtils } from "booking_app/utils";

const DEBOUNCE_TIMEOUT = 300;

export class FlightLocationSearchController {
  static $inject = [
    "FlightsAirportSearchService",
    "$translate",
    "$timeout",
    "$element",
    "$scope",
  ];

  // bindings
  label: string;
  value: FlightAirportSearchResult;
  onUpdate: (value) => void;
  datasetName: string;
  errorMessage: string;

  private internalValue: any;
  private fetchTimeout: any;
  private airports: FlightAirportSearchResult[] = [];
  private searchStatusMsg: string;

  constructor(
    private flightsAirportSearchService: FlightsAirportSearchService,
    private $translate: any,
    private $timeout: any,
    private $element: any,
    private $scope: any,
  ) {
    this.initializeSearch();
  }

  $onChanges(changes: any) {
    if (changes && changes.value) {
      this.internalValue = this.searchResultQueryFormat(changes.value.currentValue);
    }
  }

  onSelectCallback(airport: FlightAirportSearchResult): void {
    this.onUpdate({ value: airport });
  }

  fetchAirports(query: string = "") {

    if (this.fetchTimeout) {
      this.$timeout.cancel(this.fetchTimeout);
      this.fetchTimeout = null;
    }

    // Basic debounce applied
    this.fetchTimeout = this.$timeout(() => {
      this.handleSearchInProgress();
      this.flightsAirportSearchService.airportSearch(query).then((resultsArr: FlightAirportSearchResult[]) => {
        if (query.length > 1) {
          if (resultsArr.length > 0) {
            this.airports = resultsArr;
          } else {
            this.handleSearchHasNoLocations();
          }
        } else {
          this.airports = [];
        }
      }).catch(() => {
        this.initializeSearch();
      });
    }, DEBOUNCE_TIMEOUT);
  }

  noAirportsFound(): boolean {
    return this.searchStatusMsg !== "" && this.airports.length === 0 ;
  }

  searchResultQueryFormat(res: FlightAirportSearchResult): string {
    if (res && res.city && res.term) {
      return `${res.city} - ${res.term} (${res.value})`;
    } else if (res?.term && res?.value) {
      return `${res.term} (${res.value})`;
    } else if (res?.value) {
      return `${res.value}`;
    } else {
      return "";
    }
  }

  private initializeSearch(): void {
    this.searchStatusMsg = "";
    this.airports = [];
  }

  private handleSearchInProgress(): void {
    this.searchStatusMsg = this.$translate.instant("Searching ...");
  }

  private handleSearchHasNoLocations(): void {
    this.searchStatusMsg = this.$translate.instant("Airport Not Found");
    this.airports = [];
  }
}
