import { FlightsSearchResultsFilters, StopoverFilterUnit, AirlineFilterUnit } from "../filters";
import { FlightItem } from "booking_app/types";
import { TimeUtils, DisplayUtils } from "booking_app/utils";
import { ProductType } from "booking_app/types/product-type";
import { AppSettings } from "booking_app/values/app-settings";
import { FlightsSearchResultsSorterService } from "../sorter/sorter.service";
import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { SortService } from "booking_app/services/sort.service";

export class FlightsSearchResultsFilterPanelController {
  static $inject = [
    "$timeout",
    "$scope",
    "$rootScope",
    "DisplayUtils",
    "PayWithPointsCashService",
    "SortService",
    "AppSettings",
    "FlightsSearchResultsSorterService",
  ];

  // bindings
  filters: FlightsSearchResultsFilters;
  onUpdateFilters: Function;
  flightItems: FlightItem[]; // to display count
  isFetchingPrices: boolean;
  showApplyButton: boolean;
  onApplyButtonClicked: Function;
  showRedemptionRangeSlider: boolean;

  departureSliderOptions: any;
  arrivalSliderOptions: any;
  priceSliderOptions: any;

  stopoverFilterUnitCount: { [id: string]: number };
  airlineFilterUnitCount: { [id: string]: number };

  private internalFilters: FlightsSearchResultsFilters;
  private sliderTimeout: any;

  constructor(
    private $timeout: any,
    private $scope: any,
    private $rootScope: any,
    private displayUtils: DisplayUtils,
    private payWithPointsCashService: PayWithPointsCashService,
    private sortService: SortService,
    private appSettings: AppSettings,
    private flightsSearchResultsSorterService: FlightsSearchResultsSorterService,
  ) {
    this.stopoverFilterUnitCount = {};
    this.airlineFilterUnitCount = {};
    this.departureSliderOptions = this.buildTimeSliderOptions();
    this.arrivalSliderOptions = this.buildTimeSliderOptions();
    this.priceSliderOptions = this.buildPriceSliderOptions();
    this.showRedemptionRangeSlider = this.checkShouldShowRedemptionRangeSlider();
  }

  $onInit(): void {
    this.updateFilterData();
    this.setupPointsCashSliderEventListener();
  }

  $onChanges(changesObj: any): void {
    if (changesObj.filters) {
      this.internalFilters = angular.copy(this.filters);
    }
    if (
      changesObj.flightItems &&
      changesObj.flightItems.previousValue &&
      !angular.equals(changesObj.flightItems.currentValue, changesObj.flightItems.previousValue)
    ) {
      this.updateFilterData();
    }
  }

  updateAirlinesList(): void {
    // Hash of airline code as key and airline name as value
    const airlineNames: { [airline_code: string]: string } = {};

    this.flightItems.forEach((item) => {
      item.segments.forEach((segment) => {
        airlineNames[segment.marketing_airline_code] = segment.marketing_airline_name;
      });
    });

    const airlinesList = Object.keys(airlineNames).map((key) => {
      return {
        label: airlineNames[key],
        id: key,
      };
    });

    const sortedAirlinesList = this.sortService.string(airlinesList, "label");

    this.internalFilters.airline.buildFilterUnits(sortedAirlinesList || []);
  }

  updatePriceSlider(updateFromTrigger: boolean = false): void {
    const priceObject = this.getMinMaxPrice();
    const minPrice = priceObject.min;
    const maxPrice = priceObject.max;

    this.priceSliderOptions = this.buildPriceSliderOptions(minPrice, maxPrice);
    if (updateFromTrigger && this.appSettings.payWithPoints.roundDirection === "down") {
      // Fixes the round down issue and only goes in when its from a trigger
      this.internalFilters.price.updateAllowedValues(minPrice - 1, maxPrice + 1);
    } else {
      this.internalFilters.price.updateAllowedValues(minPrice, maxPrice);
    }
  }

  stopoverFilterValue(filterUnit: StopoverFilterUnit): boolean {
    return !!this.internalFilters.stopover.findFilter(filterUnit);
  }

  airlineFilterValue(filterUnit: AirlineFilterUnit): boolean {
    return !!this.internalFilters.airline.findFilter(filterUnit);
  }

  toggleStopoverFilter(filterOption): void {
    this.internalFilters.stopover.toggleFilter(filterOption);
    this.updateFilters();
    this.priceFilterUpdateForRoundDown();
  }

  toggleAirlineFilter(filterOption): void {
    this.internalFilters.airline.toggleFilter(filterOption);
    this.updateFilters();
    this.priceFilterUpdateForRoundDown();
  }

  showFilter(): boolean {
    return !this.isFetchingPrices;
  }

  resetFilters(): void {
    Object.keys(this.internalFilters).forEach((key) => {
      this.internalFilters[key].reset();
    });
    this.updateFilters();
  }

  setupPointsCashSliderEventListener(): void {
    this.$scope.$on("pointsCashSliderUpdate", () => {
      this.updatePriceSlider();
      this.updateFilters();
      this.flightsSearchResultsSorterService.initializeSortOptions();
      this.flightsSearchResultsSorterService
        .updateSortingOptionsForPayWithPoints();
    });
  }

  triggerStopOverFilter(filterUnit: StopoverFilterUnit, event: KeyboardEvent): void {
    if (event.key === "Enter" || event.code === "Space") {
      event.preventDefault();
      this.toggleStopoverFilter(filterUnit);
      this.focusElement(event.currentTarget);
    }
  }

  triggerAirlineFilter(filterUnit: AirlineFilterUnit, event: KeyboardEvent): void {
    if (event.key === "Enter" || event.code === "Space") {
      event.preventDefault();
      this.toggleAirlineFilter(filterUnit);
      this.focusElement(event.currentTarget);
    }
  }

  private priceFilterUpdateForRoundDown(): void {
    if (this.appSettings.payWithPoints.roundDirection === "down") {
      this.updatePriceSlider(true);
    }
  }

  private getMinMaxPrice(): { min: number, max: number } {
    const minPrice = this.flightItems.reduce((min, item) => Math.min(min, item.maxPriceInPoints), 1000000);
    const maxPrice = this.flightItems.reduce((max, item) => Math.max(max, item.maxPriceInPoints), 0);

    return { min: minPrice, max: maxPrice };
  }

  private focusElement(element: any): void {
    // Had to add this function because when we trigger the filter
    // the whole component contents is destroyed and replaced
    // the focus moves out the body therefore destroying the wcag focus
    this.$timeout(() => {
      angular.element("#" + element.id).focus();
    });
  }

  private updateFilterData(): void {
    this.updateAirlinesList();
    if (this.isRedeem()) {
      this.updatePriceSlider();
    }
    this.resetFilterCount();
    this.updateFilterCount();
    this.updateFilters();
  }

  private isRedeem() {
    return this.$rootScope.landingPage.hasProductType(ProductType.REDEEM);
  }

  private updateFilters(): void {
    this.onUpdateFilters({ filters: this.internalFilters });
  }

  private resetFilterCount(): void {
    Object.keys(this.stopoverFilterUnitCount).forEach((key) => { this.stopoverFilterUnitCount[key] = 0 })
    Object.keys(this.airlineFilterUnitCount).forEach((key) => { this.airlineFilterUnitCount[key] = 0 })
  }

  private updateFilterCount(): void {
    this.stopoverFilterUnitCount = this.countStopoverFilterUnits();
    this.airlineFilterUnitCount = this.countAirlineFilterUnits();
  }

  private countStopoverFilterUnits(): { [id: string]: number } {
    return this.internalFilters.stopover.allFilterUnits.reduce((all, unit) => {
      all[unit.id] = this.flightItems.filter((item) => this.internalFilters.stopover.performSingle(unit, item)).length;
      return all;
    }, {});
  }

  private countAirlineFilterUnits(): { [id: string]: number } {
    return this.internalFilters.airline.allFilterUnits.reduce((all, unit) => {
      all[unit.id] = this.flightItems.filter((item) => this.internalFilters.airline.performSingle(unit, item)).length;
      return all;
    }, {});
  }

  private onSliderUpdate(): void {
    if (this.sliderTimeout) { this.$timeout.cancel(this.sliderTimeout) }
    this.sliderTimeout = this.$timeout(() => { this.updateFilters() }, 200)
  }

  private buildTimeSliderOptions(): object {
    return {
      floor: TimeUtils.MIN_MINUTES_IN_DAY,
      ceil: TimeUtils.MAX_MINUTES_IN_DAY,
      step: 1,
      precision: 0,
      onChange: () => this.onSliderUpdate(),
      translate: (value) => TimeUtils.numberToHours(value)
    };
  }

  private buildPriceSliderOptions(floor = 0, ceil = 1000000): object {
    return {
      floor: floor,
      ceil: ceil,
      step: 1,
      precision: 0,
      rightToLeft: this.isRtlDirection(),
      onChange: () => this.onSliderUpdate(),
      translate: (points) => this.translatePoints(points)
    }
  }

  private translatePoints(points): string {
    const pointsToPay = this.payWithPointsCashService.pointsToPay(points);

    if (this.appSettings.showCurrencyInFilterSliders) {
      return `${this.displayUtils.formatNumber(pointsToPay)} ${this.displayUtils.partnerShortCurrency()}`;
    } else {
      return `${this.displayUtils.formatNumber(pointsToPay)}`;
    }
  }

  private isRtlDirection(): boolean {
    return this.$rootScope.globalState.displayAlignment === "rtl";
  }

  private checkShouldShowRedemptionRangeSlider(): boolean {
    return this.$rootScope.landingPage.hasProductType("redeem") &&
      !this.$rootScope.hideRedemptionSliderOnFullCash() &&
      !this.appSettings.hideRedemptionRangeSlider;
  }
}
