import { Bindings } from "booking_app/types";
import { FlightsSearchFormController } from "./flights-search-form.controller";

export class FlightsSearchFormComponent {
  public bindings: any;
  public controller: any;
  public templateUrl: string;

  constructor() {
    this.bindings = {
      onSearch: Bindings.EXPRESSION,
      labelGroup: Bindings.ONE_WAY,
    };
    this.controller = FlightsSearchFormController;
    this.templateUrl = '/html/flights/search_form';
  }
}

angular.module("BookingApp").component('flightsSearchFormComponent', new FlightsSearchFormComponent());
