import { FlightsSearchResultsSorterController } from "./sorter.controller";
import { Bindings } from "../../../../types";

export class FlightsSearchResultsSorterModalComponent {
  bindings: any;
  controller: any;
  template: string;

  constructor() {
    this.bindings = {
      onSelectedSortOptionChange: Bindings.EXPRESSION,
    };
    this.controller = FlightsSearchResultsSorterController;
    this.template = `<div class="sort-options"
      ng-class="{ 'selected': so == $ctrl.flightsSearchResultsSorterService.selectedSortOption }"
      ng-click="$ctrl.updateSelectedSortOption(so)"
      ng-repeat="so in $ctrl.sortOptions">
        <span ng-class="$ctrl.elementClass(so.text)" translate="{{so.text}}"></span>
        <div class="fa fa-check check-select"></div>
      </div>
    `;
  }
}

angular.module("BookingApp").component("flightsSearchResultsSorterModalComponent", new FlightsSearchResultsSorterModalComponent());
