import { FlightsCheckoutErrorMapperController } from "./error-mapper.controller";
import { Bindings } from "booking_app/types";

export class FlightsCheckoutErrorMapperComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      errorCode: Bindings.ONE_WAY,
    };
    this.controller = FlightsCheckoutErrorMapperController;
    this.templateUrl = '/html/flights/checkout_error_mapper';
  }
}

angular.module("BookingApp").component("flightsCheckoutErrorMapperComponent", new FlightsCheckoutErrorMapperComponent());
