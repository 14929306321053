import { FlightsSearchResultsFilterPanelController } from ".";
import { Bindings } from "booking_app/types";

export class FlightsSearchResultsFilterPanelComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      flightItems: Bindings.ONE_WAY,
      filters: Bindings.ONE_WAY,
      onUpdateFilters: Bindings.EXPRESSION,
      isFetchingPrices: Bindings.ONE_WAY,
      showApplyButton: Bindings.ONE_WAY,
      onApplyButtonClicked: Bindings.EXPRESSION,
    };
    this.controller = FlightsSearchResultsFilterPanelController;
    this.templateUrl = '/html/flights/filter_panel';
  }
}

angular.module("BookingApp").component("flightsSearchResultsFilterPanelComponent", new FlightsSearchResultsFilterPanelComponent());
