import { FlightsTilesController } from ".";
import { Bindings } from "booking_app/types";

export class FlightsTilesComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      name: Bindings.ONE_WAY,
      image: Bindings.ONE_WAY,
      origin: Bindings.ONE_WAY,
      destination: Bindings.ONE_WAY,
      id: Bindings.ONE_WAY
    }
    this.templateUrl = '/html/flights/tiles';
    this.controller = FlightsTilesController;
  }
}

angular.module("BookingApp").component("flightsTilesComponent", new FlightsTilesComponent());
