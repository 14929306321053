import { Tenant } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { UrlUtils } from "booking_app/utils";
import { FlightsSinglePriceSearchService } from "booking_app/services/flights";

export class FlightsTermsController {
  static $inject = [
    "$rootScope",
    "$routeParams",
    "$modal",
    "$translate",
    "ApiPollingService",
    "AppSettings",
    "UrlUtils",
    "FlightsSinglePriceSearchService",
  ];

  linkText: string;
  bookingKey: string;
  bookingRules: any;
  linkTarget: string;
  customTitle: string;

  private desktopModalOpen: boolean;
  private mobileModalOpen: boolean;

  constructor(
    private $rootScope: any,
    private $routeParams: any,
    private $modal: any,
    private $translate: any,
    private apiPollingService: any,
    private appSettings: AppSettings,
    private urlUtils: UrlUtils,
    private singlePriceService: FlightsSinglePriceSearchService,
  ) { }

  $onInit() {
    this.bookingKey = this.$routeParams.booking_key;
    this.linkTarget = this.appSettings.openInTargetTab.allLinksTarget;
    this.customTitle = this.appSettings.customFlightsTermsAndConditionsModalTitle;
    if (!this.linkText) { this.linkText = "Read airfare terms and conditions"; }
  }

  openDesktopTermsModal(event?: any): void {
    this.preventDefaultAndStopPropagation(event);

    if (this.desktopModalOpen) {
      return;
    }

    this.desktopModalOpen = true;
    this.$modal.open({
      animation: true,
      templateUrl: "html/modals/flights_terms_modal",
      controller: "FlightsTermsModalCtrl",
      size: "lg",
      windowClass: "terms-popup",
      resolve: {
        bookingKey: () => this.bookingKey,
        rules: () =>
          this.singlePriceService.bookingRules ? this.singlePriceService.bookingRules : this.bookingRules,
        customTitle: () => this.customTitle,
      },
    }).result.then(
      () => this.desktopModalOpen = false,
      () => this.desktopModalOpen = false,
    );
  }

  openMobileTermsModal(event?: any): void {
    this.preventDefaultAndStopPropagation(event);

    this.mobileModalOpen = true;
    if (!this.singlePriceService.bookingRules) {
      this.singlePriceService.loadTermsData();
    }

    this.bookingRules = this.singlePriceService.bookingRules || this.bookingRules;
  }

  closeMobileTermsModal(event?: any): void {
    this.preventDefaultAndStopPropagation(event);

    this.mobileModalOpen = false;
  }

  preventDefaultAndStopPropagation(event?: any): void {
    event.stopPropagation();
    event.preventDefault();
  }

  formatTerm(term: string): string {
    const translatedString: string = this.$translate.instant(term);
    if (translatedString === term) {
      return term.replace(/_/g, " ");
    } else {
      return translatedString;
    }
  }

  isValidHttpUrl(rule: string): boolean {
    return this.urlUtils.isValidHttpUrl(rule);
  }
}
