import { FlightsSegment, flightsSegmentId } from "booking_app/models";
import { FlightItem, FlightType } from "booking_app/types";
import { NumberUtils, TimeUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";
import { FlightsSearchResultsState } from "booking_app/components/flights/search-results";
import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { FlightsSegmentController } from "booking_app/components/flights/common/segment/flights-segment.controller";
import { PointsCashPaymentHandlingService } from "booking_app/services/points-cash-payment-handling.service";

declare var moment: any;

export class FlightsSearchResultsListItemController {
  static $inject = [
    "$rootScope",
    "$filter",
    "$translate",
    "PayWithPointsCashService",
    "AppSettings",
    "FlightsSearchResultsState",
    "PointsCashShareService",
    "PointsCashPaymentHandlingService",
  ];

  // bindings
  flightItem: FlightItem;
  showFlightInfoPrice: boolean;
  showSegments: boolean;
  onSelect: (obj: { flightItem: FlightItem }) => void;
  selectionStage: any;
  travellerCount: number;
  showHiddenHeading: boolean;

  termsOpen: boolean;

  public adminDataOn: boolean;

  constructor(
    private $rootScope: any,
    private $filter: any,
    private $translate: any,
    private payWithPointsCashService: PayWithPointsCashService,
    private appSettings: AppSettings,
    private flightsSearchResultsState: FlightsSearchResultsState,
    private pointsCashShareService: PointsCashShareService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) {
    this.adminDataOn = false;
  }

  $onInit() {
    this.showHiddenHeading = this.showHiddenHeading ?? true;
  }

  toggleAdminData(e): void {
    this.adminDataOn = !this.adminDataOn;
    e.stopPropagation();
  }

  selectFlightItem(): void {
    if (this.showFlightInfoPrice) {
      this.onSelect({ flightItem: this.flightItem });
    }
  }

  segmentId(segment: FlightsSegment): string {
    return flightsSegmentId(segment);
  }

  firstSegment(): FlightsSegment {
    return this.flightItem.segments[0];
  }

  lastSegment(): FlightsSegment {
    return this.flightItem.segments[this.flightItem.segments.length - 1];
  }

  formattedCashToPay(): string {
    return this.formatNumber(
      this.formatCurrencyValue(this.cashToPay()),
      this.$rootScope.selectedCurrency.decimalPlace,
    );
  }

  formattedPointsToPay(): string {
    return this.formatNumber(
      this.pointsToPay(), 0,
    );
  }

  cashToPayForEarn(): number {
    return this.flightItem.maxPriceInCash;
  }

  pointsToEarn(): number {
    return this.flightItem.totalEarnPoints;
  }

  cashToPay(): number {
    let cashDiscountTier: number[] = [...this.flightItem.cashNonFixedDiscountsByTier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      cashDiscountTier = cashDiscountTier.reverse();
    }
    const nonFixedDiscount = cashDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.cashToPay(
      this.flightItem.basePriceInCash,
      nonFixedDiscount,
      this.flightItem.cashFixedDiscount,
    );
  }

  pointsToPay(): number {
    let pointsDiscountTier: number[] = [...this.flightItem.pointsNonFixedDiscountsByTier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      pointsDiscountTier = pointsDiscountTier.reverse();
    }
    const nonFixedDiscount = pointsDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.pointsToPay(
      this.flightItem.basePriceInPoints,
      nonFixedDiscount,
      this.flightItem.pointsFixedDiscount,
    );
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(this.cashToPay(), this.pointsToPay());
  }

  airlineName(): string {
    return `${this.firstSegment().marketing_airline_name}`;
  }

  multipleAirlineName(): string {
    return Array.from(new Set(
      this.flightItem.segments.map(segment => segment.marketing_airline_name),
    )).join(", ");
  }

  operatingAirlineName(): string {
    return this.$translate.instant("flights.search_results.operating_airline", {
      airline: this.firstSegment().operating_airline_name,
    });
  }

  isSingleAirline(): boolean {
    return this.flightItem.segments.every((segment) => {
      return segment.marketing_airline_code === this.firstSegment().marketing_airline_code;
    });
  }

  airlineLogo(): string {
    return `/flights/${this.firstSegment().marketing_airline_code}.png`;
  }

  multipleAirlineLogo(): string {
    return this.appSettings.multipleAirlineLogoUrl;
  }

  formattedDepartureTime(): string {
    return TimeUtils.formatHourMinuteTime(
      this.firstSegment().departure_time,
      this.appSettings.customFlightsTimeLabel,
    );
  }

  formattedArrivalTime(): string {
    return TimeUtils.formatHourMinuteTime(
      this.lastSegment().arrival_time,
      this.appSettings.customFlightsTimeLabel,
    );
  }

  toggleDetails(e): void {
    e.stopPropagation();
    this.termsOpen = !this.termsOpen;
  }

  flightDuration(): string {
    const duration = moment.duration().add(this.flightItem.duration, "minutes");
    return TimeUtils.formatDuration(duration);
  }

  arrivalDayOffset(): number {
    return TimeUtils.getArrivalDayOffset(this.arrivalTime(), this.departureTime());
  }

  stopOverCount(): number {
    return this.flightItem.segments.length - 1;
  }

  stopOverAirports(): any {
    const airports = this.flightItem.segments.map(fi => fi.from_airport);
    airports.shift();
    return airports.join(", ");
  }

  totalStopOverAirportText(): any {
    return this.$translate.instant(
      this.stopOverCount() > 1 ? "flights.stop_in.other" : "flights.stop_in.one",
      {
        counter: this.stopOverCount(),
        destination: this.stopOverAirports(),
      },
    );
  }

  computeSegmentTime(index): string {
    const segments = this.flightItem.segments;
    const arrival = moment(segments[index - 1].arrival_time);
    const departure = moment(segments[index].departure_time);
    const total = moment.utc(departure).diff(arrival);

    return TimeUtils.formatDuration(
      moment.duration().add(total, "milliseconds"),
    );
  }

  hasPartnerAirlines(): boolean {
    return this.flightItem.segments.some(e => e.marketing_airline_code !== e.operating_airline_code);
  }

  flightIcon(): string {
    if (this.appSettings.customFlightIcon) {
      const appName = this.appSettings.tenant.toLowerCase();
      return `/${appName}/icons/icn-plane.png`;
    } else {
      return `/flight-icon.png`;
    }
  }

  selectionStateText(): string {
    if (this.selectionStage === "origin") {
      return this.$translate.instant("Depart");
    } else {
      return this.$translate.instant("flights.label.return");
    }
  }

  showSelectionStateText(): boolean {
    return this.appSettings.showFlightSelectionState;
  }

  isReturnTrip(): boolean {
    return this.flightsSearchResultsState.flightSearchParams.flightType === FlightType.RETURN;
  }

  hiddenHeadingText(): string {
    const airlineName: string = this.isSingleAirline() ? this.airlineName() : this.multipleAirlineName();
    const departureTime: string = TimeUtils.formatDateAndTime(this.departureTime(), "MMMM Do YYYY, h:mm A");
    const arrivalTime: string = TimeUtils.formatDateAndTime(this.arrivalTime(), "MMMM Do YYYY, h:mm A");
    return `${airlineName} depart from ${this.fromAirportName()} at ${departureTime}, arrive ${this.toAirportName()} at ${arrivalTime}`;
  }

  ariaLabel(segment: FlightsSegment, index: number): string {
    let segmentText: string = "";
    const segmentController = new FlightsSegmentController(
      this.$translate,
      this.$rootScope,
      this.appSettings,
    );

    segmentController.segment = segment;
    segmentController.index = index;
    segmentController.source = this.flightItem.source;
    segmentController.features = this.flightItem.features;

    if (index > 0) {
      segmentText += `${this.$translate.instant("flights.transit_time", {
        time: `${this.computeSegmentTime(index)}`,
        destination: segment.from_airport,
      })}, `;
    }

    segmentText += segmentController.ariaLabel();
    return segmentText;
  }

  private fromAirportName(): string {
    if (this.firstSegment().from_airport_name) {
      return `${this.firstSegment().from_airport_name} (${this.firstSegment().from_airport})`;
    } else {
      return this.firstSegment().from_airport;
    }
  }

  private toAirportName(): string {
    if (this.lastSegment().to_airport_name) {
      return `${this.lastSegment().to_airport_name} (${this.lastSegment().to_airport})`;
    } else {
      return this.lastSegment().to_airport;
    }
  }

  private departureTime(): any {
    return this.firstSegment().departure_time;
  }

  private arrivalTime(): any {
    return this.lastSegment().arrival_time;
  }

  private formatNumber(value: number, decimalPlace: number): string {
    return this.$filter("numberFmt")(
      value,
      this.$rootScope.selectedLocale,
      decimalPlace,
    );
  }

  private formatCurrencyValue(value): number {
    return NumberUtils.formatCurrencyValue(
      value,
      this.$rootScope.selectedCurrency,
    );
  }
}
