import { UrlUtils } from "booking_app/utils";
import { SortOption } from "booking_app/components/flights/search-results/sorter";
import { FlightsSearchResultsFilters } from "booking_app/components/flights/search-results/filters";
import { FlightItem } from "booking_app/types";
import { AppSettings } from "booking_app/values/app-settings";
import { ViewportSizes } from "booking_app/types/viewport-sizes";

export class FlightsSearchResultsModalController {
  static $inject = [
    "$timeout",
    "$rootScope",
    "$window",
    "KaligoConfig",
    "AppSettings",
    "UrlUtils",
  ];

  // bindings
  isFetchingPrices: boolean;
  filters: FlightsSearchResultsFilters;
  flightItems: FlightItem[];
  onUpdateFilters: (obj: { filters: FlightsSearchResultsFilters }) => void;
  onSelectedSortOptionChange: (obj: { option: SortOption }) => void;

  private isSortModalOpen: boolean;
  private isFilterModalOpen: boolean;

  constructor(
    private $timeout: any,
    private $rootScope: any,
    private $window: any,
    private kaligoConfig: any,
    private appSettings: AppSettings,
    private urlUtils: UrlUtils,
  ) {
    this.isSortModalOpen = false;
    this.isFilterModalOpen = false;
  }

  sortIconUrl(): string {
    const currentAppFolder = this.kaligoConfig.WHITELABEL_PARTNER;
    const format = this.appSettings.iconFormat;

    return this.urlUtils.imageUrl(`/${currentAppFolder}/icons/sort-icon.${format}`);
  }

  openSortModal() {
    this.isSortModalOpen = true;
  }

  closeSortModal(): void {
    this.isSortModalOpen = false;
  }

  filterIconUrl(): string {
    const currentAppFolder = this.kaligoConfig.WHITELABEL_PARTNER;
    const format = this.appSettings.iconFormat;

    return this.urlUtils.imageUrl(`/${currentAppFolder}/icons/filter-icon.${format}`);
  }

  openFilterModal(): void {
    this.isFilterModalOpen = true;
    this.$timeout(() => {
      this.$rootScope.$broadcast("rzSliderForceRender");
    }, 200);
  }

  closeFilterModal(): void {
    this.isFilterModalOpen = false;

    if (this.appSettings.mobileFirst ||
      this.$rootScope.globalState.browserWidth <= ViewportSizes.SM_MAX) {
        this.$window.scrollTo(0, 0);
    }
  }

  updateFilters(filters: FlightsSearchResultsFilters): void {
    this.onUpdateFilters({ filters });
  }

  updateSelectedSortOption(option: SortOption) {
    this.onSelectedSortOptionChange({ option });
    this.closeSortModal();
  }
}
