import { FlightsSearchFormService, FlightsSearchFormState } from "..";
import { AppSettings } from "booking_app/values/app-settings";

export class FlightsPassengersDropdownController {
  static $inject = ["$translate", "AppSettings"];

  adultCount: number;
  childCount: number;
  infantCount: number;
  onUpdateAdultCount: (obj: any) => void;
  onUpdateChildCount: (obj: any) => void;
  onUpdateInfantCount: (obj: any) => void;

  minAdultCount: number;
  maxAdultCount: number;
  minChildCount: number;
  maxChildCount: number;
  minInfantCount: number;
  maxInfantCount: number;

  private isDropdownOpen: boolean;

  private openFlag: boolean;

  constructor(
    private $translate: any,
    private appSettings: AppSettings,
  ) { }

  $onInit() {
    this.minAdultCount = FlightsSearchFormState.MIN_ADULT_COUNT;
    this.maxAdultCount = FlightsSearchFormState.MAX_ADULT_COUNT;
    this.minChildCount = FlightsSearchFormState.MIN_CHILD_COUNT;
    this.maxChildCount = FlightsSearchFormState.MAX_CHILD_COUNT;
    this.minInfantCount = FlightsSearchFormState.MIN_INFANT_COUNT;
    this.maxInfantCount = FlightsSearchFormState.MAX_INFANT_COUNT;
    this.openFlag = false;
  }

  toggleDropdown(): void {
    this.openFlag = true;
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  closeDropdown(): void {
    this.openFlag = false;
    this.isDropdownOpen = false;
  }

  updateAdultCount(value: number) {
    this.onUpdateAdultCount({ value });
  }

  updateChildCount(value: number) {
    this.onUpdateChildCount({ value });
  }

  updateInfantCount(value: number) {
    this.onUpdateInfantCount({ value });
  }

  travellerCaption(): string {
    const totalCount = this.adultCount + this.childCount + this.infantCount;
    if (totalCount === 1) {
      return this.$translate.instant("count.traveller_one");
    } else {
      return this.$translate.instant("count.traveller_other", { count: totalCount });
    }
  }

  caption(): string {
    let adultCaption = "";
    if (this.adultCount === 1) {
      adultCaption = this.$translate.instant("count.adult_one");
    } else if (this.adultCount > 1) {
      adultCaption = this.$translate.instant("count.adult_other", { count: this.adultCount });
    }

    let suffix = "";
    if (this.childCount > 0) {
      suffix = ", ";
    }

    let childCaption = "";
    if (this.childCount === 1) {
      childCaption = this.$translate.instant("count.children_one");
    } else if (this.childCount > 1) {
      childCaption = this.$translate.instant("count.children_other", { count: this.childCount });
    }

    return `${adultCaption}${suffix}${childCaption}`;
  }

  clickOutsideBehaviour(): void {
    this.closeDropdown();
  }
}
