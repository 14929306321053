import { PrimaryPassengerFormData } from "./primary-passenger-form-data";
import { PhoneCountryCodeSelectOption } from "booking_app/components/common/phone-country-code-select";
import { CommonPassengerDetailFormData } from "booking_app/components/flights/checkout/common-passenger-detail-form";
import { AngularUtils } from "booking_app/utils";
import { CheckoutOptions } from "booking_app/services/checkout-options";
import { PaginatedDropdownType } from "booking_app/types/paginated-dropdown-type";

export class FlightsCheckoutPrimaryPassengerDetailFormController {
  static $inject = ["CheckoutOptions", "$translate"];

  // bindings
  parentForm: any;
  formName: string;
  data: PrimaryPassengerFormData;
  onUpdate: (obj: { data: PrimaryPassengerFormData }) => void;
  submitted: boolean;
  passportRequired: boolean;
  travelSource: string;

  internalData: PrimaryPassengerFormData; // copy of data from bindings

  constructor(
    private checkoutOptions: CheckoutOptions,
    private $translate: any,
  ) {
    this.$translate = $translate;
  }

  $onInit(): void {
    this.checkoutOptions.populateCountryList();
  }

  $onChanges(changesObj): void {
    if (AngularUtils.hasBindingChanged(changesObj.data)) {
      this.internalData = angular.copy(this.data);
    }
  }

  title(): string {
    return this.$translate.instant(`flights.checkout.adult_caption`, {
      index: 1,
    });
  }

  onUpdateCommonData(data: CommonPassengerDetailFormData): void {
    this.internalData.commonData = data;
    this.onUpdateData();
  }

  onResetData(data: PrimaryPassengerFormData): void {
    this.internalData = data;
    this.onUpdateData();
  }

  onUpdateData(): void {
    this.onUpdate({ data: this.internalData });
  }

  phoneCountryCodeValueFormatter(phoneCountryCodeValues: PaginatedDropdownType): PhoneCountryCodeSelectOption {
    const phoneCountryCodeSelectValue: PhoneCountryCodeSelectOption = {
      id: "",
      text: "",
    };

    if (phoneCountryCodeValues.value) {
      phoneCountryCodeSelectValue.id = phoneCountryCodeValues.value;
      phoneCountryCodeSelectValue.text = phoneCountryCodeValues.text;
    }

    return phoneCountryCodeSelectValue;
  }

  onUpdatePhoneCountryCode(value: PhoneCountryCodeSelectOption): void {
    if (!this.internalData.phoneDetails) {
      this.internalData.phoneDetails = { countryCode: null, number: null };
    }

    this.internalData.phoneDetails.countryCode = angular.copy(value);
    this.onUpdateData();
  }

  emailSubLabel(): string {
    return `(${this.$translate.instant("So we can send you a booking confirmation.")})`;
  }
}
