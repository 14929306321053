import { SortOption } from ".";
import { FlightsSearchResultsSorterService } from "./sorter.service";
import { FlightsSearchResultsState } from "booking_app/components/flights/search-results";

export class FlightsSearchResultsSorterController {
  static $inject = [
    "$translate",
    "FlightsSearchResultsSorterService",
  ];

  public sortOptions: SortOption[];
  // bindings
  private onSelectedSortOptionChange: (obj: { option: SortOption }) => void;

  constructor(
    private $translate: any,
    private flightsSearchResultsSorterService: FlightsSearchResultsSorterService,
  ) {
    this.flightsSearchResultsSorterService.initializePermittedSortOptions();
    this.sortOptions = this.flightsSearchResultsSorterService.permittedSortOptions;
  }

  $onChanges(changes: any): void {
    this.flightsSearchResultsSorterService.updateSortingOptionsForPayWithPoints();
  }

  displaySortOptions(option: SortOption): string {
    return option.text;
  }

  updateSelectedSortOption(value: SortOption): void {
    this.onSelectedSortOptionChange({ option: value });
  }

  elementClass(text: string): string {
    return text.split(" ")[0];
  }
}
