import { FlightsSummaryHeaderController } from ".";
import { Bindings } from "booking_app/types";

export class FlightsSummaryHeaderComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      flightItem: Bindings.ONE_WAY,
    };
    this.templateUrl = '/html/flights/summary_headers';
    this.controller = FlightsSummaryHeaderController;
  }
}

angular.module("BookingApp").component("flightsSummaryHeaderComponent", new FlightsSummaryHeaderComponent());
