import { FlightsSearchResultsModalController } from "./search-results-modal.controller";
import { Bindings } from "booking_app/types";

export class FlightsSearchResultsModalComponent {
  bindings: any;
  templateUrl: string;
  controller: any;

  constructor() {
    this.bindings = {
      isFetchingPrices: Bindings.ONE_WAY,
      filters: Bindings.ONE_WAY,
      flightItems: Bindings.ONE_WAY,
      onUpdateFilters: Bindings.EXPRESSION,
      onSelectedSortOptionChange: Bindings.EXPRESSION,
    };
    this.templateUrl = '/html/flights/search_results_modal';
    this.controller = FlightsSearchResultsModalController;
  }
}

angular.module("BookingApp").component("flightsSearchResultsModalComponent", new FlightsSearchResultsModalComponent());
