import { DateSelectOption } from "booking_app/components/flights/checkout/date-select/date-select-option";

declare var moment: any;

const NUMBER_OF_DAYS = 31;
const NUMBER_OF_MONTHS = 12;
const NUMBER_OF_PAST_YEARS = 100;
const NUMBER_OF_FUTURE_YEARS = 20;

export class DateSelectService {
  dayOptions: DateSelectOption[];
  monthOptions: DateSelectOption[];
  pastYearOptions: DateSelectOption[];
  futureYearOptions: DateSelectOption[];

  constructor() {
    this.dayOptions = this.initDayOptions();
    this.monthOptions = this.initMonthOptions();
    this.pastYearOptions = this.initPastYearOptions();
    this.futureYearOptions = this.initFutureYearOptions();
  }

  initDayOptions(): DateSelectOption[] {
    return Array(NUMBER_OF_DAYS).fill('').map((item, index) => {
      const day = index + 1;
      return {
        value: day,
        text: day >= 10 ? `${day}` : `0${day}`,
      };
    })
  }

  initMonthOptions(): DateSelectOption[] {
    return Array(NUMBER_OF_MONTHS).fill('').map((item, index) => {
      const month = index + 1;
      return {
        value: month,
        text: month >= 10 ? `${month}` : `0${month}`,
      };
    })
  }

  initPastYearOptions(): DateSelectOption[] {
    const currentYear = moment().year();
    return Array(NUMBER_OF_PAST_YEARS).fill('').map((item, index) => {
      const year = currentYear - index;
      return {
        value: year,
        text: `${year}`,
      };
    });
  }

  initFutureYearOptions(): DateSelectOption[] {
    const currentYear = moment().year();
    return Array(NUMBER_OF_FUTURE_YEARS).fill('').map((item, index) => {
      const year = currentYear + index;
      return {
        value: year,
        text: `${year}`,
      };
    });
  }
}

angular.module("BookingApp").service("DateSelectService", DateSelectService);
