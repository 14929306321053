import { Bindings } from "booking_app/types";
import { FlightsBookingSegmentController } from "./flights-booking-segment.controller";

export class FlightBookingSegmentComponent {
  bindings: any;
  templateUrl: string;
  controller: any;

  constructor() {
    this.bindings = {
      segments: Bindings.ONE_WAY,
      segment: Bindings.ONE_WAY,
      index: Bindings.ONE_WAY,
    };
    this.templateUrl = "/html/flights/booking_segment";
    this.controller = FlightsBookingSegmentController;
  }
}

angular.module("BookingApp").component("flightBookingSegmentComponent", new FlightBookingSegmentComponent());
