
import { CurrentPage, FlightItem, FlightType } from "booking_app/types";
import { FlightsPricesSearchResult, flightsSegmentId } from "booking_app/models";
import { FlightsSearchResultsService, FlightsSearchResultsState } from ".";
import { SortOption } from "./sorter";
import { SelectionStage } from "./selection-stage";
import { FlightUrlGenerator } from "booking_app/services/flights/url-generator";
import { FlightSingleQueryParams } from "booking_app/models/flights-single-query-params";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { AppSettings } from "booking_app/values/app-settings";
import { TimeUtils } from "booking_app/utils";

const SCROLL_BUFFER = 100;

export class FlightsSearchResultsController {
  static $inject = [
    "FlightsSearchResultsService",
    "FlightsSearchResultsState",
    "FlightUrlGenerator",
    "GlobalStateService",
    "PointsCashShareService",
    "AppSettings",
    "$location",
    "$rootScope",
    "$translate",
    "$scope",
  ];

  isFetchingPrices: boolean;
  hasPartialResults: boolean;
  private windowElement: any;
  private documentElem: any;

  constructor(
    private service: FlightsSearchResultsService,
    private state: FlightsSearchResultsState,
    private flightUrlGenerator: FlightUrlGenerator,
    private globalStateService: GlobalStateService,
    private pointsCashShareService: PointsCashShareService,
    private appSettings: AppSettings,
    private $location: any,
    private $rootScope: any,
    private $translate: any,
    private $scope: any,
  ) {
    this.$scope.globalStateService = this.globalStateService;
    this.$scope.pointsCashShareService = this.pointsCashShareService;
    this.windowElement = angular.element(window);
    this.documentElem = angular.element(document);
    this.$scope.sliderLeftLabel = this.appSettings.pointsCashSliderSettings.sliderLeftLabel;
    this.$scope.sliderRightLabel = this.appSettings.pointsCashSliderSettings.sliderRightLabel;
    this.$scope.sliderLabel = this.appSettings.pointsCashSliderSettings.sliderLabel;
    this.$scope.alwaysShowPointsSlider = this.appSettings.pointsCashSliderSettings.alwaysShowPointsSlider;
  }

  $onInit(): void {
    this.globalStateService.currentPage = CurrentPage.SEARCH_RESULT;
    this.$scope.globalState = this.$rootScope.globalState;
    this.state.resetValues();
    this.state.resetSearchFormStateValues();
    this.isFetchingPrices = true;
    this.fetchFlightPrices();

    this.setupLazyLoadingEventListener();
  }

  travellerCount(): number {
    const flightSearchParams = this.state.flightSearchParams;
    return flightSearchParams.adultCount + flightSearchParams.childCount + flightSearchParams.infantCount;
  }

  $onDestroy(): void {
    this.state.resetValues();
    this.state.resetSearchFormStateValues();
  }

  onUpdateFilters(filters): void {
    this.service.updateResultsLimit(FlightsSearchResultsState.DEFAULT_RESULT_COUNT);
    this.service.updateFilters(filters);
    this.service.updateFlightItems();
  }

  fetchingPricesMessage(): string {
    return this.$translate.instant("flights.label.search_for_the_best_from", {
      departure: this.departureCity(),
    });
  }

  selectFlightItem(flightItem: FlightItem): void {
    if (this.state.selectionStage === SelectionStage.ORIGIN) {
      if (this.appSettings.departuresOnly) {
        this.isFetchingPrices = true;
        this.state.flightSearchParams.flightFareId = flightItem.flightFareId;
        this.fetchFlightPrices();
      }
      this.onSelectOriginFlightItem(flightItem);
    } else if (this.state.selectionStage === SelectionStage.RETURN) {
      this.onSelectReturnFlightItem(flightItem);
    }
    this.service.updateFlightItems();
  }

  showBackbutton(): boolean {
    return this.state.selectionStage === SelectionStage.RETURN;
  }

  dateText(): string {
    return moment(this.state.flightSearchParams.departureDate).format("ll");
  }

  resetOriginFlightItem(): void {
    this.state.selectedOriginFlightItem = null;
    this.state.resetFilters();
    this.state.selectionStage = SelectionStage.ORIGIN;

    if (this.appSettings.departuresOnly) {
      this.isFetchingPrices = true;
      this.state.flightSearchParams.flightFareId = null;
      this.fetchFlightPrices();
    }
    this.service.updateFlightItems();
  }

  fetchFlightPrices(): void {
    this.service.fetchFlightsPrices()
      .then((res: FlightsPricesSearchResult) => {
        this.state.itineraries = res.itineraries;
        this.updateFlightSearchParamsAirportNames(res);
        this.service.updateFlightItems();
        this.isFetchingPrices = false;
        this.hasPartialResults = false;
      }, (_) => {
        this.service.updateFlightItems();
        this.isFetchingPrices = false;
      }, (notifyResult: FlightsPricesSearchResult) => {
        this.updateFlightSearchParamsAirportNames(notifyResult);
        if (notifyResult.itineraries.length > 0) {
          this.state.itineraries = notifyResult.itineraries;
          this.service.updateFlightItems();
          this.hasPartialResults = true;
        }
      });
  }

  // Called on changing sort option, passed to sorter component
  onUpdateSelectedSortOption(option: SortOption): void {
    this.service.updateResultsLimit(FlightsSearchResultsState.DEFAULT_RESULT_COUNT);
    this.service.updateSelectedSortOption(option);
    this.service.updateFlightItems();
  }

  updateFlightSearchParamsAirportNames(res: FlightsPricesSearchResult): void {
    this.state.flightSearchParams.fromAirportName = res.search.from_airport_name;
    this.state.flightSearchParams.toAirportName = res.search.to_airport_name;
  }

  // === Search summary ===

  summaryDestination() {
    if (this.state.selectionStage === SelectionStage.ORIGIN) {
      return this.state.flightSearchParams.toAirportName;
    } else if (this.state.selectionStage === SelectionStage.RETURN) {
      return this.state.flightSearchParams.fromAirportName;
    }
  }

  summaryDate() {
    if (this.state.selectionStage === SelectionStage.ORIGIN) {
      return this.state.flightSearchParams.departureDate;
    } else if (this.state.selectionStage === SelectionStage.RETURN) {
      return this.state.flightSearchParams.returnDate;
    }
  }

  showSpinnerLoader(): boolean {
    return this.isFetchingPrices && !this.hasPartialResults;
  }

  showNoMatchMessage(): boolean {
    return !this.showSpinnerLoader() && this.state.displayedFlightItems.length <= 0;
  }

  showResults(): boolean {
    return !this.showSpinnerLoader() && this.state.displayedFlightItems.length > 0;
  }

  resetFilters(): void {
    this.state.resetFilters();
    this.service.updateFlightItems();
  }

  hiddenHeadingText(): string {
    const flightType: FlightType = this.state.flightSearchParams.flightType;
    const departureDate: string = TimeUtils.formatDateAndTime(
      this.state.flightSearchParams.departureDate,
      "MMMM Do YYYY",
    );

    let headingText: string = `Flights from ${this.fromAirportName()} to ${this.toAirportName()} on ${departureDate}`;
    if (flightType === FlightType.RETURN) {
      headingText = "Return " + headingText;
    } else if (flightType === FlightType.ONE_WAY) {
      headingText = "Departure " + headingText;
    }
    return headingText;
  }

  private fromAirportName(): string {
    if (this.state.flightSearchParams.fromAirportName) {
      return `${this.state.flightSearchParams.fromAirportName} (${this.state.flightSearchParams.fromAirport})`;
    } else {
      return this.state.flightSearchParams.fromAirport;
    }
  }

  private toAirportName(): string {
    if (this.state.flightSearchParams.toAirportName) {
      return `${this.state.flightSearchParams.toAirportName} (${this.state.flightSearchParams.toAirport})`;
    } else {
      return this.state.flightSearchParams.toAirport;
    }
  }

  private setupLazyLoadingEventListener(): void {
    this.documentElem.on("scroll", () => {
      const currentScrollLocation = this.windowElement.scrollTop() + this.windowElement.height();
      const updateScrollTreshold = this.documentElem.height() - SCROLL_BUFFER;
      if (currentScrollLocation >= updateScrollTreshold) {
        this.pushToVisible();
        this.$scope.$apply();
      }
    });
  }

  private departureCity(): string {
    if (this.state.flightSearchParams.fromAirportName) {
      return `${this.state.flightSearchParams.fromAirportName} (${this.state.flightSearchParams.fromAirport})`;
    } else {
      return this.state.flightSearchParams.fromAirport;
    }
  }

  private onSelectOriginFlightItem(flightItem: FlightItem): void {
    this.state.selectedOriginFlightItem = flightItem;
    this.state.resetFilters();
    if (this.state.flightSearchParams.flightType === FlightType.ONE_WAY) {
      this.redirectToSummaryPage();
    } else if (this.state.flightSearchParams.flightType === FlightType.RETURN) {
      this.state.selectionStage = SelectionStage.RETURN;
    }
  }

  private onSelectReturnFlightItem(flightItem: FlightItem): void {
    // pass data from this itinerary to summary page
    this.state.selectedReturnFlightItem = flightItem;
    this.redirectToSummaryPage();
  }

  private selectedItinerarySegmentId(): string {
    if (this.state.flightSearchParams.flightType === FlightType.ONE_WAY) {
      return this.state.selectedOriginFlightItem.id;
    } else if (this.state.flightSearchParams.flightType === FlightType.RETURN) {
      return `${this.state.selectedOriginFlightItem.id}${this.state.selectedReturnFlightItem.id}`;
    }
  }

  private selectedItineraryBookingKey(): string {
    const segmentsId = this.selectedItinerarySegmentId();

    const selectedItinerary = this.state.itineraries.find((itinerary) => {
      const itinerarySegmentsId = itinerary.segments.reduce((identifier, segment) => {
        return `${identifier}${flightsSegmentId(segment)}`;
      }, "");
      return itinerarySegmentsId === segmentsId;
    });

    return selectedItinerary.booking_key;
  }

  private redirectToSummaryPage(): void {

    const urlParams: FlightSingleQueryParams = {
      booking_key: this.selectedItineraryBookingKey(),
      partnerId: this.$rootScope.pointsPartner.id,
    };

    const url = this.flightUrlGenerator.generateSingleFlightUrl(urlParams, "summary");
    this.$location.url(url);
  }

  private pushToVisible() {
    const totalFlightItems = this.state.totalDisplayedFlightItems;
    if (totalFlightItems?.length >= this.state.resultsLimit) {
      let lastIndex = FlightsSearchResultsState.DEFAULT_RESULT_COUNT;
      this.service.updateResultsLimit(this.state.resultsLimit + 3);

      totalFlightItems.slice(lastIndex, this.state.resultsLimit).forEach((value) => {
        this.state.displayedFlightItems[lastIndex] = value;
        lastIndex += 1;
      });
    }
  }
}
