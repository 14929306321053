import { PassengersFieldMobileController } from "./passengers-field-mobile.controller";
import { Bindings } from "booking_app/types";

export class PassengersFieldMobileComponent {
  bindings: any = {
    adultCount: Bindings.ONE_WAY,
    childCount: Bindings.ONE_WAY,
    infantCount: Bindings.ONE_WAY,
    onUpdateAdultCount: Bindings.EXPRESSION,
    onUpdateChildCount: Bindings.EXPRESSION,
    onUpdateInfantCount: Bindings.EXPRESSION,
  };
  controller: any = PassengersFieldMobileController;
  transclude: any = {
    iconContent: "?iconContent",
  };
  template: string = `
    <div class="passengers-field-mobile" click-outside="$ctrl.closeModal()">

      <div class="passengers-field-mobile-input selected-value"
        ng-click="$ctrl.beginSelection()"
        tabindex="0"
        ng-keypress="$event.key == 'Enter' && $ctrl.beginSelection()">
        <div class="selected-text">
          {{ $ctrl.inputLabel() }}
        </div>

        <div class="dropdown-arrow"></div>
      </div>

      <form-popup-mobile ng-if="$ctrl.modalOpen">
        <title-content>
          <button
            type="button"
            class="fa fa-close close-tick"
            ng-click="$ctrl.closeModal()"
            aria-label="Close">
          </button>
          <div class="summary-text">
            {{ $ctrl.summaryText() }}
          </div>
        </title-content>

        <body-content modal-accessibility-lock="form-popup-mobile">
          <div class="counters-container">
            <div class="form-field">
              <div class="passenger-label">
                <div class="group" translate="txt.adults"></div>
              </div>

              <input-counter min-value="$ctrl.minAdultCount"
                max-value="$ctrl.maxAdultCount"
                value="$ctrl.adultCount"
                on-update="$ctrl.updateAdultCount(value)">
              </input-counter>
            </div>
            <div class="form-field">
              <div class="passenger-label">
                <div class="group" translate="Children"></div>
                <div class="age" translate="txt.age_2_to_11"></div>
              </div>
              <input-counter min-value="$ctrl.minChildCount"
                max-value= "$ctrl.maxChildCount"
                value="$ctrl.childCount"
                on-update="$ctrl.updateChildCount(value)">
              </input-counter>
            </div>
            <div class="form-field">
              <div class="passenger-label">
                <div class="group" translate="txt.infant"></div>
                <div class="age" translate="txt.ages_below_2"></div>
              </div>
              <input-counter min-value="$ctrl.minInfantCount"
                max-value="$ctrl.maxInfantCount"
                value="$ctrl.infantCount"
                on-update="$ctrl.updateInfantCount(value)">
              </input-counter>
            </div>
          </div>
          <button
            type="button"
            class="btn-action"
            ng-click="$ctrl.closeModal()">
            <span translate="button.done"></span>
          </button>
        </body-content>
      </form-popup-mobile>

    </div>
  `;
}

angular.module("BookingApp").component("passengersFieldMobile", new PassengersFieldMobileComponent());
