import { FlightCabin } from "booking_app/types";
import { FlightFeatures, FlightsSegment } from "booking_app/models";
import { TimeUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

export class FlightsSegmentController {
  static $inject = ["$translate", "$rootScope", "AppSettings"];

  // bindings
  segment: FlightsSegment;
  index: number;
  source: string;
  features: FlightFeatures;

  showTravelfusionBaggageTooltip = false;

  constructor(
    private $translate: any,
    private $rootScope: any,
    private appSettings: AppSettings,
  ) {
    this.$translate = $translate;
  }

  airlineLogo(): string {
    return `/flights/${this.segment.marketing_airline_code}.png`;
  }

  arrivalDayOffset() {
    return TimeUtils.getArrivalDayOffset(this.arrivalTime(), this.departureTime());
  }

  formattedDepartureTime(): string {
    return TimeUtils.formatHourMinuteTime(this.departureTime(), this.appSettings.customFlightsTimeLabel);
  }

  formattedArrivalTime(): string {
    return TimeUtils.formatHourMinuteTime(this.arrivalTime(), this.appSettings.customFlightsTimeLabel);
  }

  arrivalDetails() {
    return moment(this.arrivalTime()).format("D MMMM YYYY");
  }

  flightDuration() {
    const durationInMinutes = this.segment.duration;
    const duration = moment.duration().add(durationInMinutes, "minutes");
    return (duration.hours() > 0 ? `${duration.hours()}h ` : "")
      + (duration.minutes() > 0 ? `${duration.minutes()}m ` : "");
  }

  flightCabin(): string {
    return this.$translate.instant(FlightCabin.toStringTranslation(this.segment.cabin));
  }

  airlineName(): string {
    const {
      marketing_airline_name,
      marketing_airline_code,
      marketing_airline_flight_no,
      operating_airline_code,
      operating_airline_name,
    } = this.segment;

    let airline: string;
    airline = `${marketing_airline_name} | ${marketing_airline_code}${marketing_airline_flight_no}`;

    if (marketing_airline_code !== operating_airline_code) {
      airline += ` | ${this.$translate.instant("flights.search_results.operating_airline", {
        airline: operating_airline_name,
      })}`;
    }

    return airline;
  }

  shortAirlineName(): string {
    return this.segment.marketing_airline_name;
  }

  displayArrivalDate(): boolean {
    return this.appSettings.flightSegmentArrivalDisplay && (this.arrivalDayOffset() > 0);
  }

  segmentArrivalDate(): string {
    return moment(this.arrivalTime()).format("DD MMM");
  }

  flightLineIcon(): string {
    return this.appSettings.customFlightLineIconUrl;
  }

  segmentBaggageAllowance(): string {
    return this.$translate.instant(this.segment.baggage_allowance);
  }

  isTravelfusionItinerary(): boolean {
    return this.source === "travelfusion" && Object.keys(this.getRelevantFeatures()).length > 0;
  }

  getRelevantFeatures(): { [mappedBaggageType: string]: Set<string> } {
    const result = {};

    const features = this.features;
    if (features) {
      const baggageTypeMap = {
        HoldBag: "Checked bag",
        SmallCabinBag: "Small cabin bag",
        LargeCabinBag: "Large cabin bag",
      };

      for (const [baggageType, baggageTypeFeatures] of Object.entries(features)) {
        const relevantFeatures = baggageTypeFeatures.filter(feature => feature.Value === "0.00");

        for (const { Quantity, MaxQuantity, Weight, Dimensions } of relevantFeatures) {
          const mappedBaggageType = baggageTypeMap[baggageType];

          // If the key doesn't exist, initialize it
          if (!result[mappedBaggageType]) {
            result[mappedBaggageType] = new Set();
          }
          // If the value is not present yet, push it in
          const value = { Quantity, MaxQuantity, Weight, Dimensions };
          result[mappedBaggageType].add(JSON.stringify(value));
        }
      }
    }

    return result;
  }

  travelfusionBaggageTooltip(): string {
    let tooltipMessage = "";

    const features = this.getRelevantFeatures();
    for (const [mappedBaggageType, baggageTypeDetailsSet] of Object.entries(features)) {
      if (baggageTypeDetailsSet.size === 0) {
        continue;
      }

      const baggageTypeDetailsArray = [...baggageTypeDetailsSet].map(value => JSON.parse(value));

      const baggageTypeDetailsArrayFiltered = baggageTypeDetailsArray.filter(item =>
        item.Quantity || item.MaxQuantity || item.Weight || item.Dimensions,
      );

      if (baggageTypeDetailsArrayFiltered.length > 0) {
        const baggageTypeDetailsString = baggageTypeDetailsArrayFiltered.map(item => {
          const details = [];

          if (item.Quantity != null && item.MaxQuantity != null) {
            details.push(`${item.Quantity} - ${item.MaxQuantity} piece(s)`);
          } else if (item.Quantity != null && item.MaxQuantity == null) {
            details.push(`${item.Quantity} piece(s)`);
          } else if (item.Quantity == null && item.MaxQuantity != null) {
            details.push(`maximum ${item.MaxQuantity} piece(s)`);
          }

          if (item.Weight) {
            details.push(`maximum ${item.Weight}`);
          }
          if (item.Dimensions) {
            details.push(`within the Dimensions of ${item.Dimensions}`);
          }

          return details.join(", ");
        }).join("; ");

        tooltipMessage += `${mappedBaggageType}: ${baggageTypeDetailsString}<br>`;
      }
    }

    return tooltipMessage;
  }

  fareBrandName(): string {
    return this.segment.fare_brand_name ? this.segment.fare_brand_name.toLowerCase() : "";
  }

  /**
   * Old format (BNZ only): <cabin> (<fare_reference>)
   * New format: cabin (<fare_brand_name>)
   */
  displayFareType(): string {
    const fareType = this.fareBrandName();
    const cabin = this.flightCabin();

    if (cabin && fareType) {
      return `${cabin} (${fareType})`;
    }
    return cabin ?? fareType;
  }

  ariaLabel(): string {
    let segmentText: string = "";
    segmentText += `${this.$translate.instant("flights.label.departing_flight_from_to_on", {
      departure: this.segment.from_airport,
      arrival: this.segment.to_airport,
      date: this.formattedDepartureTime(),
    })}, `;
    if (this.displayArrivalDate()) {
      segmentText += `${this.$translate.instant("Arrives on")} ${this.segmentArrivalDate()}, `;
    }
    segmentText += `${this.$translate.instant("Flight Duration")}: ${this.flightDuration()}, `;
    segmentText += `${this.$translate.instant("flights.search_results.operating_airline", {
      airline: this.airlineName(),
    })}, `;
    segmentText += `${this.$translate.instant("Cabin class")}: ${this.$translate.instant(this.flightCabin())}, `;
    segmentText += `Aircraft: ${this.segment.aircraft_type_name}, `;
    segmentText += `${this.$translate.instant("flights.baggage_allowance", {
      baggage: this.segmentBaggageAllowance(),
    })}`;
    return segmentText;
  }

  private departureTime(): string {
    return this.segment.departure_time;
  }

  private arrivalTime(): string {
    return this.segment.arrival_time;
  }
}
