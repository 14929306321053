import { FlightsPaymentSummaryController } from "./flights-payment-summary.controller";
import { Bindings } from "booking_app/types";

export class FlightsPaymentSummaryComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      itinerary: Bindings.ONE_WAY,
    };
    this.templateUrl = "/html/flights/payment_summary";
    this.controller = FlightsPaymentSummaryController;
  }
}

angular.module("BookingApp").component("flightsPaymentSummaryComponent", new FlightsPaymentSummaryComponent());
