import { FlightItem } from "booking_app/types";
import { FlightItemFilter } from "../flight-item-filter";

export class RangeFilter extends FlightItemFilter {

  public minValue: number;
  public maxValue: number;
  public minAllowedValue: number;
  public maxAllowedValue: number;
  public options: any;
  private filterAttribute: string;

  constructor(minAllowedValue, maxAllowedValue, filterAttribute) {
    super();
    this.filterAttribute = filterAttribute;
    this.updateAllowedValues(minAllowedValue, maxAllowedValue)
    this.reset();
  }

  perform(flightItem: FlightItem): boolean {
    return this.performSingle(flightItem);
  }

  performSingle(flightItem: FlightItem): boolean {
    return this.atLeastMinValue(flightItem) && this.atMostMaxValue(flightItem);
  }

  updateAllowedValues(minAllowedValue, maxAllowedValue) {
    this.minAllowedValue = minAllowedValue;
    this.maxAllowedValue = maxAllowedValue;
    this.reset();
  }

  reset(): void {
    this.minValue = this.minAllowedValue;
    this.maxValue = this.maxAllowedValue;
  }

  private atLeastMinValue(flightItem): boolean {
    return flightItem[this.filterAttribute] >= this.minValue;
  }

  private atMostMaxValue(flightItem): boolean {
    return flightItem[this.filterAttribute] <= this.maxValue;
  }

}
