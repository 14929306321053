import { FlightsSearchResultsState } from "booking_app/components/flights/search-results";

export class FlightsEditSearchController {
  static $inject = [
    "$modalInstance",
    "FlightsSearchResultsState",
    "$scope",
    "$location",
  ];

  private $modalInstance: any;
  private flightsSearchResultsState: FlightsSearchResultsState;
  private $scope: any;

  constructor(
    $modalInstance: any,
    flightsSearchResultsState: FlightsSearchResultsState,
    $scope: any,
  ) {
    this.$modalInstance = $modalInstance;
    this.flightsSearchResultsState = flightsSearchResultsState;
    this.$scope = $scope;
  }

  onCloseButtonClicked(): void {
    this.closeModal();
    this.resetSearchFormState();
  }

  onSearchButtonClicked(): void {
    this.closeModal();
  }

  private closeModal(): void {
    this.$modalInstance.dismiss("cancel");
  }

  private resetSearchFormState(): void {
    this.flightsSearchResultsState.resetSearchFormStateValues();
  }

}

angular.module("BookingApp").controller("FlightsEditSearchController", FlightsEditSearchController);
