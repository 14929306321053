export class InputCounterController {
  static $inject = ["$rootScope"];

  value: number;
  minValue: number;
  maxValue: number;
  onUpdate: (obj: { value: number }) => void;

  constructor(
    public $rootScope: any,
  ) { }

  decrementValue(): void {
    if (this.isDecrementEnabled()) {
      this.onUpdate({ value: this.value - 1 });
    }
  }

  incrementValue(): void {
    if (this.isIncrementEnabled()) {
      this.onUpdate({ value: this.value + 1 });
    }
  }

  isDecrementEnabled(): boolean {
    return this.value > this.minValue;
  }

  isIncrementEnabled(): boolean {
    return this.value < this.maxValue;
  }
}
