import { AppSettings } from "booking_app/values/app-settings";
import { FlightsSearchFormState } from "booking_app/components/flights/search-form/flights-search-form.state";
import { ViewportSizes } from "booking_app/types/viewport-sizes";

export class PassengersFieldMobileController {

  static $inject = [
    "$translate",
    "$element",
    "AppSettings",
    "$rootScope",
  ];

  // bindings
  adultCount: number;
  childCount: number;
  infantCount: number;
  onUpdateAdultCount: (obj: { count: number }) => void;
  onUpdateChildCount: (obj: { count: number }) => void;
  onUpdateInfantCount: (obj: { count: number }) => void;

  // local state
  modalOpen: boolean = false;
  watchWidthEvent: any;

  minAdultCount: number;
  maxAdultCount: number;
  minChildCount: number;
  maxChildCount: number;
  minInfantCount: number;
  maxInfantCount: number;

  constructor(
    private $translate: any,
    private $element: any,
    private appSettings: AppSettings,
    private $rootScope: any,
  ) { }

  $onInit(): void {
    this.minAdultCount = FlightsSearchFormState.MIN_ADULT_COUNT;
    this.maxAdultCount = FlightsSearchFormState.MAX_ADULT_COUNT;
    this.minChildCount = FlightsSearchFormState.MIN_CHILD_COUNT;
    this.maxChildCount = FlightsSearchFormState.MAX_CHILD_COUNT;
    this.minInfantCount = FlightsSearchFormState.MIN_INFANT_COUNT;
    this.maxInfantCount = FlightsSearchFormState.MAX_INFANT_COUNT;
    this.setupModalWidthListener();
  }

  summaryText(): string {
    let res = "";

    res += `${this.adultCaption()}`;

    if (this.childCount > 0) {
      res += `, ${this.childCaption()}`;
    }
    if (this.infantCount > 0) {
      res += `, ${this.infantCaption()}`;
    }

    return res;
  }

  beginSelection(): void {
    this.modalOpen = true;
    this.$element.find(".field-input").blur();
    this.setupModalWidthListener();
  }

  closeModal(): void {
    this.modalOpen = false;
    this.watchWidthEvent();
  }

  updateAdultCount(count: number): void {
    this.onUpdateAdultCount({ count });
  }

  updateChildCount(count: number): void {
    this.onUpdateChildCount({ count });
  }

  updateInfantCount(count: number): void {
    this.onUpdateInfantCount({ count });
  }

  inputLabel(): string {
    if (this.totalTravellerCount() === 1) {
      return this.$translate.instant("count.traveller_one");
    } else {
      return this.$translate.instant("count.traveller_other", { count: this.totalTravellerCount() });
    }
  }

  private adultCaption(): string {
    if (Number.isInteger(this.childCount)) {
      return this.showLabelAsAdult();
    } else {
      return this.showLabelAsGuest();
    }
  }

  private showLabelAsAdult(): string {
    if (this.adultCount === 1) {
      return this.$translate.instant("count.adult_one");
    } else {
      return this.$translate.instant("count.adult_other", { count: this.adultCount });
    }
  }

  private showLabelAsGuest(): string {
    if (this.adultCount === 1) {
      return this.$translate.instant("Single Guest Count");
    } else {
      return this.$translate.instant("Multiple Guest Count", { count: this.adultCount });
    }
  }

  private childCaption(): string {
    if (this.childCount === 1) {
      return this.$translate.instant("count.children_one");
    } else if (this.childCount > 1) {
      return this.$translate.instant("count.children_other", { count: this.childCount });
    }
  }

  private infantCaption(): string {
    if (this.infantCount === 1) {
      return this.$translate.instant("count.infant_one");
    } else if (this.infantCount > 1) {
      return this.$translate.instant("count.infant_other", { count: this.infantCount });
    }
  }

  private totalTravellerCount(): number {
    return this.adultCount + this.childCount + this.infantCount;
  }

  private setupModalWidthListener(): void {
    this.watchWidthEvent = this.$rootScope.$watch("globalState.browserWidth", () => {
      if (this.$rootScope.globalState.browserWidth >= ViewportSizes.XS_MAX + 1) {
        this.closeModal();
      }
    });
  }

}
