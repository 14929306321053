import { Bindings } from "booking_app/types";
import { FlightsCheckoutCommonPassengerDetailFormController } from "./common-passenger-detail-form.controller";

export class FlightsCheckoutCommonPassengerDetailFormComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      parentForm: Bindings.ONE_WAY,
      formName: Bindings.ONE_WAY,
      data: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
      submitted: Bindings.ONE_WAY,
      passportRequired: Bindings.ONE_WAY,
      formIdIndex: Bindings.ONE_WAY,
      secondaryPassenger: Bindings.ONE_WAY,
      travelSource: Bindings.ONE_WAY,
    };
    this.controller = FlightsCheckoutCommonPassengerDetailFormController;
    this.templateUrl = "/html/flights/checkout_common_passenger_detail_form";
  }
}

angular.module("BookingApp").component("flightsCheckoutCommonPassengerDetailFormComponent", new FlightsCheckoutCommonPassengerDetailFormComponent());
