import { Bindings } from "booking_app/types";
import { FlightsCheckoutSecondaryPassengerDetailFormController } from "./secondary-passenger-detail-form.controller";

export class FlightsCheckoutSecondaryPassengerDetailFormComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      parentForm: Bindings.ONE_WAY,
      formName: Bindings.ONE_WAY,
      data: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
      submitted: Bindings.ONE_WAY,
      index: Bindings.ONE_WAY,
      passportRequired: Bindings.ONE_WAY,
      travelSource: Bindings.ONE_WAY,
    };
    this.controller = FlightsCheckoutSecondaryPassengerDetailFormController;
    this.templateUrl = '/html/flights/checkout_secondary_passenger_detail_form'
  }
}

angular.module("BookingApp").component("flightsCheckoutSecondaryPassengerDetailFormComponent", new FlightsCheckoutSecondaryPassengerDetailFormComponent());
