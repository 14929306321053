import { Bindings } from "booking_app/types";
import { FlightsSearchResultsSummaryController } from "./summary.controller";

export class FlightsSearchResultsSummaryComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      onSelectedSortOptionChange: Bindings.EXPRESSION,
      isLoading: Bindings.ONE_WAY,
      resultCount: Bindings.ONE_WAY,
      selectionStage: Bindings.ONE_WAY,
      destination: Bindings.ONE_WAY,
      date: Bindings.ONE_WAY,
    };
    this.controller = FlightsSearchResultsSummaryController;
    this.templateUrl = '/html/flights/search_results_summary';
  }
}

angular.module("BookingApp").component("flightsSearchResultsSummaryComponent", new FlightsSearchResultsSummaryComponent());
