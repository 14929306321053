import { FlightsSearchBarController } from ".";
import { Bindings } from "../../../types";

export class FlightsSearchBarComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      flightSearchParams: Bindings.ONE_WAY,
    };
    this.controller = FlightsSearchBarController;
    this.templateUrl = '/html/flights/search_bar';
  }
}

angular.module("BookingApp").component("flightsSearchBarComponent", new FlightsSearchBarComponent())
