import { TimeUtils } from "booking_app/utils";
import { FlightsSegment } from "booking_app/models";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

export class FlightsBookingSegmentController {
  static $inject = [
    "AppSettings",
    "$rootScope",
    "$translate",
  ];

  private segments: any;
  private segment: FlightsSegment;

  constructor(
    private appSettings: AppSettings,
    private $rootScope: any,
    private $translate: any,
  ) { }

  arrivalDayOffset() {
    const arrivalTime = moment.utc(this.segment.arrival_time);
    const departureTime = moment.utc(this.segment.departure_time);
    return TimeUtils.getArrivalDayOffset(arrivalTime, departureTime);
  }

  displayArrivalDate(): boolean {
    return this.appSettings.flightSegmentArrivalDisplay && (this.arrivalDayOffset() > 0);
  }

  segmentArrivalDate(): string {
    return moment(this.segment.arrival_time).format("D MMM");
  }

  computedLayoverTime(index) {
    const arrival = moment(this.segments[index - 1].arrival_time);
    const departure = moment(this.segments[index].departure_time);
    const total = moment.utc(departure).diff(arrival);

    return TimeUtils.formatDuration(
      moment.duration().add(total, "milliseconds"),
    );
  }

  flightIcon(): string {
    if (this.appSettings.customFlightIcon) {
      const appName = this.appSettings.tenant.toLowerCase();
      return `/${appName}/icons/icn-plane.png`;
    } else {
      return `/flight-icon.png`;
    }
  }

  segmentBaggageAllowance(): string {
    return this.$translate.instant(this.segment.baggage_allowance);
  }

  fareBrandName(): string {
    return this.segment.fare_brand_name ? this.segment.fare_brand_name.toLowerCase() : "";
  }

  aircraftTypeName(): string {
    return this.segment.aircraft_type_name ? this.segment.aircraft_type_name : "";
  }

  /**
   * Old format (BNZ only): <cabin> (<fare_reference>) | <aircraft_type_name>
   * New format: <fare_brand_name> | <aircraft_type_name>
   */
  flightFareType(): string {
    const fareType: string = this.fareBrandName();
    return this.segment.aircraft_type_name ? `${fareType} | ${this.segment.aircraft_type_name}` : fareType;
  }
}
