import { SortOption } from "../sorter";
import { SelectionStage } from "../selection-stage";
import { FlightsSearchResultsSorterService } from "../sorter/sorter.service";

declare var moment: any;

export class FlightsSearchResultsSummaryController {
  static $inject = ["$translate", "FlightsSearchResultsSorterService"];

  // bindings
  private onSelectedSortOptionChange: (obj: { option: SortOption }) => void;
  private isLoading: false; // used to show loading bar
  private resultCount: number;
  private selectionStage: SelectionStage;
  private destination: string;
  private date: string;

  constructor(
    private $translate: any,
    private flightsSearchResultsSorterService: FlightsSearchResultsSorterService,
  ) {
  }

  onUpdateSelectedSortOption(option: SortOption): void {
    this.onSelectedSortOptionChange({ option });
  }

  countDisclaimerText(): string {
    let countTranslation = this.$translate.instant('flights.summary.count_text', {
      count: this.resultCount,
      sortOption: this.flightsSearchResultsSorterService.selectedSortOption.text,
    });
    return `${countTranslation} ${this.$translate.instant('flights.summary.disclaimer_text')}`
  }

  translateDepartureAndReturn(withoutDestination: boolean = false): string {
    let departureKey: string = "flights.summary.departure";
    let returnKey: string = "flights.summary.return";
    if (withoutDestination) {
      departureKey = "flights.summary.departure_without_destination";
      returnKey = "flights.summary.return_without_destination";
    }
    const translationValues = {
      destination: this.destination,
      date: moment(this.date).format("ll"),
    };

    if (this.selectionStage === SelectionStage.ORIGIN) {
      return this.$translate.instant(departureKey, translationValues);
    } else if (this.selectionStage === SelectionStage.RETURN) {
      return this.$translate.instant(returnKey, translationValues);
    }
  }

  isDepartTrip(): boolean {
    return this.selectionStage === SelectionStage.ORIGIN;
  }
}
