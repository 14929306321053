import { FlightsSummaryPanelController } from ".";
import { Bindings } from "booking_app/types";

export class FlightsSummaryPanelComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      itinerary: Bindings.ONE_WAY,
      flightSearchParams: Bindings.ONE_WAY,
      redirectToCheckoutPage: Bindings.EXPRESSION,
    };
    this.templateUrl = "/html/flights/summary_panel";
    this.controller = FlightsSummaryPanelController;
  }
}

angular.module("BookingApp").component("flightsSummaryPanelComponent", new FlightsSummaryPanelComponent());
