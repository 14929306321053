import { FlightUrlGenerator } from "booking_app/services/flights/url-generator";
import { FlightSingleQueryParams } from "booking_app/models/flights-single-query-params";

export class FlightsCheckoutNavigationBarController {
  static $inject = [
    "$rootScope",
    "$location",
    "$routeParams",
    "FlightUrlGenerator",
  ];

  constructor(
    private $rootScope: any,
    private $location: any,
    private $routeParams: any,
    private flightUrlGenerator: FlightUrlGenerator,
  ) {}

  backToSummaryPage(): void {
    const urlParams: FlightSingleQueryParams = {
      booking_key: this.$routeParams.booking_key,
      partnerId: this.$rootScope.pointsPartner.id,
    };

    const url = this.flightUrlGenerator.generateSingleFlightUrl(urlParams, "summary");
    this.$location.url(url);
  }
}
