import { FlightsCheckoutState } from "./checkout.state";
import { SecondaryPassengerFormData } from "./secondary-passenger-detail-form";
import { isFutureDate, isValidDate } from "./helpers";
import { CommonPassengerDetailFormData } from "./common-passenger-detail-form";
import { PaymentDetailFormData } from "booking_app/components/common";
import { FlightsSinglePriceSearchService } from "booking_app/services/flights";
import { CheckoutStatusService } from "booking_app/services/checkout";
import { FlightsBookingsService } from "booking_app/services/flights/bookings";
import { NumberUtils } from "booking_app/utils";
import { PaymentMethodService } from "booking_app/services/payment-method.service";
import { AppSettings } from "booking_app/values/app-settings";
import "booking_app/services/payment";
import "booking_app/directives/scroll-if";
import { SavedCard } from "booking_app/models";
import { CurrentPage, Modal, PaymentChannel, PaymentMethod, ProductType } from "booking_app/types";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { CheckoutValidationService } from "booking_app/services/checkout-validation/checkout-validation.service";
import { PaymentTimerService } from "booking_app/services/payments/payment-timer.service";
import { PointsAdjustmentService } from "booking_app/services/points-adjustment.service";
import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { CouponService } from "booking_app/services/coupon.service";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { StripePaymentIntentService } from "booking_app/services/stripe-payment-intent.service";
import { AdyenFormService } from "booking_app/services/adyen-form.service";
import { SimpleModalService } from "booking_app/services/simple-modal.service";
import { FlightsBookingsPassengersParams } from "booking_app/services/flights/bookings/bookings-params";

declare var Rollbar: any;
export class FlightsCheckoutController {
  static $inject = [
    "$q",
    "$location",
    "$rootScope",
    "$scope",
    "FlightsCheckoutState",
    "FlightsSinglePriceSearchService",
    "PayWithPointsCashService",
    "CheckoutStatusService",
    "FlightsBookingsService",
    "AppSettings",
    "PaymentMethodService",
    "PaymentService",
    "GlobalStateService",
    "CheckoutValidationService",
    "PaymentTimerService",
    "PointsAdjustmentService",
    "SearchUrlService",
    "CouponService",
    "PointsCashShareService",
    "StripePaymentIntentService",
    "AdyenFormService",
    "SimpleModalService",
  ];

  isCheckoutDisclaimerAgreed: boolean;
  isTermsAndConditionsAgreed: boolean;

  private checkoutErrorCode: string;
  private showRememberMePopUp: boolean;
  private totalCashToPay: number;
  constructor(
    private $q: any,
    private $location: any,
    private $rootScope: any,
    private $scope: any,
    private state: FlightsCheckoutState,
    private service: FlightsSinglePriceSearchService,
    private payWithPointsCashService: PayWithPointsCashService,
    private checkoutStatusService: CheckoutStatusService,
    private flightsBookingsService: FlightsBookingsService,
    private appSettings: AppSettings,
    private paymentMethodService: PaymentMethodService,
    private paymentService: any,
    private globalStateService: GlobalStateService,
    private checkoutValidationService: CheckoutValidationService,
    private paymentTimerService: PaymentTimerService,
    private pointsAdjustmentService: PointsAdjustmentService,
    private searchUrlService: any,
    private couponService: CouponService,
    private pointsCashShareService: PointsCashShareService,
    private stripePaymentIntentService: StripePaymentIntentService,
    private adyenFormService: AdyenFormService,
    private simpleModalService: SimpleModalService,
  ) {
    this.isTermsAndConditionsAgreed = false;
    this.$scope.globalStateService = this.globalStateService;
  }

  $onInit() {
    if (this.notAllowedUser()) {
      return this.$location.url(this.searchUrlService.createFlightDetailsUrl());
    }

    this.service.runSingleSearch(true);
    this.globalStateService.currentPage = CurrentPage.CHECKOUT;
    this.showRememberMePopUp = false;
    this.$scope.paymentMethod = this.paymentMethodService;
    if ( !this.$scope.checkoutState ) {
      this.$scope.checkoutState = {
        errorKey: "",
        saveCreditCard: this.paymentMethodService.checkedSaveCreditCard,
      };
    }
    this.$scope.removeCreditCard = (nonce: string, payment_channel: string) =>
      this.removeCreditCard(nonce, payment_channel);
    this.$scope.tenant = this.appSettings.tenant.toLowerCase();
    this.fetchSavedCreditCards();
    this.fetchBookingStatus();
    this.itineraryPointsBuilderListener();
  }

  $onDestroy() {
    this.flightsBookingsService.resetState();
    this.checkoutStatusService.resetState();
  }

  toggleRememberMePopUp(rememberMe) {
    this.showRememberMePopUp = rememberMe;
  }

  passportRequired() {
    return this.state.itinerary && this.state.itinerary.passport_required;
  }

  travelSource() {
    return this.state.itinerary && this.state.itinerary.source;
  }

  updatePrimaryPassengerData(data) {
    this.state.data.primaryPassengerData = data;
  }

  updateSecondaryPassengerData(data: SecondaryPassengerFormData, index: number) {
    this.state.data.secondaryPassengerData[index] = data;
  }

  updatePaymentData(data: PaymentDetailFormData) {
    this.state.data.paymentData = data;
  }

  updateStripeCardPaymentChannel(stripeArray) {
    stripeArray.forEach(card => {
      card.payment_channel = PaymentChannel.STRIPE;
    });
  }

  updateStoredPayments(res) {
    this.updateStripeCardPaymentChannel(res.stripe);
    this.paymentMethodService.adyenSavedCards = res.adyen;
    this.paymentMethodService.savedCards = res.stripe;
    if (this.paymentMethodService.hasSavedCards()) {
      this.paymentMethodService.selectedCreditCard = this.paymentMethodService.savedCards[0];
    }
  }

  fetchSavedCreditCards(): any {
    const defer = this.$q.defer();
    if (this.appSettings.storeCreditCard && this.$rootScope.userDetails.loggedIn) {
      this.$scope.checkoutState.userPaymentMethodIsFetching = true;
      this.paymentService.getStoredPayments()
        .then( (storedCards) => {
          this.$scope.checkoutState.userPaymentMethodIsFetching = false;
          this.updateStoredPayments(storedCards);
          defer.resolve();
        })
        .catch( () => {
          this.$scope.checkoutState.userPaymentMethodIsFetching = false;
          defer.reject();
        });
    }
    return defer.promise;
  }

  showSavedCreditCards(): boolean {
    return this.paymentMethodService.allowSaveCreditCard() && this.storedCreditCards().length > 0;
  }

  storedCreditCards(): SavedCard[] {
    return this.paymentMethodService.savedCards;
  }

  removeCreditCard(nonce: string, payment_channel: string): void {
    this.paymentService.removeStoredPayment(nonce, payment_channel)
      .then( () => this.fetchSavedCreditCards() );
  }

  processCheckout(formRef: any): void {
    const minPointsPayment: number = this.service.getMinPointsPayment(this.state.itinerary);
    if (this.pointsAdjustmentService.showInsufficientPointBalance(this.state.itinerary.points, minPointsPayment)) {
      return;
    }
    this.paymentTimerService.paymentInProgressTimer();
    // assign the `checked status for saving credit card` into the PaymentMethodService
    this.paymentMethodService.checkedSaveCreditCard = this.$scope.checkoutState.saveCreditCard;

    if (this.canProceedtoBooking(formRef)) {
      if (this.checkoutValidationService.impersonatedModalCheck()) {
        this.openImpersonatedModal();
      } else {
        this.createBooking();
      }
    } else {
      this.checkoutValidationService.scrollToInvalidField();
    }
  }

  private setDefaultPaymentChannel(): void {
    if (!this.state.data.paymentData.creditCardData.paymentChannel) {
      switch (this.paymentMethodService.activePaymentTab) {
        case PaymentMethod.PAY_ANYONE:
          this.state.data.paymentData.creditCardData.paymentChannel =
            PaymentChannel.PAY_ANYONE;
          break;
        default:
          this.state.data.paymentData.creditCardData.paymentChannel =
            PaymentChannel.CREDIT_CARD;
      }
    }
  }

  checkout(formRef: any): void {
    this.setDefaultPaymentChannel();
    if (this.state.itinerary.source === "travelfusion" && this.canProceedtoBooking(formRef)) {
      const deregister = this.$rootScope.$on("process-flight-checkout", (_event) => {
        this.processCheckout(formRef);
        deregister(); // following https://docs.angularjs.org/api/ng/type/$rootScope.Scope#$on
      });
      this.service.runSinglePriceRevalidate({passengers: this.passengersData()});
    } else {
      this.processCheckout(formRef);
    }
  }

  canProceedtoBooking(formRef: any): boolean {
    return this.hasValidAdyenForm() && this.hasValidStripeIntentForm() && formRef.$valid && this.hasValidDateValidity();
  }

  openImpersonatedModal(): void {
    if (this.checkoutValidationService.impersonatedUserIsReadOnly()) {
      this.simpleModalService.open(Modal.IMPERSONATED_READ_ONLY);
    } else {
      this.simpleModalService.open(Modal.IMPERSONATED_CONFIRMATION).then(() => {
        this.createBooking();
      });
    }
  }

  createBooking(): any {
    this.service.createBooking()
      .then((res) => {
        this.checkoutStatusService.checkTransactionStatus(res.transaction_id);
      })
      .catch(((error) => {
        if (error.status && error.status.length !== 0) {
          this.checkoutErrorCode = error.status[0].error;
        } else if (error.errors && error.errors.length !== 0) {
          this.checkoutErrorCode = error.errors[0];
        }
      }));
  }

  paymentLoadingMessage(): string {
    return this.checkoutStatusService.loadingMessage;
  }

  isPaymentLoading(): boolean {
    return this.flightsBookingsService.isLoading || this.checkoutStatusService.isLoading;
  }

  isFetchingItinerary(): boolean {
    return this.service.isFetchingItinerary;
  }

  isRevalidatingItinerary(): boolean {
    return this.service.isRevalidatingItinerary;
  }

  isValidatingCoupon(): boolean {
    return this.couponService.validatingCouponCode || this.couponService.validatingPriceChanged;
  }

  isPriceChanged(): boolean {
    if (this.service.isPriceChanged && this.state.itinerary) {
      return this.hasCashAmountChanged();
    } else {
      return this.service.isPriceChanged;
    }
  }

  errorCode(): string {
    return this.checkoutErrorCode || this.flightsBookingsService.errorCode || this.checkoutStatusService.errorCode;
  }

  isBookingKeyExpired(): boolean {
    return this.service.isBookingKeyExpired();
  }

  showPaymentForm(): boolean {
    return !this.$rootScope.landingPage.hasProductType(ProductType.REDEEM) ||
      (
        this.$rootScope.landingPage.hasProductType(ProductType.REDEEM) &&
        this.state.itinerary &&
        this.getCashToPay() > 0
      );
  }

  cashToPay(): number {
    return this.totalCashPayment(
      this.getCashToPay(),
    );
  }

  fetchBookingStatus(): void {
    const transactionId: string = this.$location.search().transactionId;
    if (!transactionId) {
      return;
    }

    this.paymentTimerService.paymentInProgressTimer();
    this.checkoutStatusService.checkTransactionStatus(transactionId);
  }

  checkEventCode(event): boolean {
    return (event.code === "Enter" || event.keyCode === 13) ||
      (event.code === "Space" || event.keyCode === 32);
  }

  isAdyenCardPayment(): boolean {
    return this.state.data.paymentData.creditCardData.paymentChannel &&
      this.state.data.paymentData.creditCardData.paymentChannel.startsWith(PaymentChannel.ADYEN);
  }

  isStripePaymentIntents(): boolean {
    return this.state.data.paymentData.creditCardData.paymentChannel === PaymentChannel.STRIPE_PAYMENT_INTENTS;
  }

  isDefaultCreditCard(): boolean {
    return this.state.data.paymentData.creditCardData.paymentChannel === PaymentChannel.CREDIT_CARD;
  }

  private passengersData(): FlightsBookingsPassengersParams[] {
    return this.flightsBookingsService.buildPassengersRevalidateData(this.state.data);
  }

  private getCashToPay(): number {
    let cashDiscountTier: number[] = [...this.state.itinerary.cash_non_fixed_discounts_by_tier];

    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      cashDiscountTier = cashDiscountTier.reverse();
    }
    const nonFixedDiscount = cashDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.cashToPay(
      this.state.itinerary.base_cash_payment,
      nonFixedDiscount,
      this.state.itinerary.cash_fixed_discount,
    );
  }

  private hasValidPassportExpiryDate(passengerData: CommonPassengerDetailFormData): boolean {
    return isValidDate(passengerData.dateOfBirth) &&
      isFutureDate(passengerData.passportExpiryDate);
  }

  private hasValidCardExpiryDate(paymentData: PaymentDetailFormData): boolean {
    return isFutureDate(paymentData.creditCardData.expiryDate);
  }

  private hasValidDateValidity(): boolean {
    const { primaryPassengerData, secondaryPassengerData, paymentData } = this.state.data;

    return this.hasValidPassportExpiryDate(primaryPassengerData.commonData) &&
      secondaryPassengerData.every(data => this.hasValidPassportExpiryDate(data.commonData)) &&
      this.hasValidCardExpiryDate(paymentData);
  }

  private hasCashAmountChanged(): boolean {
    return this.totalCashPayment(this.state.itinerary.base_cash_payment) >
      this.totalCashPayment(this.state.itinerary.old_base_cash_payment);
  }

  private totalCashPayment(itineraryCash: number): number {
     return NumberUtils.formatCurrencyValue(
       itineraryCash,
       this.$rootScope.selectedCurrency,
      );
  }

  private notAllowedUser(): boolean {
    return this.$rootScope.globalState.requireLoginOnCheckout &&
      !this.$rootScope.isUserLoggedIn();
  }

  private itineraryPointsBuilderListener(): void {
    this.$rootScope.$on("pointsCashSliderUpdate", () => {
      this.state.itinerary.points = this.payWithPointsCashService.pointsToPay(
        this.state.itinerary.max_points_payment,
      );

      this.pointsAdjustmentService.pointsNeeded = this.state.itinerary.points;
    });
  }

  private hasValidAdyenForm(): boolean {
    return !this.showPaymentForm() ||
      this.adyenFormService.validAdyenForm(
        this.state.data.paymentData.creditCardData.paymentChannel,
        this.state.data.paymentData.creditCardData,
      ) ||
      !!this.paymentMethodService.selectedCreditCard;
  }

  private hasValidStripeIntentForm(): boolean {
    if (
      this.appSettings.stripePaymentIntentsEnabled && this.showPaymentForm() &&
      (this.isStripePaymentIntents() || this.isDefaultCreditCard())
    ) {
      return this.stripePaymentIntentService.validateStripeIntentForm();
    } else {
      return true;
    }
  }
}
