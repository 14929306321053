import { AppSettings } from "booking_app/values/app-settings";
import { FlightItem, FlightSearchParams } from "booking_app/types";
import { FlightsItinerary, FlightAirportSearchResult } from "booking_app/models";
import { StopoverFilter, AirlineFilter, RangeFilter, FlightsSearchResultsFilters } from "./filters";
import { SortOption, FlightsSearchResultsSorterService } from "./sorter";
import { SelectionStage } from "./selection-stage";
import { FlightsAirportSearchService } from "booking_app/services/flights";
import { TimeUtils } from "booking_app/utils";
import { FlightsSearchFormState } from "booking_app/components/flights/search-form";
import { ProductType } from "booking_app/types/product-type";
import { WhitelabelTranslateService } from "booking_app/services/whitelabel-translate.service";

export class FlightsSearchResultsState {
  static DEFAULT_RESULT_COUNT = 8; // For lazy loading

  static $inject = [
    "$routeParams",
    "FlightsSearchResultsSorterService",
    "FlightsAirportSearchService",
    "FlightsSearchFormState",
    "WhitelabelTranslateService",
    "$q",
    "$rootScope",
    "AppSettings",
  ];

  // route params
  flightSearchParams: FlightSearchParams;

  itineraries: FlightsItinerary[]; // Itineraries fetched from API, should never be changed locally
  flightItems: FlightItem[]; // Flight items built depending on selection stage
  displayedFlightItems: FlightItem[]; // Flight items after filterin, sorting and pagination
  totalDisplayedFlightItems: FlightItem[]; // Flight items after filtering and sorting
  selectionStage: SelectionStage;
  selectedOriginFlightItem: FlightItem;
  selectedReturnFlightItem: FlightItem;

  filters: FlightsSearchResultsFilters;

  resultsLimit: number; // for lazy loading

  constructor(
    private $routeParams: any,
    private flightsSearchResultsSorterService: FlightsSearchResultsSorterService,
    private flightsAirportSearchService: FlightsAirportSearchService,
    private flightsSearchFormState: FlightsSearchFormState,
    private whitelabelTranslateService: WhitelabelTranslateService,
    private $q: any,
    private $rootScope: any,
    private appSettings: AppSettings,
  ) {
  }

  resetValues(): void {
    this.resetFlightSearchParams();
    this.itineraries = [];
    this.rebuildFlightItems();
    this.rebuildFilters();
    this.resetSelectedSortOption();
    this.resetResultsLimit();
  }

  private resetFlightSearchParams(): void {
    const passengers = (this.$routeParams.passengers || '').split(":");
    this.flightSearchParams = {
      fromAirport: this.$routeParams.fromAirport,
      fromAirportName: null,
      toAirport: this.$routeParams.toAirport,
      toAirportName: null,
      departureDate: this.$routeParams.departureDate,
      returnDate: this.$routeParams.returnDate,
      cabin: this.$routeParams.cabin,
      flightType: this.$routeParams.flightType,
      adultCount: Number(passengers[0]),
      childCount: Number(passengers[1]),
      infantCount: Number(passengers[2]),
      currency: this.$routeParams.currency,
      partnerId: this.$routeParams.partnerId,
      landingPage: this.$routeParams.landingPage,
      departuresOnly: this.appSettings.departuresOnly,
      flightFareId: null,
    };
  }

  private fetchAirportSearch(): Promise<{ from: FlightAirportSearchResult, to: FlightAirportSearchResult }> {
    return this.$q.all([
      this.flightsAirportSearchService.airportSearch(this.flightSearchParams.fromAirport),
      this.flightsAirportSearchService.airportSearch(this.flightSearchParams.toAirport),
    ]).then((res: FlightAirportSearchResult[][]) => {
      return {
        from: res[0].find(x => x.value == this.flightSearchParams.fromAirport),
        to: res[1].find(x => x.value == this.flightSearchParams.toAirport),
      };
    });
  }

  private rebuildFlightItems(): void {
    this.flightItems = [];
    this.displayedFlightItems = [];

    this.selectionStage = SelectionStage.ORIGIN;
    this.selectedOriginFlightItem = null;
    this.selectedReturnFlightItem = null;
  }

  private rebuildFilters(): void {
    this.filters = {
      stopover: new StopoverFilter(this.whitelabelTranslateService),
      airline: new AirlineFilter(),
      departure: new RangeFilter(TimeUtils.MIN_MINUTES_IN_DAY, TimeUtils.MAX_MINUTES_IN_DAY, "departureTimeInMinutes"),
      arrival: new RangeFilter(TimeUtils.MIN_MINUTES_IN_DAY, TimeUtils.MAX_MINUTES_IN_DAY, "arrivalTimeInMinutes"),
      price: new RangeFilter(0, 100000000, this.isRedeem() ? "maxPriceInPoints" : "maxPriceInCash" ),
    }
  }

  resetFilters(): void {
    // AngularJS only checks for equality by reference when passing one-way binding
    // We should try changing this method to just reset the filters without copying after upgrading
    // https://github.com/angular/angular.js/pull/16553
    const _filters = angular.copy(this.filters);
    Object.keys(_filters).forEach((key) => {
      _filters[key].reset();
    });
    this.filters = _filters;
  }

  private resetSelectedSortOption() {
    this.flightsSearchResultsSorterService.selectedSortOption =
      this.flightsSearchResultsSorterService.permittedSortOptions[0];
  }

  private resetResultsLimit() {
    this.resultsLimit = FlightsSearchResultsState.DEFAULT_RESULT_COUNT;
  }

  private isRedeem() {
    return this.$rootScope.landingPage.hasProductType(ProductType.REDEEM);

  }

  resetSearchFormStateValues(): void {
    this.fetchAirportSearch()
      .then((res: { from: FlightAirportSearchResult, to: FlightAirportSearchResult }) => {
        this.flightsSearchFormState.fromAirport = res.from;
        this.flightsSearchFormState.toAirport = res.to;
        this.flightsSearchFormState.flightType = this.flightSearchParams.flightType;
        this.flightsSearchFormState.adultCount = this.flightSearchParams.adultCount;
        this.flightsSearchFormState.childCount = this.flightSearchParams.childCount;
        this.flightsSearchFormState.infantCount = this.flightSearchParams.infantCount;
        this.flightsSearchFormState.cabin = this.flightSearchParams.cabin;
        this.flightsSearchFormState.departureDate = this.flightSearchParams.departureDate;
        this.flightsSearchFormState.returnDate = this.flightSearchParams.returnDate;
      })
      .catch((() => { }));
  }
}

angular.module("BookingApp").service("FlightsSearchResultsState", FlightsSearchResultsState);
