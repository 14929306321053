import { Bindings, FlightCabin } from "booking_app/types";
import { FlightsSegmentController } from "./flights-segment.controller";

export class FlightsSegmentComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      segment: Bindings.ONE_WAY,
      index: Bindings.ONE_WAY,
      source: Bindings.ONE_WAY,
      features: Bindings.ONE_WAY,
    };
    this.controller = FlightsSegmentController;
    this.templateUrl = "/html/flights/segment";
  }
}

angular.module("BookingApp").component("flightsSegmentComponent", new FlightsSegmentComponent());
