import { FlightsItinerary } from "booking_app/models";
import { NumberUtils } from "booking_app/utils";
import { AppSettings } from "booking_app/values/app-settings";
import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { PointsCashPaymentHandlingService } from "booking_app/services/points-cash-payment-handling.service";

export class FlightsPaymentSummaryController {
  static $inject = [
    "$filter",
    "$rootScope",
    "PayWithPointsCashService",
    "AppSettings",
    "PointsCashShareService",
    "PointsCashPaymentHandlingService",
  ];

  // bindings
  itinerary: FlightsItinerary;

  constructor(
    private $filter: any,
    private $rootScope: any,
    private payWithPointsCashService: PayWithPointsCashService,
    private appSettings: AppSettings,
    private pointsCashShareService: PointsCashShareService,
    private pointsCashPaymentHandlingService: PointsCashPaymentHandlingService,
  ) {}

  hasDiscountedPrice(): boolean {
    return this.payWithPointsCashService.hasDiscountedPrice(this.itinerary);
  }

  getPriceBeforeDiscount(): string {
    return this.payWithPointsCashService.getPriceBeforeDiscount(this.itinerary);
  }

  formattedPointsPayment(): string {
    return this.formatNumber(this.totalPointsPayment(), 0);
  }

  formattedCashPayment(): any {
    return this.formatNumber(
      this.formatCurrencyValue(this.totalCashPayment()),
      this.$rootScope.selectedCurrency.decimalPlace,
    );
  }

  totalCashPayment(): number {
    let cashDiscountTier: number[] = [...this.itinerary.cash_non_fixed_discounts_by_tier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      cashDiscountTier = cashDiscountTier.reverse();
    }
    const nonFixedDiscount = cashDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.cashToPay(
      this.itinerary.base_cash_payment,
      nonFixedDiscount,
      this.itinerary.cash_fixed_discount,
    );
  }

  totalPointsPayment(): number {
    let pointsDiscountTier: number[] = [...this.itinerary.cash_non_fixed_discounts_by_tier];
    if (this.appSettings.pointsCashSliderSettings.invertedPointsCashSlider) {
      pointsDiscountTier = pointsDiscountTier.reverse();
    }
    const nonFixedDiscount = pointsDiscountTier[this.pointsCashShareService.pointsCashShare.value];

    return this.payWithPointsCashService.pointsToPay(
      this.itinerary.base_points_payment,
      nonFixedDiscount,
      this.itinerary.points_fixed_discount,
    );
  }

  zeroFullCashPayment(): boolean {
    return this.pointsCashPaymentHandlingService.zeroFullCashPayment(
      this.totalCashPayment(),
      this.totalPointsPayment(),
    );
  }

  private formatNumber(value: number, decimalPlace: number): string {
    return this.$filter("numberFmt")(value, this.$rootScope.selectedLocale, decimalPlace);
  }

  private formatCurrencyValue(value): number {
    return NumberUtils.formatCurrencyValue(
      value,
      this.$rootScope.selectedCurrency,
    );
  }
}
