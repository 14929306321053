import { Bindings } from "booking_app/types";
import { FlightsSearchResultsListItemController } from ".";

export class FlightsSearchResultsListItemComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      flightItem: Bindings.ONE_WAY,
      showFlightInfoPrice: Bindings.ONE_WAY,
      showSegments: Bindings.ONE_WAY,
      onSelect: Bindings.EXPRESSION,
      selectionStage: Bindings.ONE_WAY,
      travellerCount: Bindings.ONE_WAY,
      showHiddenHeading: Bindings.ONE_WAY,
    };
    this.controller = FlightsSearchResultsListItemController;
    this.templateUrl = "/html/flights/search_results_list_item";
  }
}

angular.module("BookingApp").component("flightsSearchResultsListItemComponent",
  new FlightsSearchResultsListItemComponent());
