import { FlightsSearchResultsSorterController } from ".";
import { Bindings } from "../../../../types";

export class FlightsSearchResultsSorterComponent {
  bindings: any;
  controller: any;
  template: string;

  constructor() {
    this.bindings = {
      onSelectedSortOptionChange: Bindings.EXPRESSION,
    };
    this.controller = FlightsSearchResultsSorterController;
    this.template = `
      <input-select
        class="ipt-select2 thin"
        value-formatter="$ctrl.displaySortOptions"
        on-update="$ctrl.updateSelectedSortOption(value)"
        selected-value="$ctrl.flightsSearchResultsSorterService.selectedSortOption"
        options="$ctrl.sortOptions"
      ></input-select>
    `;
  }
}

angular.module("BookingApp").component("flightsSearchResultsSorterComponent", new FlightsSearchResultsSorterComponent());
