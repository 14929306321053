import { FlightItem } from "booking_app/types";
import { FlightItemFilter } from "../flight-item-filter";
import { AirlineFilterUnit } from "./airline-filter-unit";

export class AirlineFilter extends FlightItemFilter {

  allFilterUnits: AirlineFilterUnit[];
  activeFilterUnits: AirlineFilterUnit[];

  constructor() {
    super();
    this.allFilterUnits = [];
    this.reset();
  }

  buildFilterUnits(airlines: { label: string, id: string }[]): void {
    this.allFilterUnits = airlines.map((airline) => { return { label: airline.label, id: airline.id } });
  }


  performSingle(filter: AirlineFilterUnit, flightItem: FlightItem): boolean {
    for (let segment of flightItem.segments) {
      if (segment.marketing_airline_code == filter.id) {
        return true;
      }
    }
    return false;
  }

  perform(flightItem: FlightItem): boolean {
    if (this.activeFilterUnits.length === 0) {
      return true;
    }

    return this.activeFilterUnits.some(filterUnit => this.performSingle(filterUnit, flightItem));
  }

  reset(): void {
    this.activeFilterUnits = [];
  }

  toggleFilter(filterUnit: AirlineFilterUnit) {
    const filterUnitIndex: number = this.activeFilterUnits.findIndex(unit => unit.id === filterUnit.id);
    const filterIsActive: boolean = filterUnitIndex > -1;
    if (filterIsActive) {
      this.activeFilterUnits.splice(filterUnitIndex, 1);
    } else {
      this.activeFilterUnits.push(filterUnit);
    }
  }

  findFilter(filterUnit: AirlineFilterUnit): AirlineFilterUnit {
    return this.activeFilterUnits.find(unit => unit.id === filterUnit.id);
  }
}
