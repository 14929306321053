import { FlightsSearchFormService, FlightsSearchFormState } from ".";
import { FlightAirportSearchResult } from "booking_app/models";
import { FlightCabin, FlightType, TravelType } from "booking_app/types";
import { FlightsAirportSearchService } from "booking_app/services/flights";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

export class FlightsSearchFormController {

  static $inject = [
    "FlightsSearchFormService",
    "FlightsSearchFormState",
    "FlightsAirportSearchService",
    "AppSettings",
    "AppConfig",
    "$translate",
    "$location",
    "$rootScope",
    "$window",
  ];

  // bindings
  onSearch: () => void;
  labelGroup: string;

  public flightTypeOptionsDisplay: any;
  public flightTypeOptions: any[];
  private cabinOptions: string[];
  private submitted: boolean;

  constructor(
    private formService: FlightsSearchFormService,
    private formState: FlightsSearchFormState,
    private flightsAirportSearchService: FlightsAirportSearchService,
    private appSettings: AppSettings,
    private appConfig: any,
    private $translate: any,
    private $location: any,
    private $rootScope: any,
    private $window: any,
  ) {
    this.flightsAirportSearchService = flightsAirportSearchService;
    this.$translate = $translate;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.flightTypeOptions = [
      { value: "ow", display: "One Way" },
      { value: "rt", display: "Return" },
    ];
  }

  $onInit() {
    this.cabinOptions = Object.keys(FlightCabin)
      .filter(key => typeof FlightCabin[key] !== "function")
      .map(key => FlightCabin[key]);

    this.flightTypeOptions = Object.keys(FlightType)
      .map(key => FlightType[key]);

    if (!this.formState.fromAirport) {
      this.buildDefaultAirportDeparture();
    }

    // binding the `flightTypeOptionsDisplay` to current controller to use the `labelGroup`
    this.flightTypeOptionsDisplay = (option) => this.flightOptionDisplay(option);
  }

  isReturnFlight() {
    return this.formState.flightType === FlightType.RETURN;
  }

  isOneWayFlight() {
    return this.formState.flightType === FlightType.ONE_WAY;
  }

  onUpdateFlightType() {
    this.formState.returnDate = this.formState.departureDate;
  }

  updateOrigin(value: FlightAirportSearchResult) {
    this.formState.fromAirport = value;
  }

  updateDestination(value: FlightAirportSearchResult) {
    this.formState.toAirport = value;
  }

  updateCabin(value: FlightCabin) {
    this.formState.cabin = value;
  }

  updateFlightType(value: FlightType) {
    this.formState.flightType = value;
    this.onUpdateFlightType();
  }

  updateDates(value: { startDate: string, endDate: string }) {
    this.formState.departureDate = value.startDate;
    if (this.formState.flightType === FlightType.RETURN) {
      this.formState.returnDate = value.endDate;
    } else {
      this.formState.returnDate = this.formState.departureDate;
    }
  }

  updateAdultCount(value: number) {
    this.formState.adultCount = value;
  }

  updateChildCount(value: number) {
    this.formState.childCount = value;
  }

  updateInfantCount(value: number) {
    this.formState.infantCount = value;
  }

  cabinOptionsDisplay(option: string) {
    return FlightCabin.toStringTranslation(option);
  }

  flightOptionDisplay(option: FlightType) {
    let labelPrefix = "flights.type";
    if (this.labelGroup && this.labelGroup !== "") {
      labelPrefix = `wl.${this.labelGroup}.flights.type`;
    }
    if (option === FlightType.ONE_WAY) {
      return `${labelPrefix}.oneway`;
    }
    return `${labelPrefix}.return`;
  }

  searchFlights() {
    this.submitted = true;

    if (this.errorMessage("origin") !== "" || this.errorMessage("destination") !== "") {
      setTimeout(() => {
        this.submitted = false;
      }, 100);
      return;
    }

    // Redirect to flights search result page, pass along params from flights search form state
    const url = this.formService.generateResultsUrl();

    if (this.appSettings.reloadOnSearch) {
      this.$window.location.href = url;
    } else {
      this.$location.url(url);
    }

    // close edit search modal in results page
    if (this.onSearch) {
      this.onSearch();
    }
  }

  errorMessage(field: string): string {
    const { fromAirport, toAirport } = this.formState;

    if (!this.submitted) {
      return "";
    }

    if (field === "origin") {
      return this.$translate.instant(this.locationError(fromAirport, field));
    }

    if (field === "destination") {
      if (this.sameOriginAndDestination(fromAirport, toAirport)) {
        return this.$translate.instant("Origin and Destination cannot be the same!");
      }
      return this.$translate.instant(this.locationError(toAirport, field));
    }
  }

  minStartDate(): string {
    return moment().add(2, "d").format("YYYY-MM-DD");
  }

  minDaysToBookInAdvance(): number {
    return this.appConfig.min_days_to_book_in_advance[TravelType.FLIGHTS];
  }

  updateDepartureDate(value: string) {
    this.formState.departureDate = value;
  }

  updateReturnDate(value: string) {
    this.formState.returnDate = value;
  }

  private buildDefaultAirportDeparture() {
    this.flightsAirportSearchService.airportSearch(this.appSettings.defaultAirlineSearchCode).
      then((res: FlightAirportSearchResult[]) => {
        this.formState.fromAirport = res[0];
      });
  }

  private locationError(location, dataset: "origin" | "destination"): string {
    if (this.appSettings.customFlightsSearchErrorMessage[dataset]) {
      return !location ? this.$translate.instant(this.appSettings.customFlightsSearchErrorMessage[dataset]) : "";
    } else {
      return !location ? this.$translate.instant("wl.please_fill_in_a_destination") : "";
    }
  }

  private sameOriginAndDestination(
    fromAirport: FlightAirportSearchResult,
    toAirport: FlightAirportSearchResult,
  ): boolean {
    return (fromAirport && toAirport && fromAirport.value === toAirport.value);
  }
}
