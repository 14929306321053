import { Bindings } from "../../../../types";
import { FlightsPassengersDropdownController } from "./flights-passengers-dropdown.controller";

export class FlightsPassengersDropdownComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      adultCount: Bindings.ONE_WAY,
      childCount: Bindings.ONE_WAY,
      infantCount: Bindings.ONE_WAY,
      onUpdateAdultCount: Bindings.EXPRESSION,
      onUpdateChildCount: Bindings.EXPRESSION,
      onUpdateInfantCount: Bindings.EXPRESSION,
    };

    this.controller = FlightsPassengersDropdownController;

    this.templateUrl = "/html/flights/passengers_dropdown";
  }

}

angular.module("BookingApp").component("flightsPassengersDropdownComponent", new FlightsPassengersDropdownComponent());
