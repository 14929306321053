import { convertJSONToQueryString } from "booking_app/services/route-params-utils";
import { FlightsSinglePriceSearchResult } from "booking_app/models";
import { FlightSingleQueryParams, FlightSingleQueryPage } from "booking_app/models/flights-single-query-params";
import { AppSettings } from "booking_app/values/app-settings";
import { PointsCashShareService } from "../points-cash-share.service";

export class FlightUrlGenerator {

  static $inject = ["$location", "$rootScope", "AppSettings", "PointsCashShareService"];


  constructor(
    private $location: any,
    private $rootScope: any,
    private appSettings: AppSettings,
    private pointsCashShareService: PointsCashShareService,
  ) {}

  public generateSearchResultsUrl(searchResult: FlightsSinglePriceSearchResult): string {
    const urlParams: any = {
      fromAirport: searchResult.search.from_airport,
      toAirport: searchResult.search.to_airport,
      departureDate: searchResult.search.departure_date,
      returnDate: searchResult.search.return_date,
      cabin: searchResult.search.cabin,
      passengers: searchResult.search.passengers.replace(/,/g, ":"),
      flightType: searchResult.search.flight_type,
      currency: this.$rootScope.selectedCurrency.code,
      landingPage: this.$rootScope.landingPage.url,
      partnerId: this.$rootScope.pointsPartner.id,
      pointsCashShare: this.pointsCashShareService.pointsCashShare?.value,
      pointsAccountId: this.$rootScope.pointsAccountId,
    };

    return `/flights/results?${convertJSONToQueryString(urlParams)}`;
  }

  public generateSingleFlightUrl(
    flightSingleQueryParams: FlightSingleQueryParams,
    page: FlightSingleQueryPage,
  ): string {
    const urlParams: FlightSingleQueryParams = {
      booking_key: flightSingleQueryParams.booking_key,
      partnerId: flightSingleQueryParams.partnerId,
      pointsCashShare: this.pointsCashShareService.pointsCashShare?.value,
      pointsAccountId: this.$rootScope.pointsAccountId,
    };

    if (this.appSettings.useProductType) {
      urlParams.productType = this.$rootScope.globalState.productType;
    }

    return `/flights/${page}?${convertJSONToQueryString(urlParams)}`;
  }
}

angular.module("BookingApp").service('FlightUrlGenerator', FlightUrlGenerator)
