import { FlightsSummaryController } from ".";

export class FlightsSummaryComponent {
  controller: any;
  templateUrl: string;

  constructor() {
    this.templateUrl = "/html/flights/summary";
    this.controller = FlightsSummaryController;
  }
}

angular.module("BookingApp").component("flightsSummaryComponent", new FlightsSummaryComponent());
