import { CommonPassengerDetailFormData } from "./common-passenger-detail-form-data";
import {
  DateSelectOption,
  DateSelectService,
} from "booking_app/components/flights/checkout/date-select";
import { CountrySelectOption } from "booking_app/components/common/country-select";
import {
  isFutureDate,
  isValidDate,
} from "booking_app/components/flights/checkout/helpers";
import { AngularUtils } from "booking_app/utils";
import { CheckoutOptions } from "booking_app/services/checkout-options";
import { PaginatedDropdownType } from "booking_app/types/paginated-dropdown-type";

export class FlightsCheckoutCommonPassengerDetailFormController {
  static $inject = [
    "DateSelectService",
    "$rootScope",
    "$scope",
    "AppSettings",
    "CheckoutOptions",
  ];

  // bindings
  parentForm: any;
  formName: string;
  data: CommonPassengerDetailFormData;
  onUpdate: (obj: { data: CommonPassengerDetailFormData }) => void;
  submitted: boolean;
  passportRequired: boolean;
  formIdIndex: string;
  secondaryPassenger: boolean;
  travelSource: string;

  internalData: CommonPassengerDetailFormData; // copy of data from bindings

  constructor(
    private dateSelectService: DateSelectService,
    private $rootScope: any,
    private $scope: any,
    private AppSettings: any,
    private checkoutOptions: any,
  ) { }

  $onInit(): void {
    this.$scope.guestTitleOptions = this.checkoutOptions.guestTitleOptions();
    this.$scope.countryOptions = this.checkoutOptions.countryOptions();
    this.displayPattern();
  }

  $onChanges(changesObj) {
    if (AngularUtils.hasBindingChanged(changesObj.data)) {
      this.internalData = angular.copy(this.data);
    }
  }

  displayPattern(): void {
    let regexp = new RegExp("^[A-Za-z ]+$");
    if (this.travelSource === "travelfusion") {
      regexp = new RegExp("^[a-zA-Z0-9 ]*$");
    }

    this.$scope.pattern = regexp;
  }

  onUpdateData() {
    this.onUpdate({ data: this.internalData });
  }

  displayDateOptions(option: DateSelectOption): string {
    return option && option.text;
  }

  displayPassportNationalityOptions(option: CountrySelectOption): string {
    return option && option.text;
  }

  onUpdateDateOfBirth(value: DateSelectOption, key: string): void {
    this.internalData.dateOfBirth[key] = value;
    this.onUpdateData();
  }

  onUpdatePassportNationality(value: CountrySelectOption): void {
    this.internalData.passportNationality = value;
    this.onUpdateData();
  }

  onUpdatePassportExpiryDate(value: DateSelectOption, key: string): void {
    this.internalData.passportExpiryDate[key] = value;
    this.onUpdateData();
  }

  dayOptions() {
    return this.dateSelectService.dayOptions;
  }

  monthOptions() {
    return this.dateSelectService.monthOptions;
  }

  pastYearOptions() {
    return this.dateSelectService.pastYearOptions;
  }

  futureYearOptions() {
    return this.dateSelectService.futureYearOptions;
  }

  validDateOfBirth(): boolean {
    return isValidDate(this.internalData.dateOfBirth);
  }

  validPassportExpiryDate(): boolean {
    return isFutureDate(this.internalData.passportExpiryDate);
  }

  usDateFormat(): boolean {
    return this.AppSettings.usDateFormat;
  }

  showDateOfBirthRequiredError(): boolean {
    return (
      this.submitted &&
      (this.parentForm?.dateOfBirthDay.$error.required ||
        this.parentForm?.dateOfBirthMonth.$error.required ||
        this.parentForm?.dateOfBirthYear.$error.required)
    );
  }

  showDateOfBirthInvalidError(): boolean {
    return (
      this.parentForm?.dateOfBirthYear &&
      this.parentForm?.dateOfBirthDay.$dirty &&
      this.parentForm?.dateOfBirthMonth.$dirty &&
      this.parentForm?.dateOfBirthYear.$dirty
    );
  }

  showPassportExpiryDateRequiredError(): boolean {
    return (
      this.submitted &&
      (this.parentForm?.passportExpiryDateDay.$error.required ||
        this.parentForm?.passportExpiryDateMonth.$error.required ||
        this.parentForm?.passportExpiryDateYear.$error.required)
    );
  }

  showPassportExpiryDateInvalidError(): boolean {
    return (
      this.parentForm?.passportExpiryDateYear &&
      this.parentForm?.passportExpiryDateDay.$dirty &&
      this.parentForm?.passportExpiryDateMonth.$dirty &&
      this.parentForm?.passportExpiryDateYear.$dirty
    );
  }

  passportNationalityValueFormatter(passportNationalityValues: PaginatedDropdownType): CountrySelectOption {
    const countrySelectValue: CountrySelectOption = {
      id: "",
      code: "",
      text: "",
    };

    if (passportNationalityValues.value) {
      countrySelectValue.code = passportNationalityValues.value;
      countrySelectValue.id = passportNationalityValues.value;
      countrySelectValue.text = passportNationalityValues.text;
    }

    return countrySelectValue;
  }

  updateGuestTitleValue(salutationValue): void {
    this.internalData.title = salutationValue.value;
    this.onUpdateData();
  }

  generateFormId(prefix: string): string {
    return prefix + "-" + this.formIdIndex;
  }
}
