import { ApiDataService } from 'booking_app/services/api-data.service';
import { FlightAirportSearchResult } from "booking_app/models";

export class FlightsAirportSearchService {

  static $inject = [
    '$q',
    'ApiDataService',
  ];

  constructor(
    private $q,
    private apiDataService: ApiDataService,
  ) { }

  airportSearch(name: string): Promise<FlightAirportSearchResult[]> {
    return this.$q((resolve, reject) => {
      if (!name || name === "") {
        reject("Empty name");
      } else {
        this.apiDataService.get(`airports?name=${name}`)
          .then((res: FlightAirportSearchResult[]) => {
            resolve(res);
          })
          .catch(error => reject(error));
      }
    })
  }
}

angular.module("BookingApp").service("FlightsAirportSearchService", FlightsAirportSearchService);
