declare var Rollbar: any;

import { CurrentPage, FlightCabin, FlightItem, FlightSearchParams, FlightType, Modal } from "booking_app/types";
import { FlightItemBuilder, FlightsBookingsService, FlightUrlGenerator } from "booking_app/services/flights";
import { ApiPollingService } from "booking_app/services/api-polling.service";
import { FlightsItinerary, FlightsSinglePriceSearchResult, FlightsTermsConditionRule } from "booking_app/models";
import {
  defaultSinglePriceSearchResult,
  isBookingKeyExpired,
} from "booking_app/models/utils/single-price-utils";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { SimpleModalService } from "booking_app/services/simple-modal.service";
import { AppSettings } from "booking_app/values/app-settings";
import { PayWithPointsCashService } from "booking_app/services/pay-with-points-cash.service";
import { PointsAdjustmentService } from "booking_app/services/points-adjustment.service";
import { FlightsCheckoutState } from "booking_app/components/flights/checkout/checkout.state";
import { UrlUtils } from "booking_app/utils";
import { ApiDataService  } from "booking_app/services/api-data.service";

export class FlightsSinglePriceSearchService {
  static $inject = [
    "$location",
    "$rootScope",
    "$routeParams",
    "ApiPollingService",
    "AppSettings",
    "FlightsBookingsService",
    "FlightsCheckoutState",
    "FlightItemBuilder",
    "FlightUrlGenerator",
    "GlobalStateService",
    "PayWithPointsCashService",
    "PointsAdjustmentService",
    "SimpleModalService",
    "UrlUtils",
    "ApiDataService",
  ];

  page: "summary" | "checkout";

  // both page used params
  singlePriceSearchResult: FlightsSinglePriceSearchResult;
  isFetchingItinerary: boolean = true;
  isRevalidatingItinerary: boolean;
  isPriceChanged: boolean;

  // summary page params
  flightSearchParams: FlightSearchParams;
  flightItems: FlightItem[];
  itineraries: FlightsItinerary[];
  flightSearchUrl: string;

  // checkout params
  bookingRules: FlightsTermsConditionRule[];
  externalTermsConditionLink: string;

  constructor(
    private $location: any,
    private $rootScope: any,
    private $routeParams: any,
    private apiPollingService: ApiPollingService,
    private appSettings: AppSettings,
    private flightsBookingsService: FlightsBookingsService,
    private state: FlightsCheckoutState,
    private flightItemBuilder: FlightItemBuilder,
    private flightUrlGenerator: FlightUrlGenerator,
    private globalStateService: GlobalStateService,
    private payWithPointsCashService: PayWithPointsCashService,
    private pointsAdjustmentService: PointsAdjustmentService,
    private simpleModalService: SimpleModalService,
    private urlUtils: UrlUtils,
    private apiDataService: ApiDataService,
  ) {
    this.flightItems = [];
    this.singlePriceSearchResult = null;
    this.flightSearchParams = null;
    this.isRevalidatingItinerary = true;
    this.updateFetchingItineraryState();
  }

  createBooking(): Promise<{ transaction_id: string }> {
    return this.flightsBookingsService.createBooking(
      this.state.data,
      this.state.params,
      this.state.itinerary,
    );
  }

  runSingleSearch(
    runSpinner: boolean,
    couponCode: string = "",
  ): Promise<void> {
    this.isPriceChanged = false;
    this.updateFetchingItineraryState();
    return this.fetchFlightsSinglePriceSearch(false, couponCode)
      .then((res) => {
        this.singlePriceSearchResult = res;
        if (!this.isBookingKeyExpired()) {
          this.flightSummaryPageActions(res);
          this.handleSinglePriceFetchSuccess(res);
          if (this.inCheckoutPage()) {
            return this.fetchFlightsSinglePriceSearch(true, couponCode);
          } else {
            return res;
          }
        } else {
          this.expiredBookingKeyActions();
          return res;
        }
      }).catch((_res) => {
        this.expiredBookingKeyActions();
      })
      .then((res: FlightsSinglePriceSearchResult) => {
        this.singlePriceSearchResult = res;
        if (!this.isBookingKeyExpired()) {
          this.flightSummaryPageActions(res);
          this.handleSinglePriceFetchSuccess(res);
          this.isRevalidatingItinerary = false;
          // needed to broadcast this to populate state.itinerary.points
          if (this.appSettings.checkoutSettings.insufficientPointsModalCheck) {
            this.$rootScope.$broadcast("pointsCashSliderUpdate");
          }

          if (res.itineraries.length > 0) {
            this.handlePriceChange(res);
          }
        } else {
          this.expiredBookingKeyActions();
        }
      })
      .catch(() => {
        this.expiredBookingKeyActions();
      })
      .finally(() => {
        if (this.inCheckoutPage()) {
          this.checkoutFinalAction();
        }
      });
  }

  runSinglePriceRevalidate(bookingData: any) {
    this.flightsBookingsService.showLoading();
    this.revalidateFlightSinglePriceSearch(bookingData);
  }

  flightSummaryPageActions(res: any): void {
    if (this.inDetailsPage()) {
      this.itineraries = res.itineraries;
      this.buildFlightSearchParams();
      this.rebuildItineraries();
      this.buildRedirectToSearchUrl();
    }
  }

  isBookingKeyExpired(): boolean {
    return isBookingKeyExpired(this.singlePriceSearchResult);
  }

  rebuildItineraries(): void {
    if (this.flightSearchParams.flightType === FlightType.RETURN) {
      this.flightItems = [this.originFlightItem(), this.returnFlightItem()];
    } else if (this.flightSearchParams.flightType === FlightType.ONE_WAY) {
      this.flightItems =  [this.originFlightItem()];
    }
  }

  priceChangeAccepted(): void {
    this.isPriceChanged = false;

    if (this.resumeCheckout()) {
      this.flightsBookingsService.showLoading();
      this.checkInsufficientPointsBalance(true);
    } else {
      this.checkInsufficientPointsBalance(); // so we will only check points once accepted
    }
  }

  getMinPointsPayment(itinerary: FlightsItinerary): number {
    return this.payWithPointsCashService.minPoint(itinerary.max_points_payment);
  }

  loadTermsData(): void {
    this.apiPollingService.poll(this.buildUrl(`flights/${this.$routeParams.booking_key}/terms`), {}).then(
      (data: any) => {
        this.bookingRules = data.rules;
        if (this.isExternalTermsConditionLink(data.rules)) {
          this.externalTermsConditionLink = this.urlUtils.convertToHttpsUrl(data.rules[0].rule);
        }
      },
      (errors: any) => Rollbar.warning("Error on loading flights terms: " + JSON.stringify(errors)),
    );
  }

  isExternalTermsConditionLink(rules = this.bookingRules): boolean {
    return rules.length === 1 && this.urlUtils.isValidHttpUrl(rules[0].rule);
  }

  // Applicable only if the supplier requires passenger data to get the final price
  private resumeCheckout(): boolean {
    return this.state.itinerary.source === "travelfusion";
  }

  private updateFetchingItineraryState(): void {
    if (this.inDetailsPage()) {
      this.isFetchingItinerary = true;
    }
  }

  private checkoutFinalAction(): void {
    if (this.isBookingKeyExpired()) {
      if (!!this.singlePriceSearchResult.search && Object.keys(this.singlePriceSearchResult.search).length > 0) {
        this.simpleModalService.open(Modal.FLIGHTS_EXPIRY_BACK_TO_RESULTS_PAGE).then(() => {
          const url: string = this.flightUrlGenerator.generateSearchResultsUrl(this.singlePriceSearchResult);
          this.$location.url(url);
        });
      } else {
        this.showSummaryPageExpiryModal();
      }
    } else {
      this.loadTermsData();
    }
  }

  private expiredBookingKeyActions(): any {
    if (this.isBookingKeyExpired()) {
      this.singlePriceSearchResult = defaultSinglePriceSearchResult();
      this.checkAndShowSummaryPageExpiryModal();
    }
  }

  private originFlightItem(): any {
    return this.flightItemBuilder.buildOriginFlightItems(this.itineraries)[0];
  }

  private returnFlightItem(): any {
    return this.flightItemBuilder.buildReturnFlightItems(this.itineraries, this.originFlightItem())[0];
  }

  private fetchFlightsSinglePriceSearch(
    revalidate: boolean,
    couponCode: string = "",
  ): Promise<FlightsSinglePriceSearchResult> {
    return this.apiPollingService.poll(`flights/single_price/${this.$routeParams.booking_key}`, {
      ...this.buildSinglePriceParams(couponCode),
      revalidate,
    });
  }

  private revalidateFlightSinglePriceSearch(bookingData: any): Promise<void> {
    const url = `flights/single_price/${this.$routeParams.booking_key}`;
    return this.apiDataService.post(url, this.buildSinglePriceParams("", bookingData))
    .then((res: FlightsSinglePriceSearchResult) => {
      this.singlePriceSearchResult = res;
      if (!this.isBookingKeyExpired()) {
        this.flightSummaryPageActions(res);
        this.handleSinglePriceFetchSuccess(res);
        this.isRevalidatingItinerary = false;
        // needed to broadcast this to populate state.itinerary.points
        if (this.appSettings.checkoutSettings.insufficientPointsModalCheck) {
          this.$rootScope.$broadcast("pointsCashSliderUpdate");
        }

        if (res.itineraries.length > 0) {
          this.handlePriceChange(res, true);
        }
      } else {
        this.flightsBookingsService.hideLoading();
        this.expiredBookingKeyActions();
      }
    }).catch((res: FlightsSinglePriceSearchResult) => {
      this.flightsBookingsService.setRevalidateError(res.error);
      this.flightsBookingsService.hideLoading();
      this.expiredBookingKeyActions();
    }).finally(() => {
    }) as Promise<void>;
  }

  private buildSinglePriceParams(couponCode: string = "", bookingData?: any): any {
    const urlParams: any = {
      landing_page: this.$rootScope.landingPage.url,
      partner_id: this.$rootScope.pointsPartner.id,
      coupon_code: couponCode,
    };

    if (this.$rootScope.useProductType()) {
      urlParams.product_type = this.$rootScope.productTypeAdapter(this.$rootScope.globalState.productType);
    }

    if (bookingData !== undefined) {
      urlParams.booking_data = bookingData;
    }

    return urlParams;
  }

  private handlePriceChange(res: FlightsSinglePriceSearchResult, resumeCheckout: boolean = false): void {
    const itinerary = res.itineraries[0];
    // itinerary.base_cash_payment = itinerary.base_cash_payment + 100
    const cashChanged = itinerary.old_base_cash_payment &&
      itinerary.old_base_cash_payment !== itinerary.base_cash_payment;
    if (cashChanged) {
      this.isPriceChanged = true;
      this.flightsBookingsService.hideLoading();
    } else {
      // we will only check points balance if the price is not changed
      this.checkInsufficientPointsBalance(resumeCheckout);
    }
  }

  private buildFlightSearchParams(): void {
    const searchValues = this.singlePriceSearchResult.search;
    const passengers = searchValues.passengers.split(",").map(Number);

    this.flightSearchParams = {
      fromAirport: searchValues.from_airport,
      fromAirportName: searchValues.from_airport_name,
      toAirport: searchValues.to_airport,
      toAirportName: searchValues.to_airport_name,
      departureDate: searchValues.departure_date,
      returnDate: searchValues.return_date,
      cabin: searchValues.cabin as FlightCabin,
      flightType: searchValues.flight_type as FlightType,
      adultCount: passengers[0],
      childCount: passengers[1],
      infantCount: passengers[2],
      currency: this.$routeParams.currency,
      partnerId: this.$routeParams.partnerId,
      landingPage: this.$routeParams.landingPage,
      departuresOnly: this.appSettings.departuresOnly,
    };
  }

  private buildRedirectToSearchUrl(): void {
    this.flightSearchUrl = this.flightUrlGenerator.generateSearchResultsUrl(this.singlePriceSearchResult);
  }

  private checkAndShowSummaryPageExpiryModal(): void {
    if (this.inDetailsPage() || this.inCheckoutPage()) {
      this.showSummaryPageExpiryModal();
    }
  }

  private showSummaryPageExpiryModal(): void {
    this.simpleModalService.open(Modal.FLIGHTS_EXPIRY_BACK_TO_LANDING_PAGE).then(() => {
      this.$location.url("/flights");
    });
  }

  private buildUrl(path: string): string {
    return `${path}?landing_page=${this.$rootScope.landingPage.url || ""}`;
  }

  private handleSinglePriceFetchSuccess(res: FlightsSinglePriceSearchResult): void {
    const { from_airport, to_airport, from_airport_name, to_airport_name,
      return_date, departure_date, cabin, flight_type } = res.search;
    const { adultCount, childCount, infantCount } = this.parsePassengers(res.search.passengers);

    const params: any = {
      ...this.state.params,
      fromAirport: from_airport,
      toAirport: to_airport,
      fromAirportName: from_airport_name,
      toAirportName: to_airport_name,
      returnDate: return_date,
      departureDate: departure_date,
      flightType: flight_type,
      cabin: FlightCabin[cabin],
      adultCount,
      childCount,
      infantCount,
    };

    if (this.$rootScope.useProductType()) {
      params.product_type = this.$rootScope.productTypeAdapter(this.$rootScope.globalState.productType);
    }

    const itinerary = res.itineraries[0];
    if (itinerary) {
      this.state.resetValues(params, itinerary, this.isFetchingItinerary);
    }
    this.isFetchingItinerary = false;
  }

  private parsePassengers(passengers: string): { adultCount: number, childCount: number, infantCount: number } {
    const passengersCount = passengers.split(",");
    return {
      adultCount: Number(passengersCount[0]),
      childCount: Number(passengersCount[1]),
      infantCount: Number(passengersCount[2]),
    };
  }

  private checkInsufficientPointsBalance(resumeCheckout: boolean = false): void {
    const minPointsToPay: number = this.getMinPointsPayment(this.state.itinerary);
    if (this.inCheckoutPage()) {
      const showInsuff = this.pointsAdjustmentService.showInsufficientPointBalance(this.state.itinerary.points, minPointsToPay);
      if (!showInsuff && resumeCheckout) {
        this.$rootScope.$broadcast("process-flight-checkout");
      }
    }
  }

  private inCheckoutPage(): boolean {
    return this.globalStateService.currentPage === CurrentPage.CHECKOUT;
  }

  private inDetailsPage(): boolean {
    return this.globalStateService.currentPage === CurrentPage.FLIGHT_DETAIL;
  }
}

angular.module("BookingApp").service("FlightsSinglePriceSearchService", FlightsSinglePriceSearchService);
