import { CreditCardFormData } from "booking_app/components/common";
import { StripeIntentFields, StripePaymentIntentService } from "booking_app/services/stripe-payment-intent.service";
import { AppSettings } from "booking_app/values/app-settings";

export class StripeIntentPaymentFormFlightsController {
  static $inject = [
    "$scope",
    "StripePaymentIntentService",
    "AppSettings",
  ];

  // binding
  parentForm: any;
  internalData: CreditCardFormData;
  submitted: boolean;
  onUpdateData: () => void;

  constructor(
    private $scope: any,
    public stripePaymentIntentService: StripePaymentIntentService,
    public appSettings: AppSettings,
  ) { }

  $onInit() {
    this.stripePaymentIntentService.mountStripeIntentElements();
    this.stripePaymentIntentService.setupOnChangeListeners(this.$scope);
  }

  showErrorMessage(field: StripeIntentFields): boolean {
    return (
      this.stripePaymentIntentService.errorFlags[field] ||
      (this.submitted && !this.stripePaymentIntentService.stripeIntentForm[field])
    );
  }
}
