import { Bindings } from "booking_app/types";
import { InputCounterController } from "./input-counter.controller";

export class InputCounterComponent {
  bindings: any = {
    value: Bindings.ONE_WAY,
    minValue: Bindings.ONE_WAY,
    maxValue: Bindings.ONE_WAY,
    onUpdate: Bindings.EXPRESSION,
    customLabel: Bindings.ONE_WAY,
    customAriaLabel: Bindings.ONE_WAY,
  };
  controller: any = InputCounterController;
  templateUrl: string = "/html/components/input_counter";
}

angular.module("BookingApp").component("inputCounter", new InputCounterComponent());
