import { BookingErrorsMapper } from "booking_app/services/booking-errors-mapper.service";

export class FlightsCheckoutErrorMapperController {
  static $inject = ["BookingErrorsMapper"];

  constructor(
    private bookingErrorsMapper: BookingErrorsMapper,
  ) { }

  // bindings
  errorCode: string;

  errorDisplay(): string {
    if (this.errorCode) {
      return this.bookingErrorsMapper.map(this.errorCode);
    }
  }
}
