import { Bindings } from "booking_app/types";
import { FlightsTermsController } from "./flights-terms.controller";

export class FlightsTerms {
  bindings: any = {
    bookingKey: Bindings.ONE_WAY,
    bookingRules: Bindings.ONE_WAY,
    linkText: Bindings.ONE_WAY,
  };
  controller: any = FlightsTermsController;
  template: string = `
    <a class="terms"
      translate="{{ $ctrl.linkText }}"
      ng-if="$ctrl.singlePriceService.externalTermsConditionLink"
      href="{{ $ctrl.singlePriceService.externalTermsConditionLink }}"
      target="{{ $ctrl.linkTarget }}"
      tabindex="0"></a>
    <a class="terms hidden-sm hidden-xs"
      translate="{{ $ctrl.linkText }}"
      ng-if="!$ctrl.singlePriceService.externalTermsConditionLink"
      ng-click="$ctrl.openDesktopTermsModal($event)"
      key-enter="$ctrl.openDesktopTermsModal($event)"
      tabindex="0"></a>
    <a class="terms hidden-lg hidden-md"
      translate="{{ $ctrl.linkText }}"
      ng-if="!$ctrl.singlePriceService.externalTermsConditionLink"
      ng-click="$ctrl.openMobileTermsModal($event)"
      key-enter="$ctrl.openMobileTermsModal($event)"
      tabindex="0"></a>

    <form-popup-mobile ng-if="$ctrl.mobileModalOpen"
      close-modal="$ctrl.closeMobileTermsModal($event)"
      modal-accessibility-lock="form-popup-mobile"
      ng-click="$ctrl.preventDefaultAndStopPropagation($event)"
      max-modal-width="991">
      <title-content>
        <button class="fa fa-close close-tick"
                ng-click="$ctrl.closeMobileTermsModal($event)"
                key-enter="$ctrl.closeMobileTermsModal($event)"
                tabindex="0"></button>
        <span class="heading" translate="{{ $ctrl.customTitle ? $ctrl.customTitle : 'Terms & Conditions' }}"></span>
      </title-content>
      <body-content class="flights-terms-modal">
        <p class="loading"
          ng-if="!$ctrl.bookingRules"
          translate="Terms & Conditions is loading...">
        </p>
        <p align="center"
          ng-if="$ctrl.bookingRules && $ctrl.bookingRules.length == 0"
          translate="No terms & conditions available"></p>
        <accordion class="accordion-element" ng-repeat="term in $ctrl.bookingRules">
          <div class="header" ng-click="togglePanel()" ng-class="{active: showPanel}" tabindex="0">
            <span ng-bind="$ctrl.formatTerm(term.category)"></span>
            <em class="fa fa-angle-down"></em>
          </div>
          <div class="content" ng-class="{active: showPanel}">
            <pre translate="{{term.rule}}"></pre>
          </div>
        </accordion>
      </body-content>
    </form-popup-mobile>
  `;
}

angular.module("BookingApp").component("flightsTerms", new FlightsTerms());
