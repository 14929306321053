import { FlightCabin, FlightSearchParams, FlightType, ViewportSizes } from "booking_app/types";
import { FlightsEditSearchController } from "./flights-edit-search.controller";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { AppSettings } from "booking_app/values/app-settings";

declare var moment: any;

export class FlightsSearchBarController {
  static $inject = [
    "$translate",
    "$modal",
    "$rootScope",
    "$scope",
    "GlobalStateService",
    "AppSettings",
  ];

  // bindings
  flightSearchParams: FlightSearchParams;

  constructor(
    private $translate: any,
    private $modal: any,
    private $rootScope: any,
    private $scope: any,
    private globalStateService: GlobalStateService,
    private appSettings: AppSettings,
  ) {
    this.$scope.globalStateService = GlobalStateService;
  }

  flightDepartText(): string {
    return `${this.fromAirportName()} ${this.$translate.instant("to")}`;
  }

  flightReturnText(): string {
    return `${this.toAirportName()}`;
  }

  flightDepartureAndReturnText(
    oneWayKey: string = "flights.type.one_way",
    returnKey: string = "flights.type.return",
  ): string {
    let label = this.$translate.instant(`${oneWayKey}`);
    if (this.flightSearchParams.flightType === FlightType.RETURN) {
      label = this.$translate.instant(`${returnKey}`);
    }
    return `${this.flightDepartText()} ${this.flightReturnText()} - ${label}`;
  }

  dateLabel(): string {
    if (this.flightSearchParams.flightType === FlightType.ONE_WAY) {
      return this.$translate.instant("flights.search_bar.depart");
    } else if (this.flightSearchParams.flightType === FlightType.RETURN) {
      return this.$translate.instant("flights.search_bar.depart_return");
    }
  }

  dateText(): string {
    const departureDate = moment(this.flightSearchParams.departureDate).format("[customDayMonth]");
    const returnDate = moment(this.flightSearchParams.returnDate).format("[customDayMonth]");

    if (this.flightSearchParams.flightType === FlightType.ONE_WAY) {
      return departureDate;
    } else if (this.flightSearchParams.flightType === FlightType.RETURN) {
      return `${departureDate} - ${returnDate}`;
    }
  }

  departureDate(): string {
    return moment(this.flightSearchParams.departureDate).format("[customDayMonth]");
  }

  returnDate(): string {
    return moment(this.flightSearchParams.returnDate).format("[customDayMonth]");
  }

  isReturn(): boolean {
    return this.flightSearchParams.flightType === FlightType.RETURN;
  }

  travellerText(): string {
    return [this.adultCaption(), this.childCaption(), this.infantCaption()].filter(item => item).join(", ");
  }

  travellerCaption(): string {
    const searchParams = this.flightSearchParams;
    const totalCount = searchParams.adultCount + searchParams.childCount + searchParams.infantCount;
    if (totalCount === 1) {
      return this.$translate.instant("count.traveller_one");
    } else {
      return this.$translate.instant("count.traveller_other", { count: totalCount });
    }
  }

  cabinText(): string {
    return this.$translate.instant(FlightCabin.toStringTranslation(this.flightSearchParams.cabin));
  }

  combinedLabel(): string {
    return `${this.flightDepartText()} ${this.flightReturnText()}`;
  }

  combinedValue(): string {
    return `${this.dateText()}, ${this.travellerText()}, ${this.cabinText()}`;
  }

  editSearch() {
    this.$modal.open({
      animation: true,
      backdrop: "static",
      templateUrl: "/html/modals/flights_search_modal",
      size: "sm",
      controller: FlightsEditSearchController,
      controllerAs: "$ctrl",
      windowClass: "edit-search-popup flights-edit-search-popup",
    });
  }

  private fromAirportName(): string {
    if (this.flightSearchParams.fromAirportName && !this.onlyUseAirportCodesOnMobile()) {
      return `${this.flightSearchParams.fromAirportName} (${this.flightSearchParams.fromAirport})`;
    } else {
      return this.flightSearchParams.fromAirport;
    }
  }

  private toAirportName(): string {
    if (this.flightSearchParams.toAirportName && !this.onlyUseAirportCodesOnMobile()) {
      return `${this.flightSearchParams.toAirportName} (${this.flightSearchParams.toAirport})`;
    } else {
      return this.flightSearchParams.toAirport;
    }
  }

  private onlyUseAirportCodesOnMobile(): boolean {
    return this.appSettings.onlyUseAirportCodesinModifySearchMobile && this.isMobile();
  }

  private adultCaption(): string {
    if (this.flightSearchParams.adultCount === 1) {
      return this.$translate.instant("count.adult_one");
    } else if (this.flightSearchParams.adultCount > 1) {
      return this.$translate.instant("count.adult_other", { count: this.flightSearchParams.adultCount });
    }
  }

  private childCaption(): string {
    if (this.flightSearchParams.childCount === 1) {
      return this.$translate.instant("count.children_one");
    } else if (this.flightSearchParams.childCount > 1) {
      return this.$translate.instant("count.children_other", { count: this.flightSearchParams.childCount });
    }
  }

  private infantCaption(): string {
    if (this.flightSearchParams.infantCount === 1) {
      return this.$translate.instant("count.infant_one");
    } else if (this.flightSearchParams.infantCount > 1) {
      return this.$translate.instant("count.infant_other", { count: this.flightSearchParams.infantCount });
    }
  }

  private isMobile(): boolean {
    return this.appSettings.mobileFirst || this.$rootScope.globalState.browserWidth <= ViewportSizes.XS_MAX;
  }
}
