import { FlightsCheckoutNavigationBarController } from './navigation-bar.controller';

export class FlightsCheckoutNavigationBarComponent {
  controller: any;
  templateUrl: string;

  constructor() {
    this.controller = FlightsCheckoutNavigationBarController;
    this.templateUrl = '/html/flights/checkout_navigation_bar';
  }
}

angular.module("BookingApp").component("flightsCheckoutNavigationBarComponent", new FlightsCheckoutNavigationBarComponent());
