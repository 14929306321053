angular.module("BookingApp").directive('integer', () => {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: (scope, element, attrs, controller) => {
      controller.$validators.integer = (value: string) => {
        return /^([0-9]\d*)$/.test(value);
      };
    }
  };
});
