import { CurrentPage, FlightItem, FlightSearchParams, FlightType } from "booking_app/types";
import { FlightsItinerary, FlightsSinglePriceSearchResult } from "booking_app/models";
import { convertJSONToQueryString } from "booking_app/services/route-params-utils";
import { FlightsSinglePriceSearchService } from "booking_app/services/flights";
import { FlightUrlGenerator } from "booking_app/services/flights/url-generator";
import { FlightSingleQueryParams } from "booking_app/models/flights-single-query-params";
import { isBookingKeyExpired } from "booking_app/models/utils/single-price-utils";
import { GlobalStateService } from "booking_app/services/global-state.service";
import { PointsCashShareService } from "booking_app/services/points-cash-share.service";
import { AppSettings } from "booking_app/values/app-settings";

export class FlightsSummaryController {
  static $inject = [
    "$scope",
    "$rootScope",
    "$location",
    "FlightUrlGenerator",
    "GlobalStateService",
    "PointsCashShareService",
    "AppSettings",
    "FlightsSinglePriceSearchService",
  ];

  constructor(
    private $scope: any,
    private $rootScope: any,
    private $location: any,
    private flightUrlGenerator: FlightUrlGenerator,
    private globalStateService: GlobalStateService,
    private pointsCashShareService: PointsCashShareService,
    private appSettings: AppSettings,
    private singlePriceService: FlightsSinglePriceSearchService,
  ) {
    this.$scope.globalStateService = this.globalStateService;
    this.$scope.pointsCashShareService = this.pointsCashShareService;
    this.$scope.sliderLeftLabel = this.appSettings.pointsCashSliderSettings.sliderLeftLabel;
    this.$scope.sliderRightLabel = this.appSettings.pointsCashSliderSettings.sliderRightLabel;
    this.$scope.sliderLabel = this.appSettings.pointsCashSliderSettings.sliderLabel;
    this.$scope.alwaysShowPointsSlider = this.appSettings.pointsCashSliderSettings.alwaysShowPointsSlider;
  }

  $onInit(): void {
    this.globalStateService.currentPage = CurrentPage.FLIGHT_DETAIL;
    this.singlePriceService.isPriceChanged = false;
    this.singlePriceService.runSingleSearch(false);
    this.$scope.globalState = this.$rootScope.globalState;
  }

  isBookingKeyExpired(): boolean {
    return isBookingKeyExpired(this.singlePriceResult);
  }

  get flightItems(): FlightItem[] {
    return this.singlePriceService.flightItems;
  }

  get itineraries(): FlightsItinerary[] {
    return this.singlePriceService.itineraries;
  }

  get isPriceChanged(): boolean {
    return this.singlePriceService.isPriceChanged;
  }

  get singlePriceResult(): FlightsSinglePriceSearchResult {
    return this.singlePriceService.singlePriceSearchResult;
  }

  get isLoading(): boolean {
    return this.singlePriceService.isFetchingItinerary;
  }

  get flightSearchParams(): FlightSearchParams {
    return this.singlePriceService.flightSearchParams;
  }

  get flightSearchUrl(): string {
    return this.singlePriceService.flightSearchUrl;
  }

  flightType(): string {
    const flightType = this.flightSearchParams.flightType;
    return Object.keys(FlightType).find(key => FlightType[key] === flightType);
  }

  isRevalidatingItinerary(): boolean {
    return this.singlePriceService.isRevalidatingItinerary;
  }

  checkoutPageUrl(): string {
    const urlParams: FlightSingleQueryParams = {
      booking_key: this.itineraryBookingKey(),
      partnerId: this.$rootScope.pointsPartner.id,
    };

    return this.flightUrlGenerator.generateSingleFlightUrl(urlParams, "checkout");
  }

  redirectToCheckoutPage(): void {
    this.$location.url( this.checkoutPageUrl() );
  }

  changeFlight(): void {
    const url = this.regenerateResultsUrl();
    this.$location.url(url);
  }

  itinerary(): any {
    return this.itineraries ? this.itineraries[0] : null;
  }

  private passengers(): any {
    return `
      ${this.flightSearchParams.adultCount}:
      ${this.flightSearchParams.childCount}:
      ${this.flightSearchParams.infantCount}
    `;
  }

  private itineraryBookingKey(): string {
    return this.itinerary().booking_key;
  }

  private regenerateResultsUrl(): string {
    const urlParams: any = {
      fromAirport: this.flightSearchParams.fromAirport,
      toAirport: this.flightSearchParams.toAirport,
      departureDate: this.flightSearchParams.departureDate,
      returnDate: this.flightSearchParams.returnDate,
      cabin: this.flightSearchParams.cabin,
      passengers: this.passengers(),
      flightType: this.flightSearchParams.flightType,
      currency: this.$rootScope.selectedCurrency.code,
      landingPage: this.$rootScope.landingPage.url,
      partnerId: this.$rootScope.pointsPartner.id,
      pointsCashShare: this.pointsCashShareService.pointsCashShare?.value,
    };

    if (this.$rootScope.useProductType()) {
      urlParams.product_type = this.$rootScope.productTypeAdapter(this.$rootScope.globalState.productType);
    }

    return `/flights/results?${convertJSONToQueryString(urlParams)}`;
  }
}
