import { Bindings } from "booking_app/types";
import { FlightsCheckoutPrimaryPassengerDetailFormController } from "./primary-passenger-detail-form.controller";

export class FlightsCheckoutPrimaryPassengerDetailFormComponent {
  bindings: any;
  controller: any;
  templateUrl: string;

  constructor() {
    this.bindings = {
      parentForm: Bindings.ONE_WAY,
      formName: Bindings.ONE_WAY,
      data: Bindings.ONE_WAY,
      onUpdate: Bindings.EXPRESSION,
      submitted: Bindings.ONE_WAY,
      passportRequired: Bindings.ONE_WAY,
      travelSource: Bindings.ONE_WAY,
    };
    this.controller = FlightsCheckoutPrimaryPassengerDetailFormController;
    this.templateUrl = '/html/flights/checkout_primary_passenger_detail_form'
  }
}

angular.module("BookingApp").component("flightsCheckoutPrimaryPassengerDetailFormComponent", new FlightsCheckoutPrimaryPassengerDetailFormComponent());
