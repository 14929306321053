import { FlightsPricesSearchResult, FlightsItinerary } from "booking_app/models";
import { ApiPollingService } from 'booking_app/services/api-polling.service';
import { FlightType, FlightItem } from 'booking_app/types';
import { SelectionStage } from "./selection-stage";
import { FlightItemFilter, FlightsSearchResultsFilters } from "./filters";
import { FlightsSearchResultsState } from '.';
import { FlightItemBuilder } from "booking_app/services/flights";
import { FlightsSearchResultsSorterService, SortOption } from "booking_app/components/flights/search-results/sorter";

export class FlightsSearchResultsService {
  static $inject = [
    "$rootScope",
    "$window",
    "FlightsSearchResultsState",
    "ApiPollingService",
    "FlightItemBuilder",
    "FlightsSearchResultsSorterService",
  ];

  // injectors
  private state: FlightsSearchResultsState;

  constructor(
    private $rootScope: any,
    private $window: any,
    private flightsSearchResultsState: FlightsSearchResultsState,
    private apiPollingService: ApiPollingService,
    private flightItemBuilder: FlightItemBuilder,
    private flightsSearchResultsSorterService: FlightsSearchResultsSorterService,
  ) {
    this.state = this.flightsSearchResultsState;
  }

  updateFilters(filters: FlightsSearchResultsFilters): void {
    this.state.filters = filters;
  }

  updateSelectedSortOption(option: SortOption): void {
    this.$window.scrollTo(0, 0);
    this.flightsSearchResultsSorterService.selectedSortOption = option;
  }

  updateResultsLimit(resultsLimit: number): void {
    this.state.resultsLimit = resultsLimit;
  }

  updateFlightItems(): void {
    this.state.flightItems = this.buildFlightItems(this.state.itineraries);
    this.state.totalDisplayedFlightItems = this.sortFlightItems(
      this.filterFlightItemsByFilters(Object.values(this.state.filters), this.state.flightItems)
    );
    this.state.displayedFlightItems = this.state.totalDisplayedFlightItems.slice(0, this.state.resultsLimit);
  }

  private buildFlightItems(itineraries: FlightsItinerary[]): FlightItem[] {
    if (this.state.selectionStage === SelectionStage.ORIGIN) {
      return this.flightItemBuilder.buildOriginFlightItems(itineraries);
    } else if (this.state.selectionStage === SelectionStage.RETURN) {
      return this.flightItemBuilder.buildReturnFlightItems(itineraries, this.state.selectedOriginFlightItem);
    }
  }

  private filterFlightItemsByFilters(filters: FlightItemFilter[], flightItems: FlightItem[]): FlightItem[] {
    return flightItems.filter(item => filters.every(filter => filter.perform(item)));
  }

  private sortFlightItems(flightItems: FlightItem[]): FlightItem[] {
    return this.flightsSearchResultsSorterService.selectedSortOption.sortFunction(flightItems);
  }

  // This is supposed to return Promise<FlightsPricesSearchResult>, however since we need the notify result,
  // we're using the CommonJS Promise specs here, therefore we can't do type checking here.
  fetchFlightsPrices(): any {
    const {
      flightType,
      departureDate,
      adultCount,
      childCount,
      infantCount,
      fromAirport,
      toAirport,
      cabin,
      departuresOnly,
      flightFareId,
    } = this.state.flightSearchParams;
    const urlParams : any = {
      flight_type: flightType,
      departure_date: departureDate,
      passengers: `${adultCount},${childCount},${infantCount}`,
      from_airport: fromAirport,
      to_airport: toAirport,
      cabin: cabin,
      currency: this.$rootScope.selectedCurrency.code,
      partner_id: this.$rootScope.pointsPartner.id,
      landing_page: this.$rootScope.landingPage.url,
    };
    if (this.state.flightSearchParams.flightType === FlightType.RETURN) {
      urlParams.return_date = this.state.flightSearchParams.returnDate;
    }

    if (this.$rootScope.useProductType()) {
      urlParams.product_type = this.$rootScope.productTypeAdapter(this.$rootScope.globalState.productType);
    }

    if (departuresOnly) {
      urlParams.departures_only = departuresOnly;
      urlParams.flight_fare_id = flightFareId;
    }

    return this.apiPollingService.poll("flights/prices", urlParams);
  }
}

angular.module("BookingApp").service("FlightsSearchResultsService", FlightsSearchResultsService);
